import styled from 'styled-components';
import { Link } from 'react-router-dom';

import colors from 'utils/colors';

const NavItem = styled(Link)`
  flex: 0 0 auto;
  display: inline-block;
  line-height: 100px;
  transition: opacity 0.15s, transform 0.15s;
  cursor: pointer;
  text-decoration: none;
  color: ${colors.secondary_blue};
  &:hover,
  &:focus {
    opacity: 0.5;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.5;
  }
  &:visited {
    color: ${colors.secondary_blue};
  }
`;

export default NavItem;
