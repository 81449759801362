import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import ReactModal from 'react-modal';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';

import LoaderPage from 'common/LoaderPage';
import LongButton from 'common/buttons/longButton';
import SvgIcon from 'common/icons/SvgIcon';

import colors from 'utils/colors';
import { baseFont, boldFont } from 'utils/fonts';
import { mobile } from 'utils/media';
import GetTableSize from 'utils/getTableSize';

const Wrapper = styled.div`
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(4, 96, 132, 0.5);
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(4, 96, 132, 1);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.primary_blue};
  }

  ${mobile(
    css`
      font-size: 14px;
    `
  )};
`;

const NA = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color: ${colors.primary_blue};
  font-family: ${boldFont};
`;

const LabelColumn = styled.div`
  max-width: 100%;
`;

const LabelWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  font-family: ${boldFont};
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 300ms ease-in-out;
`;

const PaginationButton = styled.button`
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 14px;
  letter-spacing: 0.71px;
  line-height: 18px;
  border: none;
`;

const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 540px;
  width: 986px;
  background-color: #ffffff;
`;

const NoPackages = styled.span`
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.4px;
  line-height: 29px;
`;

const LabelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 52px;
  border: 1px solid ${colors.base_text3};
  border-radius: 2px;
  background: transparent;
  cursor: pointer;
  ${(p) =>
    !p.exists
      ? `
    border: 1px solid #DDDDDD;
    cursor: default;
    `
      : null}
`;

const AccessWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const Code = styled.span`
  width: 75px !important;
  color: #083e66;
  font-family: 'Swiss721BTRegular';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 18px;
`;

class LogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCameraModal: false,
      label_url: null,
    };
    this.toggleCameraModal = this.toggleCameraModal.bind(this);
    this.showLabel = this.showLabel.bind(this);
  }

  componentWillMount() {
    // if own user parcels or OWNER can see other user parcels labels
    ReactModal.setAppElement('body');
  }

  toggleCameraModal() {
    this.setState({ showCameraModal: !this.state.showCameraModal });
  }

  showLabel(label_url) {
    this.setState({ showCameraModal: !this.state.showCameraModal, label_url });
  }

  renderLabelImg(label_url) {
    return (
      <img
        style={{
          width: '100%',
          height: '100%',
        }}
        src={label_url}
        alt="label"
        onError={(e) => {
          this.setState({ label_url: null });
        }}
      />
    );
  }

  render() {
    const { parcels } = this.props;
    const { pageStart, pageSizeArray } = GetTableSize(parcels.length);
    const columns = [
      {
        Header: 'Delivered',
        headerClassName: 'table-header',
        className: 'table-cell',
        accessor: 'created_at',
        minWidth: 150,
        Cell: ({ row }) => {
          if (row.created_at) {
            const created_at = moment(row.created_at).format(
              'MM/DD/YYYY | h:mm a'
            );
            return created_at;
          } else {
            return 'N/A';
          }
        },
        sortMethod: (a, b) => moment(a).diff(moment(b)),
      },
      {
        Header: 'Location',
        headerClassName: 'table-header',
        className: 'table-cell',
        accessor: 'nickname',
      },
      {
        Header: 'Mobile Number',
        headerClassName: 'table-header',
        className: 'table-cell',
        accessor: 'courier',
      },
      {
        Header: 'Status',
        headerClassName: 'table-header',
        className: 'table-cell',
        accessor: 'status',
        Cell: ({ row }) => {
          if (row.status === 'Expired') {
            return <span style={{ color: colors.action2 }}>EXPIRED</span>;
          } else {
            return row.status.toUpperCase();
          }
        },
      },
      {
        Header: 'Picked Up',
        headerClassName: 'table-header',
        className: 'table-cell table-col-timestamp',
        accessor: 'picked_up_at',
        minWidth: 115,
        sortMethod: (a, b) => moment(a).diff(moment(b)),
        Cell: ({ row }) => {
          if (row.picked_up_at) {
            const picked_up_at = moment(row.picked_up_at).format(
              'MM/DD/YYYY | h:mm a'
            );
            return picked_up_at;
          } else {
            return 'N/A';
          }
        },
      },
      {
        Header: 'Signature',
        headerClassName: 'table-header nounderline',
        accessor: 'label_url',
        className: 'table-cell label-column',
        style: { display: 'flex', justifyContent: 'center' },
        sortable: false,
        Cell: ({ row }) => {
          return (
            <LabelButton
              onClick={
                row.label_url ? () => this.showLabel(row.label_url) : null
              }
              exists={row.label_url}
            >
              <SvgIcon
                icon="camera"
                viewBox="0 0 13 11"
                height="12px"
                width="14px"
                fill={row.label_url ? colors.base_text3 : colors.base1}
              />
            </LabelButton>
          );
        },
      },
      {
        Header: 'Access Code',
        headerClassName: 'table-header nounderline',
        accessor: 'pin',
        className: 'table-cell label-column ',
        style: { display: 'flex', justifyContent: 'center' },
        minWidth: 150,
        sortable: false,
        Cell: ({ row }) => {
          // pin === Access Code, backend object uses pin as key, access code is name for column due to business value
          if (row.pin && row.pin === 'Contact Management') {
            return 'Contact Management';
          }

          return row.pin ? (
            <AccessWrap>
              <Code>{row.pin}</Code>
              <div
                onClick={() =>
                  this.props.sendEmail(row.pin, row.expiration, this.props.user)
                }
              >
                <SvgIcon
                  icon="email"
                  viewBox="0 0 28 28"
                  height="19px"
                  width="19px"
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div
                onClick={() =>
                  this.props.sendSMS(row.pin, this.props.user.phone)
                }
              >
                <SvgIcon
                  icon="phone"
                  viewBox="0 0 28 28"
                  height="19px"
                  width="19px"
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </AccessWrap>
          ) : null;
        },
      },
    ];

    return (
      <React.Fragment>
        {!this.props.log_loading && parcels ? (
          <Wrapper style={{ width: parcels.length === 0 && 'auto' }}>
            <ReactTable
              data={parcels}
              columns={parcels.length > 0 ? columns : []}
              noDataText=""
              className="table -highlight"
              defaultPageSize={pageStart}
              pageSizeOptions={pageSizeArray}
              defaultSortDesc={true}
              minRows={0}
              defaultSorted={[
                {
                  id: 'created_at',
                  desc: true,
                },
              ]}
              rowsText={''}
              showPagination={parcels.length > 0}
              NoDataComponent={() => (
                <NoData>
                  <SvgIcon
                    icon="package"
                    width="50px"
                    height="50px"
                    viewBox="0 0 59 59"
                  />
                  <br />
                  <NoPackages>No Packages Yet</NoPackages>
                </NoData>
              )}
              PreviousComponent={(props) => {
                return (
                  <div>
                    <PaginationButton
                      {...props}
                      style={{
                        color: props.disabled
                          ? colors.accent4
                          : colors.base_text3,
                      }}
                    >
                      PREV
                    </PaginationButton>
                  </div>
                );
              }}
              NextComponent={(props) => {
                return (
                  <PaginationButton
                    {...props}
                    style={{
                      color: props.disabled
                        ? colors.accent4
                        : colors.base_text3,
                    }}
                  >
                    NEXT
                  </PaginationButton>
                );
              }}
            />
            <ReactModal
              className="Modal"
              overlayClassName="Overlay"
              closeTimeoutMS={150}
              isOpen={this.state.showCameraModal}
              onRequestClose={this.toggleCameraModal}
              shouldCloseOnOverlayClick
              shouldCloseOnEsc
            >
              <LabelWrapper>
                {this.state.label_url ? (
                  this.renderLabelImg(this.state.label_url)
                ) : (
                  <h2>This parcel does not have a label picture on file.</h2>
                )}
              </LabelWrapper>
            </ReactModal>
          </Wrapper>
        ) : (
          <LoaderPage color="accent1" />
        )}
      </React.Fragment>
    );
  }
}

export default LogTable;
