import React from 'react';

export default (props) => (
  <g
    id="UI-Style-Guide"
    transform="translate(-140.000000, -2270.000000)"
    fill={props.fill}
    fillRule="nonzero"
  >
    <g id="magnifying-glass" transform="translate(140.000000, 2270.000000)">
      <path
        d="M17.8443114,17.0820399 L13.4690619,12.7077605 C14.6427146,11.3587583 15.3532934,9.59866962 15.3532934,7.67494457 C15.3532934,3.44035477 11.9081836,0 7.67664671,0 C3.44111776,0 0,3.4443459 0,7.67494457 C0,11.9055432 3.44510978,15.3498891 7.67664671,15.3498891 C9.6007984,15.3498891 11.3612774,14.6394678 12.7105788,13.4660754 L17.0858283,17.8403548 C17.1896208,17.9441242 17.3293413,18 17.4650699,18 C17.6007984,18 17.740519,17.9481153 17.8443114,17.8403548 C18.0518962,17.632816 18.0518962,17.2895787 17.8443114,17.0820399 Z M1.0738523,7.67494457 C1.0738523,4.03503326 4.03592814,1.07760532 7.67265469,1.07760532 C11.3133733,1.07760532 14.2714571,4.03902439 14.2714571,7.67494457 C14.2714571,11.3108647 11.3133733,14.2762749 7.67265469,14.2762749 C4.03592814,14.2762749 1.0738523,11.3148559 1.0738523,7.67494457 Z"
        id="Shape"
        style={{ mixBlendMode: 'darken' }}
      />
    </g>
  </g>
);
