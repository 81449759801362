import React from 'react';
import styled from 'styled-components';
import colors from 'utils/colors';

const HamburgerWrapper = styled.div`
  visibility: visible;
  margin-bottom: 25px;
  margin-left: 15px;
  flex-direction: column;
  cursor: pointer;
  width: 30px;
  height: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }
`;

const Line = styled.span`
  width: 30px;
  height: 5px;
  margin-bottom: 5px;
  border-radius: 1000px;
  background: ${(p) => colors[p.color]};
  display: block;
`;

const lastChild = {
  marginBottom: 0,
};

const MobileToggle = ({ style = {}, color, onClick }) => (
  <HamburgerWrapper style={style} onClick={onClick}>
    <Line color={color} />
    <Line color={color} />
    <Line color={color} style={lastChild} />
  </HamburgerWrapper>
);

export default MobileToggle;
