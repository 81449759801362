import styled from 'styled-components';

import colors from 'utils/colors';

const NavSeparator = styled.span`
  width: 5px;
  height: 5px;
  margin: 0 10px;
  border-radius: 3px;
  border: 2px solid ${colors.secondary_blue};
`;

export default NavSeparator;
