import React from 'react';

export default (props) => (
  <g
    id="Style-Guide"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g id="UI-Style-Guide" transform="translate(-275.000000, -2328.000000)">
      <g
        id="unregistered-users-icon"
        transform="translate(275.000000, 2328.000000)"
      >
        <circle id="Oval" fill="#073E66" cx="17" cy="17" r="17" />
        <path
          d="M17,16.5 C18.934375,16.5 20.5,14.8225446 20.5,12.75 C20.5,10.6774554 18.934375,9 17,9 C15.065625,9 13.5,10.6774554 13.5,12.75 C13.5,14.8225446 15.065625,16.5 17,16.5 Z M17,16.5 C18.934375,16.5 20.5,14.8225446 20.5,12.75 C20.5,10.6774554 18.934375,9 17,9 C15.065625,9 13.5,10.6774554 13.5,12.75 C13.5,14.8225446 15.065625,16.5 17,16.5 Z M19.4,17.5714286 L19.140625,17.5714286 C18.490625,17.90625 17.76875,18.1071429 17,18.1071429 C16.23125,18.1071429 15.5125,17.90625 14.859375,17.5714286 L14.6,17.5714286 C12.6125,17.5714286 11,19.2991071 11,21.4285714 L11,22.3928571 C11,23.2801339 11.671875,24 12.5,24 L21.5,24 C22.328125,24 23,23.2801339 23,22.3928571 L23,21.4285714 C23,19.2991071 21.3875,17.5714286 19.4,17.5714286 Z"
          id="users"
          fill="#F6F7F9"
          fillRule="nonzero"
        />
      </g>
    </g>
  </g>
);
