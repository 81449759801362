import styled from 'styled-components';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

const ErrorMessage = styled.div`
  font-family: ${baseFont};
  text-align: center;
  color: ${colors.action2};
  margin: 15px auto 0 auto;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;

export default ErrorMessage;
