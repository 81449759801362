import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { css } from 'styled-components';
import ReactTable from 'react-table';
import colors from 'utils/colors';
import GetTableSize from 'utils/getTableSize';
import { baseFont, boldFont } from 'utils/fonts';
import moment from 'moment';
import { updateVacationHold } from 'redux/modules/duck-admin';
import LongButton from 'common/buttons/longButton';

const PaginationButton = styled.button`
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 14px;
  letter-spacing: 0.71px;
  line-height: 18px;
  border: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const ApproveButton = styled(LongButton)`
  width: 95px;
  padding: 5px;
  font-size: 14px;
  margin: 0 auto 10px auto;
  border: 2px solid ${colors.action1};
  color: ${colors.action1};
  &:hover {
    border-color: transparent;
  }
`;

const DeclineButton = styled(LongButton)`
  width: 95px;
  padding: 5px;
  font-size: 14px;
  margin: 0 auto 10px auto;
  border: 2px solid ${colors.action2};
  color: ${colors.action2};
  &:hover {
    border-color: transparent;
  }
`;

const HoldRequestsTable = (props) => {
  const {
    loading,
    errors,
    updateVacationHold,
    selectedSystem,
    history,
    vacations,
    selectSystemIndex,
    analytics_loading,
  } = props;

  const approveVacation = async (id) => {
    await props.updateVacationHold(
      id,
      'approved',
      props.selectedSystem._id,
      props.vacations
    );
  };

  const declineVacation = async (id) => {
    await props.updateVacationHold(
      id,
      'declined',
      props.selectedSystem._id,
      props.vacations
    );
  };

  const { pageStart, pageSizeArray } = GetTableSize(vacations.length);

  const columns = [
    {
      Header: 'Name',
      headerClassName: 'table-header',
      width: 150,
      className: 'table-cell',
      accessor: 'name',
    },
    {
      Header: 'Email',
      headerClassName: 'table-header',
      className: 'table-cell',
      accessor: 'email',
      width: 250,
      sortMethod: (a, b) => {
        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      },
    },
    {
      Header: 'Vacation Start',
      headerClassName: 'table-header',
      className: 'table-cell',
      accessor: 'hold_from',
      Cell: ({ row }) => {
        const { hold_from } = row._original;
        return moment(hold_from).format('MM/DD/YYYY');
      },
    },

    {
      Header: 'Vacation End',
      headerClassName: 'table-header',
      className: 'table-cell',
      accessor: 'hold_to',
      Cell: ({ row }) => {
        const { hold_to } = row._original;
        return moment(hold_to).format('MM/DD/YYYY');
      },
    },

    {
      Header: 'Action',
      headerClassName: 'table-header',
      className: 'table-cell-vachold-action',
      Cell: (props) => {
        return (
          <ButtonWrapper>
            <ApproveButton
              onClick={async () => {
                await approveVacation(props.index);
              }}
              textColor="action1"
              backgroundColor="white"
            >
              Approve
            </ApproveButton>
            <DeclineButton
              onClick={async () => {
                await declineVacation(props.index);
              }}
              textColor="action2"
              backgroundColor="white"
            >
              Decline
            </DeclineButton>
          </ButtonWrapper>
        );
      },
    },
  ];

  return (
    <ReactTable
      data={vacations}
      columns={columns}
      noDataText="No Vacation Holds"
      className="notifTable -highlight"
      pageSize={pageStart}
      pageSizeOptions={pageSizeArray}
      defaultSorted={[{ id: 'name' }]}
      minRows={5}
      loading={loading}
      loadingText="Loading..."
      resizable={false}
      rowsText={null}
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e, handleOriginal) => {
            if (handleOriginal) {
              handleOriginal();
            }
          },
        };
      }}
      PreviousComponent={(props) => {
        return (
          <div>
            <PaginationButton
              {...props}
              style={{
                color: props.disabled ? colors.accent4 : colors.base_text3,
              }}
            >
              PREV
            </PaginationButton>
          </div>
        );
      }}
      NextComponent={(props) => {
        return (
          <PaginationButton
            {...props}
            style={{
              color: props.disabled ? colors.accent4 : colors.base_text3,
            }}
          >
            NEXT
          </PaginationButton>
        );
      }}
    />
  );
};

const mapStateToProps = ({ admin }, ownProps) => {
  const {
    loading,
    errors,
    selectedSystem,
    currentRequest,
    vacations,
    selectSystemIndex,
    analytics_loading,
  } = admin;
  return {
    loading,
    errors,
    selectedSystem,
    currentRequest,
    vacations,
    selectSystemIndex,
    analytics_loading,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateVacationHold,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoldRequestsTable);
