import React from 'react';
import styled from 'styled-components';
import colors from 'utils/colors';

const Wrap = styled.div`
  position: relative;
  background: transparent;
  width: 100%;
  flex: 1;
`;

const Footer = styled.footer`
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 15px;

  height: 10px;
  width: 90%;
  box-sizing: border-box;
  text-align: center;
  font-size: 10px;

  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;

  ${(p) =>
    p.isAuthenticated
      ? `color: ${colors.accent1};`
      : `color: ${colors.secondary_blue}`};
`;

export default (props) => {
  return (
    <Wrap {...props}>
      <Footer {...props}>
        {`\u00A9`} Copyright 2019 Digilock by Security People Inc.
      </Footer>
    </Wrap>
  );
};
