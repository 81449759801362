import React, { Component } from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel } from 'victory';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

class BarChart extends Component {
  checkEmpty() {
    const { data } = this.props;
    const isEmpty = data.every((info) => info.count === 0);

    return isEmpty;
  }

  render() {
    let isEmpty;
    if (this.props.y === 'count') {
      // exclusive for locker usage card
      isEmpty = this.checkEmpty();
    }
    return (
      <VictoryChart domainPadding={{ x: 25, y: 25 }} height={400}>
        <VictoryAxis
          offsetY={35}
          style={{
            axis: { stroke: colors.primary_blue },
            axisLabel: {
              fontSize: 15,
              padding: 30,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
          }}
        />
        <VictoryBar
          barRatio={0.95}
          style={{
            data: {
              fill: !isEmpty ? colors.primary_blue : colors.white,
            },
            labels: {
              fontSize: 15,
              padding: 5,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
          }}
          animate={{
            duration: 1500,
            onLoad: { duration: 500 },
          }}
          x={this.props.x}
          y={this.props.y}
          data={this.props.data}
          labelComponent={
            <VictoryLabel dy={isEmpty ? 150 : 0} text={(d) => d.y} />
          }
        />
      </VictoryChart>
    );
  }
}

export default BarChart;
