import { createSelector } from 'reselect';

const couriersSelector = (state) => state.couriers;
const ascendingSelector = (state) => state.ascending;

export const sortCouriers = createSelector(
  [couriersSelector, ascendingSelector],
  (couriers, ascending) => {
    if (ascending) {
      return couriers.concat().sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } else {
      return couriers.concat().sort(function(a, b) {
        if (a.name < b.name) {
          return 1;
        }
        if (a.name > b.name) {
          return -1;
        }
        return 0;
      });
    }
  }
);
