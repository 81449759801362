import React from 'react';

export default (props) => (
  <g
    id="UI-Style-Guide"
    transform="translate(-374.000000, -2267.000000)"
    fill={props.fill}
    fillRule="nonzero"
  >
    <path
      d="M397.999979,2278.97733 C398.012221,2285.58895 392.620306,2290.99535 386.008699,2291 C383.153137,2291.00203 380.530172,2290.00661 378.468932,2288.34301 C377.932948,2287.91043 377.892884,2287.10715 378.379948,2286.62009 L378.925125,2286.07491 C379.341689,2285.65834 380.006721,2285.61276 380.468236,2285.97891 C381.986864,2287.18399 383.908749,2287.90327 385.99999,2287.90327 C390.921195,2287.90327 394.903208,2283.92047 394.903208,2279.00002 C394.903208,2274.0788 390.920421,2270.09678 385.99999,2270.09678 C383.638024,2270.09678 381.492784,2271.01464 379.89993,2272.51285 L382.355767,2274.96869 C382.843508,2275.45644 382.498073,2276.29034 381.808364,2276.29034 L374.774193,2276.29034 C374.346596,2276.29034 374,2275.94374 374,2275.51615 L374,2268.48195 C374,2267.79224 374.833903,2267.44681 375.321644,2267.9345 L377.71061,2270.32347 C379.864947,2268.26465 382.784767,2267 385.99999,2267 C392.619823,2267 397.987737,2272.36033 397.999979,2278.97733 Z M389.347192,2282.57017 L389.778147,2281.98431 C390.135178,2281.49897 390.052479,2280.79951 389.593446,2280.42207 L387.80781,2278.95362 L387.80781,2274.11327 C387.80781,2273.49842 387.336405,2273 386.754881,2273 L386.052929,2273 C385.471405,2273 385,2273.49842 385,2274.11327 L385,2280.4056 L387.869626,2282.76545 C388.328659,2283.1429 388.990161,2283.05551 389.347192,2282.57017 Z"
      id="package-history"
    />
  </g>
);
