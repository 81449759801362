import React, { Component } from 'react';
import styled from 'styled-components';

import { baseFont } from 'utils/fonts';
import colors from 'utils/colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 400px;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 60px;
`;

const Num = styled.span`
  color: #c15d26;
  font-family: ${baseFont};
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  text-decoration: underline;
`;

const Subtitle = styled.span`
  color: #083e66;
  font-family: ${baseFont};
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;

class SystemActivity extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Wrapper>
        <Box>
          <Num style={{ color: '#3E3E58' }}>{data.avgdeliveries}</Num>
          <Subtitle style={{ color: '#3E3E58' }}>
            Average
            <br /> Daily Dropoffs
          </Subtitle>
        </Box>
        <Box>
          <Num style={{ color: '#5C7FA7' }}>
            {data.avgstorage}
            {data.avgstorage == 0 && ' hrs'}
            {data.avgstorage == 1 && ' hr'}
            {data.avgstorage > 1 && ' hrs'}
          </Num>
          <Subtitle style={{ color: '#5C7FA7' }}>
            Average
            <br /> Storage Time
          </Subtitle>
        </Box>
        <Box>
          <Num style={{ color: '#5CBA8F' }}>{data.avgpickups}</Num>
          <Subtitle style={{ color: '#5CBA8F' }}>
            Average
            <br /> Daily Pickups
          </Subtitle>
        </Box>
      </Wrapper>
    );
  }
}

export default SystemActivity;
