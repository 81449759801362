import React from 'react';
import styled, { css } from 'styled-components';

import colors from 'utils/colors';
import { mobile } from 'utils/media';

const MobileToggleWrapper = styled.div`
  display: none;
  ${mobile(css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 25px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover,
    &:focus {
      opacity: 0.8;
    }
    &:active {
      transform: scale(0.95);
      opacity: 0.6;
    }
    ${(p) =>
      p.isMobileNav
        ? css`
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
          `
        : css`
            transform: none;
            -webkit-transform: none;
          `};
  `)};
`;

const MobileToggleLine = styled.span`
  width: 25px;
  height: 4px;
  margin-bottom: 6px;
  border-radius: 1000px;
  background: ${(p) => colors[p.color]};
  display: block;
`;

const lastChild = {
  marginBottom: 0,
};

const MobileToggle = ({ onMobileNavToggle, isMobileNav, color }) => (
  <MobileToggleWrapper isMobileNav={isMobileNav} onClick={onMobileNavToggle}>
    <MobileToggleLine color={color} />
    <MobileToggleLine color={color} />
    <MobileToggleLine color={color} style={lastChild} />
  </MobileToggleWrapper>
);

export default MobileToggle;
