import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { css } from 'styled-components';
import ReactTable from 'react-table';
import {
  approvePendingUser,
  rejectPendingUser,
} from 'redux/modules/duck-admin';
import colors from 'utils/colors';
import GetTableSize from 'utils/getTableSize';
import { baseFont, boldFont } from 'utils/fonts';
import moment from 'moment';
import LongButton from 'common/buttons/longButton';

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const InstructionsDiv = styled.div`
  color: #083e66;
  font-family: 'Swiss721BTRegular';
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border: none;
  background: transparent;
`;

const PaginationButton = styled.button`
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 14px;
  letter-spacing: 0.71px;
  line-height: 18px;
  border: none;
`;
const ApproveButton = styled(LongButton)`
  width: 95px;
  padding: 5px;
  font-size: 14px;
  margin: 0 auto 10px auto;
  border: 2px solid ${colors.action1};
  color: ${colors.action1};
  &:hover {
    border-color: transparent;
  }
`;

const DeclineButton = styled(LongButton)`
  width: 95px;
  padding: 5px;
  font-size: 14px;
  margin: 0 auto 10px auto;
  border: 2px solid ${colors.action2};
  color: ${colors.action2};
  &:hover {
    border-color: transparent;
  }
`;

const PendingTable = ({
  pendingAccounts,
  loading,
  error,
  approvePendingUser,
  rejectPendingUser,
  system_id,
}) => {
  const { pageStart, pageSizeArray } = GetTableSize(pendingAccounts.length);
  // made in render method to have access to props
  const columns = [
    {
      Header: 'Name',
      headerClassName: 'table-header',
      width: 150,
      className: 'table-cell',
      Cell: ({ row }) => {
        const user = row._original;
        const fullName = `${user.first_name} ${user.last_name}`;

        if (user.first_name) {
          return fullName;
        } else {
          return '';
        }
      },
      accessor: 'last_name',
      sortMethod: (a, b, desc) => {
        if (desc) {
          if (a && !b) {
            return 1;
          }

          if (!a && b) {
            return -1;
          }

          if (!a && !b) {
            return 0;
          }

          if (a && b && a[0] > b[0]) {
            return 1;
          }

          if (a && b && a[0] < b[0]) {
            return -1;
          }

          return 0;
        } else {
          if (a && !b) {
            return -1;
          }

          if (!a && b) {
            return 1;
          }

          if (!a && !b) {
            return 0;
          }

          if (a && b && a[0] > b[0]) {
            return 1;
          }

          if (a && b && a[0] < b[0]) {
            return -1;
          }

          return 0;
        }
      },
    },
    {
      Header: 'Email',
      headerClassName: 'table-header',
      className: 'table-cell',
      accessor: 'email',
      width: 250,
      sortMethod: (a, b) => {
        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      },
    },
    {
      Header: 'Date Delivered',
      headerClassName: 'table-header',
      className: 'table-cell',
      accessor: 'created_at',
      Cell: ({ row }) => {
        const { created_at } = row._original;
        return moment(created_at).format('MM/DD/YYYY');
      },
    },
    {
      Header: 'Action',
      headerClassName: 'table-header',
      className: 'table-cell-vachold-action',
      Cell: ({ row }) => {
        const [actionStatus, setActionStatus] = useState(null);
        const { _id } = row._original;
        return (
          <Fragment>
            {(() => {
              switch (actionStatus) {
                case null:
                  return (
                    <ButtonWrapper>
                      <ApproveButton
                        onClick={() => {
                          setActionStatus('approve');
                          return approvePendingUser(_id, system_id);
                        }}
                        textColor="action1"
                        backgroundColor="white"
                      >
                        Approve
                      </ApproveButton>
                      <DeclineButton
                        onClick={() => {
                          setActionStatus('reject');
                          return rejectPendingUser(_id);
                        }}
                        textColor="action2"
                        backgroundColor="white"
                      >
                        Decline
                      </DeclineButton>
                    </ButtonWrapper>
                  );
                case 'approve':
                  return (
                    <InstructionsDiv style={{ color: '#90A1B5' }}>
                      APPROVED
                    </InstructionsDiv>
                  );
                case 'reject':
                  return (
                    <InstructionsDiv style={{ color: '#CB4C6F' }}>
                      REJECTED
                    </InstructionsDiv>
                  );
              }
            })()}
          </Fragment>
        );
      },
    },
  ];
  return (
    <ReactTable
      data={pendingAccounts}
      columns={columns}
      noDataText="No Pending Accounts"
      className="notifTable -highlight"
      pageSize={pageStart}
      pageSizeOptions={pageSizeArray}
      defaultSorted={[{ id: 'last_name' }]}
      minRows={5}
      loading={loading}
      loadingText="Loading..."
      resizable={false}
      rowsText={null}
      PreviousComponent={(props) => {
        return (
          <PaginationButton
            {...props}
            style={{
              color: props.disabled ? colors.accent4 : colors.base_text3,
            }}
          >
            PREV
          </PaginationButton>
        );
      }}
      NextComponent={(props) => {
        return (
          <PaginationButton
            {...props}
            style={{
              color: props.disabled ? colors.accent4 : colors.base_text3,
            }}
          >
            NEXT
          </PaginationButton>
        );
      }}
    />
  );
};

const mapStateToProps = ({ admin }) => {
  return {
    pendingAccounts: admin.pendingAccounts,
    loading: admin.loading,
    error: admin.error,
    system_id: admin.selectedSystem ? admin.selectedSystem.system._id : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      approvePendingUser,
      rejectPendingUser,
    },
    dispatch
  );
};

PendingTable.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingTable);
