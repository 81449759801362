import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LongButton from './longButton.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './InviteButton.css';

const InviteButton = ({
  onClick,
  clicked,
  loading,
  renderSpinner,
  error,
  link,
}) => {
  const [copied, setCopied] = useState(false);
  return (
    <div>
      <LongButton
        inviteBtn={true}
        onClick={clicked ? (e) => e.preventDefault() : onClick}
        textColor={clicked ? 'secondary_blue' : 'base_text3'}
        backgroundColor={
          copied ? 'action1' : clicked ? 'secondary_blue' : 'white'
        }
        copied={copied}
        type="submit"
        fontSize="14px"
        style={{
          width: '196px',
          height: '44px',
          border: `1px solid ${copied ? '#5CBA8F' : '#083E66'}`,
        }}
      >
        {clicked ? (
          <div style={{ display: 'flex', height: '95%' }}>
            <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
              <div
                id="copyLink"
                className="invBtn__copyBtn"
                style={{
                  backgroundColor: `${copied ? '#5CBA8F' : '#083E66'}`,
                  margin: 'auto',
                }}
              >
                {copied ? 'Copied' : 'Copy Link'}
              </div>
            </CopyToClipboard>
            <div
              id="link"
              className="invBtn__link"
              style={{
                backgroundColor: 'white',
                border: `0 solid ${copied ? '#5CBA8F' : '#083E66'}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {loading && renderSpinner()}
              {error && 'Could not generate link :('}
              {link && link}
            </div>
          </div>
        ) : (
          <p>GENERATE INVITE LINK</p>
        )}
      </LongButton>
      <p
        className={clicked ? 'invBtn__underText' : 'invBtn__underText--hidden'}
      >
        Copy and Send this link to your team.
      </p>
      <input
        type="text"
        value={link}
        id="hiddenLink"
        style={{ display: 'none' }}
      />
    </div>
  );
};

InviteButton.propTypes = {};

export default InviteButton;
