import React from 'react';

export default (props) => (
  <g id="Confirmation" transform="translate(1.000000, 1.000000)">
    <g id="Confirm">
      <g id="Confirmation">
        <g id="checkmark-outlined-circular-button">
          {!props.nocircle ? (
            <g id="circle_copy_4_7_" fillRule="nonzero">
              <path
                d="M10,19.375 C4.8225,19.375 0.625,15.1775 0.625,10.0003125 C0.625,4.8225 4.8225,0.625 10,0.625 C15.1775,0.625 19.375,4.8225 19.375,10.0003125 C19.375,15.1775 15.1775,19.375 10,19.375 Z"
                id="Shape"
              />
              <path
                d="M10,0 C4.4771875,0 0,4.4771875 0,10 C0,15.5228125 4.4771875,20 10,20 C15.5228125,20 20,15.5228125 20,10 C20,4.4771875 15.5228125,0 10,0 Z"
                id="Path"
                stroke={props.fill}
                strokeWidth="1.5"
              />
            </g>
          ) : null}
          <g
            id="check"
            transform="translate(5.625000, 6.875000)"
            fill={props.fill}
            stroke={props.fill}
          >
            <polygon
              id="Path"
              points="8.4971875 0.694375 8.0553125 0.2525 3.125 5.183125 0.694375 2.7525 0.2525 3.1946875 3.055625 5.9971875 3.125 5.9284375 3.194375 5.9971875"
            />
          </g>
        </g>
        }
      </g>
    </g>
  </g>
);
