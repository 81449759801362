const colors = {
  oj: '#FF9F00',
  blue: '#4c6fcb',
  white: '#FDFEFF',
  blue_gray: '#a2acc4',
  gray: '#8c8c90',
  background_gray: '#eeeef2',
  yellow: '#fcdc64',
  pink: '#FFD7D7',
  dark_pink: '#F5A2A3',
  warning_red: '#cb4c6f',
  // new colors
  primary_blue: '#21baea',
  secondary_blue: '#083e66',
  adjusted_blue: '#046084',
  nav_gray: '#b8b8b8',
  secondary_gray: '#323232',
  primary_light_gray: '#cdcdcd',
  secondary_light_gray: '#6e6e6e',

  // new colors based on UI Style Guide
  base_text1: '#131313',
  base_text2: '#15234A',
  base_text3: '#083E66',
  base_text4: '#445870',
  base1: '#DDDDDD',
  base2: '#F6F7F9',
  base3: '#FDFEFF',
  accent1: '#3E3E58',
  accent2: '#BEB7A6',
  accent3: '#C8AB85',
  accent4: '#E8E8E8',
  action1: '#5CBA8F',
  action2: '#CB4C6F',
  action3: '#C15D26',

  // colors specifically for table
  pending: '#eee5da',
  approved: '#C8AB85',
};
export default colors;
