import React, { Component } from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as registerActions from 'redux/modules/duck-auth';

import { Header } from 'common/Header';
import ForgotPasswordForm from './ForgotPasswordForm';
import Title from 'common/forms/FormTitle';
import LongButton from 'common/buttons/longButton.js';

import SvgIcon from 'common/icons/SvgIcon';
import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

const NewHeader = styled(Header)`
  height: 501px;
  width: 751px;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-top: 50px;
`;

const Success = styled.div`
  font-family: ${baseFont};
  height: 42px;
  width: 324px;
  color: ${colors.base_text3};
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin: 25px 0;
`;

const BackButton = styled(LongButton)`
  margin: 25px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  height: 55px;
  width: 257px;
`;

class ForgotPassword extends Component {
  render() {
    const { auth, form, history } = this.props;
    return !this.props.auth.forgot_success ? (
      <NewHeader landing={true}>
        <Title
          color="base_text2"
          style={{ fontSize: '24px', lineHeight: '65px' }}
        >
          RESET PASSWORD
        </Title>
        <ForgotPasswordForm
          err={auth.error}
          loading={auth.loading}
          onForgotPasswordSubmit={() => {
            this.props.forgotPassword(form.forgotPasswordForm.values, history);
          }}
        />
      </NewHeader>
    ) : (
      <NewHeader>
        <SvgIcon
          icon="checkmark"
          height="61px"
          width="61px"
          fill={colors.action1}
          style={{ marginRight: '15px' }}
          viewBox="0 0 22 22"
        />
        <Success>
          Check your email and follow the instructions to reset your password.
        </Success>
        <Link to="/dashboard/login">
          <BackButton textColor="white" backgroundColor="base_text2">
            BACK TO LOGIN
          </BackButton>
        </Link>
      </NewHeader>
    );
  }
}

function mapStateToProps({ auth, form }) {
  return { auth, form };
}

export default connect(mapStateToProps, registerActions)(ForgotPassword);
