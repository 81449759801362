import React from 'react';
import styled, { css } from 'styled-components';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';

import '../../../node_modules/pretty-checkbox/dist/pretty-checkbox.css';
import './renderCheckbox.css';
import 'common/styles/tooltip.css';

const Input = styled.input``;

const Label = styled.label`
  font-family: ${baseFont};
  font-size: 16px;
  color: ${(p) =>
    p.formtype === 'register' ? colors.base_text3 : colors.base_text3};
  text-transform: uppercase;
`;

const Wrapper = styled.div.attrs({
  className: 'pretty p-default p-thick p-smooth',
})`
  margin: auto 25px;
  ${mobile(css`
    margin: 25px 0px;
  `)};
`;

const Overlay = styled.h1`
  min-height: 32px;
  height: auto;
  width: 215px;
  color: #ffffff;
  font-family: ${baseFont};
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
`;

const TooltipWrap = (children, field) => {
  let overlay;
  if (field === 'ada') {
    overlay = (
      <div>
        We are proud to offer services compliant with the Americans with
        Disabilities Act. Click{' '}
        <a
          href="https://www.ada.gov/"
          style={{ color: 'gray', textDecoration: 'underline' }}
        >
          here
        </a>{' '}
        for more information
      </div>
    );
  }
  if (field === 'sms_notification') {
    overlay = 'Receive a text when your package is delivered';
  }
  if (field === 'email_notification') {
    overlay = 'Receive an email when your package is delivered';
  }
  return (
    <Tooltip
      placement="bottom"
      trigger={['hover']}
      prefixCls="rc-tooltip-ada"
      overlay={<Overlay>{overlay}</Overlay>}
    >
      {children}
    </Tooltip>
  );
};

const Main = (field) => (
  <Wrapper>
    <Input
      {...field.input}
      type={field.type}
      defaultChecked={field.defaultValue}
    />
    <div
      className={
        field.input.name === 'ada'
          ? 'state ada'
          : field.formtype && field.formtype === 'register'
          ? 'state register'
          : 'state'
      }
    >
      <Label formtype={field.formtype}>{field.label}</Label>
    </div>
    {field.meta.touched && field.meta.error && (
      <span className="error">{field.meta.error}</span>
    )}
  </Wrapper>
);

export default (field) => {
  if (field.defaultValue) {
    delete field.input.checked;
  }
  return (
    <div style={{ width: 'auto' }}>
      {!field.settingspage
        ? TooltipWrap(Main(field), field.input.name)
        : Main(field)}
    </div>
  );
};
