import React from 'react';
import styled, { css } from 'styled-components';

import ErrorMessage from '../Error';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 25px auto;
  width: 500px;
  justify-content: space-between;
  ${mobile(css`
    width: 100%;
    margin: 25px 0;
  `)};
`;

const Label = styled.label`
  float: left;
  font-family: ${baseFont};
  font-size: 18px;
  color: ${colors.primary_blue};
  text-transform: uppercase;
  margin-top: 10px;
`;

const SelectWrap = styled.div`
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.primary_blue} transparent transparent;
  }
  ${mobile(
    css`
      width: 100%;
    `
  )};
`;

const SelectField = styled.select`
  float: right;
  padding: 0px 5px;
  width: 175px;
  font-family: ${baseFont};
  font-size: 16px;
  font-weight: bold;
  color: ${colors.primary_blue};
  background: transparent;
  border-radius: 3px;
  border-color: ${colors.primary_blue};
  letter-spacing: 2px;
  text-transform: uppercase;
  ${mobile(css`
    width: 100%;
    margin: 25px 0;
  `)};
`;

const OptionField = styled.option``;

export default (props) => {
  const { input, label, type, meta, level } = props;
  delete input.value;
  input.defaultValue = level;

  return (
    <Wrapper>
      <Label>{label}:</Label>
      <SelectWrap>
        <SelectField {...input} type={type}>
          <OptionField value="2">Admin</OptionField>
          <OptionField value="0">User</OptionField>
        </SelectField>
      </SelectWrap>
      {!meta.active && meta.touched && meta.error && (
        <ErrorMessage className="error">{meta.error}</ErrorMessage>
      )}
    </Wrapper>
  );
};
