import React from 'react';

export default (props) => (
  <g
    id="UI-Style-Guide"
    transform="translate(-483.000000, -2268.000000)"
    fill={props.fill}
    fillRule="nonzero"
  >
    <path
      d="M496,2274 L493,2274 C492.4475,2274 492,2274.47946 492,2275.07143 L492,2287.92857 C492,2288.52054 492.4475,2289 493,2289 L496,2289 C496.5525,2289 497,2288.52054 497,2287.92857 L497,2275.07143 C497,2274.47946 496.5525,2274 496,2274 Z M487,2281 L484,2281 C483.4475,2281 483,2281.4475 483,2282 L483,2288 C483,2288.5525 483.4475,2289 484,2289 L487,2289 C487.5525,2289 488,2288.5525 488,2288 L488,2282 C488,2281.4475 487.5525,2281 487,2281 Z M504,2268 L501,2268 C500.4475,2268 500,2268.46987 500,2269.05 L500,2287.95 C500,2288.53013 500.4475,2289 501,2289 L504,2289 C504.5525,2289 505,2288.53013 505,2287.95 L505,2269.05 C505,2268.46987 504.5525,2268 504,2268 Z"
      id="dashboard"
    />
  </g>
);
