import React from 'react';

export default (props) => (
  <svg
    // width="30px"
    // height="30px"
    viewBox="0 0 500 500"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="holdRequest"
      stroke="none"
      stroke-width="1"
      fill={props.fill}
      fill-rule="evenodd"
    >
      <g
        id="timetable"
        transform="translate(1.000000, 1.000000)"
        fill={props.fill}
        fill-rule="nonzero"
      >
        <path
          d="M482,292.25 L482,46 C482,37.714844 475.285156,31 467,31 L391,31 L391,15 C391,6.714844 384.285156,0 376,0 C367.714844,0 361,6.714844 361,15 L361,31 L301,31 L301,15 C301,6.714844 294.285156,0 286,0 C277.714844,0 271,6.714844 271,15 L271,31 L211,31 L211,15 C211,6.714844 204.285156,0 196,0 C187.714844,0 181,6.714844 181,15 L181,31 L121,31 L121,15 C121,6.714844 114.285156,0 106,0 C97.714844,0 91,6.714844 91,15 L91,31 L15,31 C6.714844,31 0,37.714844 0,46 L0,437 C0,445.285156 6.714844,452 15,452 L264.804688,452 C289.054688,488.152344 330.292969,512 377,512 C451.4375,512 512,451.4375 512,377 C512,344.929688 500.75,315.4375 482,292.25 Z M91,61 L91,76 C91,84.285156 97.714844,91 106,91 C114.285156,91 121,84.285156 121,76 L121,61 L181,61 L181,76 C181,84.285156 187.714844,91 196,91 C204.285156,91 211,84.285156 211,76 L211,61 L271,61 L271,76 C271,84.285156 277.714844,91 286,91 C294.285156,91 301,84.285156 301,76 L301,61 L361,61 L361,76 C361,84.285156 367.714844,91 376,91 C384.285156,91 391,84.285156 391,76 L391,61 L452,61 L452,121 L30,121 L30,61 L91,61 Z M30,422 L30,151 L452,151 L452,264.804688 C430.535156,250.410156 404.730469,242 377,242 C329.601562,242 287.835938,266.558594 263.742188,303.613281 C261.714844,302.589844 259.429688,302 257,302 L227,302 C218.714844,302 212,308.714844 212,317 C212,325.285156 218.714844,332 227,332 L249.722656,332 C246.335938,341.554688 243.992188,351.601562 242.839844,362 L227,362 C218.714844,362 212,368.714844 212,377 C212,385.285156 218.714844,392 227,392 L242.839844,392 C243.992188,402.398438 246.332031,412.445312 249.722656,422 L30,422 Z M377,482 C319.101562,482 272,434.898438 272,377 C272,319.101562 319.101562,272 377,272 C434.898438,272 482,319.101562 482,377 C482,434.898438 434.898438,482 377,482 Z"
          id="Shape"
        />
        <path
          d="M437,362 L392,362 L392,317 C392,308.714844 385.285156,302 377,302 C368.714844,302 362,308.714844 362,317 L362,377 C362,385.285156 368.714844,392 377,392 L437,392 C445.285156,392 452,385.285156 452,377 C452,368.714844 445.285156,362 437,362 Z"
          id="Path"
        />
        <path
          d="M136,182 L106,182 C97.714844,182 91,188.714844 91,197 C91,205.285156 97.714844,212 106,212 L136,212 C144.285156,212 151,205.285156 151,197 C151,188.714844 144.285156,182 136,182 Z"
          id="Path"
        />
        <path
          d="M136,242 L106,242 C97.714844,242 91,248.714844 91,257 C91,265.285156 97.714844,272 106,272 L136,272 C144.285156,272 151,265.285156 151,257 C151,248.714844 144.285156,242 136,242 Z"
          id="Path"
        />
        <path
          d="M136,302 L106,302 C97.714844,302 91,308.714844 91,317 C91,325.285156 97.714844,332 106,332 L136,332 C144.285156,332 151,325.285156 151,317 C151,308.714844 144.285156,302 136,302 Z"
          id="Path"
        />
        <path
          d="M227,212 L257,212 C265.285156,212 272,205.285156 272,197 C272,188.714844 265.285156,182 257,182 L227,182 C218.714844,182 212,188.714844 212,197 C212,205.285156 218.714844,212 227,212 Z"
          id="Path"
        />
        <path
          d="M227,272 L257,272 C265.285156,272 272,265.285156 272,257 C272,248.714844 265.285156,242 257,242 L227,242 C218.714844,242 212,248.714844 212,257 C212,265.285156 218.714844,272 227,272 Z"
          id="Path"
        />
        <path
          d="M136,362 L106,362 C97.714844,362 91,368.714844 91,377 C91,385.285156 97.714844,392 106,392 L136,392 C144.285156,392 151,385.285156 151,377 C151,368.714844 144.285156,362 136,362 Z"
          id="Path"
        />
        <path
          d="M347,212 L377,212 C385.285156,212 392,205.285156 392,197 C392,188.714844 385.285156,182 377,182 L347,182 C338.714844,182 332,188.714844 332,197 C332,205.285156 338.714844,212 347,212 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);
