import React from 'react';

export default (props) => (
  <g
    id="UI-Style-Guide"
    transform="translate(-533.000000, -2271.000000)"
    fill={props.fill}
    fillRule="nonzero"
  >
    <path
      d="M551.82741,2271.00182 C549.924599,2271.10595 546.142589,2271.48496 543.807826,2272.86308 C543.646713,2272.95817 543.555391,2273.12725 543.555391,2273.30404 L543.555391,2285.48713 C543.555391,2285.87384 543.993941,2286.11826 544.363739,2285.9388 C546.765864,2284.77296 550.239882,2284.45488 551.957621,2284.36782 C552.544089,2284.33803 553,2283.88468 553,2283.34127 L553,2272.02972 C553.000347,2271.43675 552.467004,2270.967 551.82741,2271.00182 Z M542.192173,2272.86308 C539.857758,2271.48496 536.075748,2271.10628 534.172937,2271.00182 C533.533343,2270.967 533,2271.43675 533,2272.02972 L533,2283.3416 C533,2283.88535 533.455911,2284.3387 534.042379,2284.36816 C535.760812,2284.45521 539.236567,2284.77363 541.638692,2285.94014 C542.007448,2286.11927 542.444608,2285.87518 542.444608,2285.48947 L542.444608,2273.29801 C542.444608,2273.12089 542.353635,2272.95851 542.192173,2272.86308 Z"
      id="training"
    />
  </g>
);
