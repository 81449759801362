import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  logOutUser,
  onNavToggle,
  fetchDirectory,
} from 'redux/modules/duck-auth';
import { fetchUserParcels } from 'redux/modules/duck-parcels';

import Nav from 'common/Nav';
import UserDash from './UserDash';
import Logs from './Logs';
import Directory from './Directory';
import Settings from './Settings/Settings';
import SettingsForm from './Settings/SettingsForm';
import VacationHoldRequest from './VacationHoldRequest';
import Hamburger from './Hamburger';
import PageShell from 'common/PageShell';
import Notifications from 'common/notification';
import ProfileIcon from 'common/ProfileIcon';
import Footer from 'common/Footer';

import { belowfour } from 'utils/media';

const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
  height: auto;
  width: 100%;
  background: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  width: 100%;
  padding: 20px 25px;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  @media (max-width: 580px) {
    padding: 5px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  height: 35px;
  font-size: 16px;
  ${belowfour(css`
    font-size: 14px;
  `)};
`;

class Dashboard extends Component {
  componentDidMount() {
    if (localStorage.user_token) {
      this.props.fetchDirectory(this.props.history);
    }

    if (
      localStorage.user_token &&
      this.props.history.location.pathname !== '/dashboard/parcels'
    ) {
      this.props.fetchUserParcels(this.props.history);
    }
  }

  render() {
    // if mobile, close the side navbar automatically for space
    const { history, match, isAdmin, firstName, isNavOpen } = this.props;
    return (
      <Wrapper>
        <Nav app="dashboard" isAdmin={isAdmin} history={history} />
        <Content>
          <Notifications />
          <TopWrapper>
            {isNavOpen ? (
              <Hamburger
                color="base_text2"
                onClick={() => {
                  this.props.onNavToggle();
                }}
              />
            ) : (
              <div />
            )}
            {firstName && firstName.length > 0 ? (
              <ProfileIcon
                firstName={firstName}
                isAdmin={isAdmin}
                mode="user"
                logOut={this.props.logOutUser}
              />
            ) : null}
          </TopWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Switch>
              <Route
                exact
                path={`${match.url}/parcels`}
                component={PageShell(UserDash)}
              />
              <Route
                exact
                path={`${match.url}/logs`}
                component={PageShell(Logs)}
              />
              <Route
                exact
                path={`${match.url}/directory`}
                component={PageShell(Directory)}
              />
              <Route
                path={`${match.url}/vacation`}
                component={PageShell(VacationHoldRequest)}
              />
              <Route
                exact
                path={`${match.url}/settings`}
                component={PageShell(Settings)}
              />
              <Route
                path={`${match.url}/settings/:setting`}
                component={PageShell(SettingsForm)}
              />
              <Redirect to={`${match.url}/parcels`} />
            </Switch>
            <Footer isAuthenticated={true} />
          </div>
        </Content>
      </Wrapper>
    );
  }
}

function mapStateToProps({ auth }) {
  const { isNavOpen } = auth;
  return { isNavOpen };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { logOutUser, onNavToggle, fetchUserParcels, fetchDirectory },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
