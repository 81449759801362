import React from 'react';
import styled, { css } from 'styled-components';

import ErrorMessage from '../Error';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';

const Wrapper = styled.div`
  display: block;
  ${(p) =>
    p.formtype === 'emails'
      ? `width:100%`
      : p.row === 'true'
      ? `width:225px; margin: 25px 25px;
        `
      : !p.row && p.formtype === 'systemSettings'
      ? `width:100%;`
      : `
        width:500px;`};
`;

const InputWrapper = styled.div``;

const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 443px;
  padding: 12px 18px;

  line-height: 24px;
  font-family: ${baseFont};
  font-size: 16px;
  line-height: 19px;
  color: ${colors.base_text2};
  border: 1px solid #dddddd;
  text-align: left;
  transition: all 350ms ease-in-out;
  border-radius: 2px;
  &::placeholder {
    opacity: 0.6;
    color: #131313;
    font-family: ${baseFont};
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }
  &:focus {
    outline: none;
    + span {
      &:after {
        transform: scaleX(1.01);
      }
    }
    &::placeholder {
      opacity: 0.1;
    }
  }
  + span {
    &:after {
      margin-top: -1.5px;
      z-index: 999;
      display: block;
      content: '';
      border-bottom: 2px solid
        ${(p) =>
          p.formtype === 'landing' || p.formtype === 'register'
            ? colors.secondary_blue
            : colors.primary_blue};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
  }
  ${(p) =>
    p.error
      ? `
      border-bottom: 2px solid ${colors.action2};
    `
      : null} ${mobile(css`
    text-align: left;
    width: 100%;
  `)};
`;

const InputDisabled = styled(Input)`
  margin: 10px 0 -35px 0;
  color: ${colors.primary_gray};
  border: none;
`;

const Label = styled.span`
  color: ${colors.primary_blue};
  font-size: 18px;
  ${mobile(css`
    float: left;
  `)};
`;

export default (field) => {
  if (field.defaultValue) {
    delete field.input.value;
    field.input.defaultValue = field.defaultValue;
  }
  if (field.formtype === 'userSettings' || field.formtype === 'emails') {
    if (!field.meta.active && field.meta.visited) {
      field.meta.touched = true;
    }
  }

  return (
    <Wrapper row={field.row} formtype={field.formtype}>
      {field.formtype === 'systemSettings' ||
      field.formtype === 'userSettings' ? (
        <Label>{field.label}</Label>
      ) : null}
      {field.label === 'Primary Email' ||
      (field.label === 'Email' && field.formtype === 'EditUser') ? (
        <InputDisabled
          {...field.input}
          type={field.type}
          formtype={field.formtype}
          disabled={true}
          error={!field.meta.active && field.meta.touched && field.meta.error}
        />
      ) : (
        <InputWrapper>
          {field.label !== 'Phone Number' ? (
            <Input
              {...field.input}
              type={field.type}
              placeholder="Eg.john@example.com"
              formtype={field.formtype}
              autoComplete="off"
              error={
                !field.meta.active && field.meta.touched && field.meta.error
              }
            />
          ) : null}
        </InputWrapper>
      )}
      {field.formtype !== 'userSettings' &&
        field.meta.touched &&
        field.meta.error && (
          <ErrorMessage className="error">{field.meta.error}</ErrorMessage>
        )}
      {field.formtype === 'userSettings' &&
        field.meta.touched &&
        field.meta.dirty &&
        field.meta.error && (
          <ErrorMessage className="error">{field.meta.error}</ErrorMessage>
        )}
    </Wrapper>
  );
};
