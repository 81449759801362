import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
import {
  fetchUserLogs,
  changeLogSort,
  resetLogSort,
  sendSMS,
  sendEmail,
} from 'redux/modules/duck-parcels';

import { Header } from 'common/Header';
import LogTable from 'common/LogTable';

import colors from 'utils/colors';
import { baseFont, boldFont } from 'utils/fonts';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SelectWrapper = styled.div`
  display: block;
  align-self: flex-start;
  width: 221px;
  margin-bottom: 50px;
`;

const SelectWrap = styled.div`
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.base_text3} transparent transparent;
  }
`;

const SelectField = styled.select`
  width: 100%;
  height: 25px;
  font-family: ${baseFont};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.base_text3};
  background: white;
  border-radius: 3px;
  border-color: ${colors.base_text3};
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 16px;
`;

const NA = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color: ${colors.primary_blue};
  font-family: ${boldFont};
`;

const DateTitle = styled.h1``;

const LogContainer = styled.tbody`
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: space-between;
`;

const Log = styled.tr`
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  line-height: 22px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 50px;
  align-self: flex-start;
`;

class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: 'one_month',
      search: '',
    };

    this.handleTimeSelect = this.handleTimeSelect.bind(this);
  }
  componentWillMount() {
    if (localStorage.user_token) {
      this.props.fetchUserLogs('one_month', this.props.history);
    }
  }

  componentWillUnmount() {
    this.props.resetLogSort();
  }

  renderTimelineSelect() {
    return (
      <SelectWrapper>
        <SelectWrap>
          <SelectField onChange={this.handleTimeSelect}>
            <option value="one_month">Last Month</option>
            <option value="three_month">Last 3 Months</option>
            <option value="one_year">Last Year</option>
            <option value="all">All</option>
          </SelectField>
        </SelectWrap>
      </SelectWrapper>
    );
  }

  handleTimeSelect(e) {
    if (e.target.value !== this.state.selectedTime) {
      this.setState(
        {
          selectedTime: e.target.value,
        },
        () => {
          this.props.fetchUserLogs(this.state.selectedTime, this.props.history);
        }
      );
    }
  }

  render() {
    const {
      parcels,
      log_loading,
      sortBy,
      ascending,
      changeLogSort,
      sendSMS,
      sendEmail,
      user,
    } = this.props;
    return (
      <Header>
        <Title>Package History</Title>
        {this.renderTimelineSelect()}
        <LogTable
          parcels={parcels}
          log_loading={log_loading}
          sortBy={sortBy}
          ascending={ascending}
          changeLogSort={changeLogSort}
          accessLabel={true}
          sendSMS={sendSMS}
          sendEmail={sendEmail}
          user={user}
        />
      </Header>
    );
  }
}

function mapStateToProps(state) {
  const { log_loading, sortBy, ascending, log_parcels } = state.parcels;
  const { user } = state.auth;

  return {
    parcels: log_parcels,
    log_loading,
    sortBy,
    ascending,
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchUserLogs, changeLogSort, resetLogSort, sendEmail, sendSMS },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logs);
