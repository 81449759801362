import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { css } from 'styled-components';
import ReactTable from 'react-table';
import colors from 'utils/colors';
import GetTableSize from 'utils/getTableSize';
import moment from 'moment';

import { baseFont, boldFont } from 'utils/fonts';

const columns = [
  {
    Header: 'Name',
    headerClassName: 'table-header',
    width: 150,
    className: 'table-cell',
    accessor: 'name',
    sortMethod: (a, b, desc) => {
      if (desc) {
        if (a && !b) {
          return 1;
        }

        if (!a && b) {
          return -1;
        }

        if (!a && !b) {
          return 0;
        }

        if (a && b && a[0] > b[0]) {
          return 1;
        }

        if (a && b && a[0] < b[0]) {
          return -1;
        }

        return 0;
      } else {
        if (a && !b) {
          return -1;
        }

        if (!a && b) {
          return 1;
        }

        if (!a && !b) {
          return 0;
        }

        if (a && b && a[0] > b[0]) {
          return 1;
        }

        if (a && b && a[0] < b[0]) {
          return -1;
        }

        return 0;
      }
    },
  },
  {
    Header: 'Courier',
    headerClassName: 'table-header',
    className: 'table-cell',
    accessor: 'courier',
    width: 250,
  },
  {
    Header: 'Date Delivered',
    headerClassName: 'table-header',
    className: 'table-cell',
    accessor: 'dateDelivered',
    Cell: ({ row }) => {
      const { dateDelivered } = row._original;
      return moment(dateDelivered).format('MM/DD/YYYY');
    },
  },
  {
    Header: 'Access Code',
    headerClassName: 'table-header',
    className: 'table-cell',
    accessor: 'pin',
  },
];

const PaginationButton = styled.button`
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 14px;
  letter-spacing: 0.71px;
  line-height: 18px;
  border: none;
`;

const UnregisteredTable = ({ unregisteredUsers, loading, error }) => {
  const { pageStart, pageSizeArray } = GetTableSize(unregisteredUsers.length);
  return (
    <ReactTable
      data={unregisteredUsers}
      columns={columns}
      noDataText="No Unregistered Users"
      className="notifTable -highlight"
      pageSize={pageStart}
      pageSizeOptions={pageSizeArray}
      defaultSorted={[{ id: 'name' }]}
      minRows={5}
      loading={loading}
      loadingText="Loading..."
      resizable={false}
      rowsText={null}
      PreviousComponent={(props) => {
        return (
          <div>
            <PaginationButton
              {...props}
              style={{
                color: props.disabled ? colors.accent4 : colors.base_text3,
              }}
            >
              PREV
            </PaginationButton>
          </div>
        );
      }}
      NextComponent={(props) => {
        return (
          <PaginationButton
            {...props}
            style={{
              color: props.disabled ? colors.accent4 : colors.base_text3,
            }}
          >
            NEXT
          </PaginationButton>
        );
      }}
    />
  );
};

const mapStateToProps = ({ admin }) => {
  return {
    unregisteredUsers: admin.unregisteredUsers,
    loading: admin.loading,
    error: admin.error,
  };
};

UnregisteredTable.propTypes = {};

export default connect(
  mapStateToProps,
  null
)(UnregisteredTable);
