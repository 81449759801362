import React from 'react';

export default (props) => (
  <g
    id="User-Dashboard-filled-in"
    transform="translate(-733.000000, -217.000000)"
    fill={props.fill ? props.fill : '#15234A'}
    fillRule="nonzero"
  >
    <g id="phone-icon" transform="translate(733.000000, 217.000000)">
      <circle id="Oval" cx="14.0958904" cy="21.1917808" r="1.05479452" />
      <path
        d="M17.0281313,6.32876712 L10.9718687,6.32876712 C10.6323838,6.32876712 10.3561644,6.59287218 10.3561644,6.91689554 L10.3561644,18.398173 C10.3561644,18.722475 10.6321985,18.9863014 10.9718687,18.9863014 L17.0281313,18.9863014 C17.3676162,18.9863014 17.6438356,18.7221963 17.6438356,18.398173 L17.6438356,6.91689554 C17.6436504,6.59259349 17.3676162,6.32876712 17.0281313,6.32876712 Z"
        id="Path"
      />
      <path
        d="M13.9996267,0 C6.26793512,0 0,6.26836 0,13.99972 C0,21.73108 6.26793512,28 13.9996267,28 C21.7313182,28 28,21.7314533 28,13.99972 C28,6.26798667 21.7313182,0 13.9996267,0 Z M19.4464096,20.9086267 C19.4464096,21.97804 18.5793372,22.8452 17.5098234,22.8452 L10.4898033,22.8452 C9.4203828,22.8452 8.55321702,21.9781333 8.55321702,20.9086267 L8.55321702,7.03014667 C8.55321702,5.96073333 9.42028947,5.09357333 10.4898033,5.09357333 L17.5098234,5.09357333 C18.5792439,5.09357333 19.4464096,5.96064 19.4464096,7.03014667 L19.4464096,20.9086267 Z"
        id="Shape"
      />
    </g>
  </g>
);
