import styled, { css } from 'styled-components';

import colors from 'utils/colors';
import { headerFont, boldFont } from 'utils/fonts';
import { mobile } from 'utils/media';
import rem from 'utils/rem';

export const RoundButton = styled.button`
  display: block;
  margin: 10px auto;
  width: 50%;
  height: ${rem(36)};
  border-radius: 30px;
  font-family: ${headerFont};
  color: ${(p) => p.textColor};
  font-size: 18px;
  cursor: pointer;
  background: ${(p) => p.buttonColor};
  border-color: ${colors.blue};
  &:hover {
    opacity: 0.8;
  }
  ${'' /* TODO: remove this for accessibility */}
  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }
  &:visited {
    color: ${colors.blue};
  }
  ${mobile(css`
    font-size: 14px;
  `)};
`;

export const SquareButton = styled.button`
  display: block;
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 50px;
  border-radius: 2px;
  margin: 25px 0;
  &:first-child {
    margin: 0;
  }
  font-family: ${headerFont};
  color: ${(p) => p.textColor};
  font-size: 20px;
  font-weight: 900;
  cursor: pointer;
  background: ${(p) => p.buttonColor};
  border-color: ${colors.blue};
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }
  &:visited {
    color: ${colors.blue};
  }
  ${mobile(css`
    font-size: 16px;
  `)};
`;

export const TransferButton = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  max-height: 50px;
  border-radius: 3px;
  margin: 25px 0;
  &:first-child {
    margin: 0;
  }
  font-family: ${boldFont};
  color: ${(p) => p.textColor};
  font-size: 30px;
  ${mobile(
    css`
      font-size: 20px;
    `
  )};
  font-weight: 900;
  cursor: pointer;
  background: ${colors.warning_red};
  transition: all 0.3s ease-in-out;
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }
  &:visited {
    color: ${colors.warning_red};
  }
  &:disabled {
    cursor: not-allowed;
    background: grey;
    border-color: grey;
  }
`;
