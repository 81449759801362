import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { Header } from 'common/Header';
import LongButton from 'common/buttons/longButton';
import PackageHold from 'common/PackageHoldLogoWhite.png';

import colors from 'utils/colors';
import { mobile } from 'utils/media';
import { baseFont, lightFont } from 'utils/fonts';

const Logo = styled.div`
  height: 81px;
  width: 450px;
  background-image: url(${PackageHold});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 25px;
`;

const Slogan = styled.span`
  color: #ffffff;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0.04px;
  margin-bottom: 0;
  font-family: ${baseFont};
  color: ${colors.white};
  cursor: default;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 25px 0;
  ${mobile(css`
    flex-direction: column;
  `)};
  a {
    margin: 5px;
  }
`;

const LoginButton = styled(LongButton)`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  height: 55px;
  width: 257px;
  border: none;
`;

const Jumbotron = (props) => (
  <Header landing={true}>
    <Logo />
    <Slogan>Package Security Simplified.</Slogan>
    <ButtonWrapper>
      <Link to="/dashboard/login">
        <LoginButton backgroundColor="base_text2" textColor="white">
          Login
        </LoginButton>
      </Link>
    </ButtonWrapper>
  </Header>
);

export default Jumbotron;
