import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as loginActions from 'redux/modules/duck-auth';

import { Header } from 'common/Header';
import AdminLoginForm from './AdminLoginForm';

import { headerFont } from 'utils/fonts';
import rem from 'utils/rem';

const NewHeader = styled(Header)`
  @media (max-width: 768px) {
    width: 350px;
  }
`;

const Title = styled.h1`
  font-family: ${headerFont};
  margin: 0 auto;
  width: 50%;
  padding: ${rem(5)} 0;
  margin-bottom: ${rem(37)};
`;

class AdminLogin extends Component {
  componentWillUnmount() {
    if (this.props.auth.error) {
      this.props.clearMessages();
    }
  }

  render() {
    const { auth, form, history } = this.props;
    return (
      <NewHeader landing={true}>
        <Title>Manager Login</Title>
        <AdminLoginForm
          err={auth.error}
          loading={auth.loading}
          onLoginSubmit={() => {
            this.props.loginAdmin(form.adminLoginForm.values, history);
          }}
        />
      </NewHeader>
    );
  }
}

function mapStateToProps({ auth, form }) {
  return { auth, form };
}

export default connect(
  mapStateToProps,
  loginActions
)(AdminLogin);
