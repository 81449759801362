import React from 'react';
import styled, { css } from 'styled-components';

import ErrorMessage from '../Error';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';

const Wrapper = styled.div`
  display: block;
  margin: 25px 25px;
  text-align: center;
  ${(p) =>
    p.formtype === 'emails'
      ? `width:45%`
      : p.row === 'true'
      ? `width:275px; margin: 25px 50px;`
      : !p.row && p.formtype === 'systemSettings'
      ? `width:100%;text-align:left;`
      : `width:500px;`};

  ${mobile(css`
    width: 100%;
    margin: 25px auto;
  `)};
`;

const InputWrapper = styled.div``;

const Input = styled.input`
  background: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  padding: 0;
  text-align: center;

  ${(p) =>
    p.formtype === 'register'
      ? `height:30px;`
      : p.formtype === 'systemSettings'
      ? `height:30px; text-align:left; padding-left: 15px;`
      : `height:45px;`};
  font-size: 16px;
  line-height: 21px;
  font-family: ${baseFont};
  color: ${colors.base_text2};
  border: none;
  transition: all 350ms ease-in-out;
  -webkit-border-radius: 0px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  ::placeholder {
    color: ${colors.primary_gray};
    opacity: 0.75;
    transition: opacity 150ms ease-in-out;
  }

  ${(p) =>
    p.formtype === 'register' || p.formtype === 'systemSettings'
      ? `height:30px;font-size: 16px;`
      : `height:45px;font-size: 18px;`};

  ${(p) =>
    p.formtype === 'userSettings' ? `text-align:left; padding-left:5px;` : ``};

  &:focus {
    outline: none;
    + span {
      &:after {
        transform: scaleX(1.01);
      }
    }
    &::placeholder {
      opacity: 0.1;
    }
  }
  + span {
    &:after {
      margin-top: -1.5px;
      z-index: 999;
      display: block;
      content: '';
      border-bottom: 2px solid
        ${(p) =>
          p.formtype === 'landing' || p.formtype === 'register'
            ? colors.secondary_blue
            : colors.base_text3};
      transform: scaleX(0);

      transition: transform 250ms ease-in-out;
    }
  }
  ${(p) =>
    p.error
      ? `
      border-bottom: 2px solid ${colors.action2};
    `
      : null} ${mobile(css`
    text-align: left;
    width: 100%;
  `)};
`;

const InputDisabled = styled(Input)`
  margin: 10px 0 -35px 0;
  color: ${colors.primary_gray};
`;

const Label = styled.span`
  margin-bottom: 10px;
  text-align: left;
  float: left;
  color: ${colors.base_text3};
  font-size: 16px;
  ${mobile(css`
    float: left;
  `)};
`;

export default (field) => {
  if (field.defaultValue) {
    delete field.input.value;
    field.input.defaultValue = field.defaultValue;
  }
  if (field.formtype === 'userSettings' || field.formtype === 'emails') {
    if (!field.meta.active && field.meta.visited) {
      field.meta.touched = true;
    }
  }
  return (
    <Wrapper
      row={field.row}
      formtype={field.formtype}
      style={field.style}
      name={field.name}
    >
      {field.formtype === 'systemSettings' ||
      field.formtype === 'userSettings' ? (
        <Label>{field.label}</Label>
      ) : null}
      {field.label === 'Primary Email' ||
      (field.label === 'Email' && field.formtype === 'EditUser') ? (
        <InputDisabled
          {...field.input}
          type={field.type}
          formtype={field.formtype}
          error={!field.meta.active && field.meta.touched && field.meta.error}
        />
      ) : (
        <InputWrapper>
          {field.label !== 'Phone Number' ? (
            <Input
              {...field.input}
              type={field.type}
              placeholder={
                field.formtype !== 'systemSettings' ? field.placeholder : null
              }
              formtype={field.formtype}
              autoComplete="on"
              error={
                !field.meta.active && field.meta.touched && field.meta.error
              }
            />
          ) : null}
          <span />
        </InputWrapper>
      )}
      {field.formtype !== 'userSettings' &&
        field.meta.touched &&
        field.meta.error && (
          <ErrorMessage className="error">{field.meta.error}</ErrorMessage>
        )}
      {field.formtype === 'userSettings' &&
        field.meta.touched &&
        field.meta.dirty &&
        field.meta.error && (
          <ErrorMessage className="error">{field.meta.error}</ErrorMessage>
        )}
    </Wrapper>
  );
};
