import styled, { css } from 'styled-components';
import Img from 'common/PackageHoldLogoWhite.png';

import { mobile } from 'utils/media';

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-position: center;
  background-size: contain;
  background-image: url(${Img})};
  background-repeat: no-repeat;
  width: 245px;
  height: 34px;
  ${mobile(css`
    display: none;
  `)}
`;

export default Logo;
