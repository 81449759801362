import React, { Component } from 'react';
import { VictoryChart, VictoryAxis, VictoryLine, VictoryLabel } from 'victory';
import moment from 'moment';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

class VacationChart extends Component {
  checkEmpty() {
    const { data } = this.props;
    let empty = true;
    data.forEach(({ count }) => {
      if (count) {
        empty = false;
        return;
      }
    });
    return empty;
  }

  render() {
    let isEmpty;
    if (this.props.y === 'count') {
      // exclusive for locker usage card
      isEmpty = this.checkEmpty();
    }
    return !isEmpty ? (
      <VictoryChart
        domainPadding={{ x: 25, y: 75 }}
        height={400}
        width={1000}
        style={{
          axis: { stroke: colors.primary_blue },
        }}
      >
        <VictoryAxis
          tickFormat={(t) => `${moment(t).format('MMMM Do')}`}
          style={{
            axis: { stroke: colors.primary_blue },
            ticks: { stroke: colors.primary_blue, size: 5 },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
          }}
        />
        <VictoryAxis
          tickFormat={(t) => {
            return Number.isInteger(t) ? t : ``;
          }}
          tickLabelComponent={<VictoryLabel dx={-10} />}
          style={{
            axis: { stroke: colors.primary_blue },
            ticks: { stroke: colors.primary_blue },
            tickLabels: {
              fontSize: 30,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
          }}
          dependentAxis
        />
        <VictoryLine
          style={{
            data: { stroke: colors.primary_blue },
          }}
          animate={{
            duration: 1500,
            onLoad: { duration: 500 },
          }}
          interpolation="catmullRom"
          data={this.props.data}
          x={this.props.x}
          y={this.props.y}
        />
      </VictoryChart>
    ) : (
      'No Vacations in near future'
    );
  }
}

export default VacationChart;
