import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { inviteUser, generateLink } from 'redux/modules/duck-admin';

import InvitationForm from './InvitationForm';
import Title from 'common/forms/FormTitle';
import { Header } from 'common/Header';

class Invitation extends Component {
  render() {
    const {
      auth,
      owner_systems,
      genLink,
      genLinkLoading,
      genLinkError,
    } = this.props;

    return (
      <Header style={{ marginTop: '25px' }}>
        <Title>Invite Your Team</Title>
        <InvitationForm
          link={genLink}
          linkLoading={genLinkLoading}
          linkError={genLinkError}
          systems={owner_systems}
          err={auth.error}
          loading={auth.loading}
          onInvitationSubmit={(reset) => {
            // reset is a function to reset the form
            let result;
            const {
              emails,
              emails_csv,
            } = this.props.form.invitationForm.values;

            if (emails_csv) {
              result = emails.concat(emails_csv);
            } else {
              result = emails;
            }
            this.props.inviteUser(
              {
                system_id: this.props.selectedSystem.system._id,
                emails: result,
              },
              reset
            );
          }}
          onGenerateLink={(reset) => {
            const { selectedSystem, auth } = this.props;
            this.props.generateLink(
              { system_id: selectedSystem.system._id, email: auth.user.email },
              reset
            );
          }}
        />
      </Header>
    );
  }
}

function mapStateToProps({ auth, admin, form }) {
  const {
    owner_systems,
    selectedSystem,
    genLink,
    genLinkLoading,
    genLinkError,
  } = admin;
  return {
    auth,
    owner_systems,
    selectedSystem,
    genLink,
    genLinkLoading,
    genLinkError,
    form,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      inviteUser,
      generateLink,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invitation);
