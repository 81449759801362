import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import ErrorMessage from '../Error';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';

const Wrapper = styled.div`
  display: block;
  width: 500px;
  margin: 25px auto;
  ${mobile(css`
    width: 100%;
    margin: 25px 0;
  `)};
`;

const SelectWrap = styled.div`
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.secondary_blue} transparent transparent;
  }
`;

const SelectField = styled.select`
  padding: 0px 5px;
  width: 100%;
  height: 25px;
  font-family: ${baseFont};
  font-size: 16px;
  font-weight: bold;
  color: ${colors.secondary_blue};
  background: transparent;
  border-radius: 3px;
  border-color: ${colors.secondary_blue};
  letter-spacing: 2px;
  text-transform: uppercase;
`;

class renderSystemSelect extends Component {
  renderOptions() {
    const { systems } = this.props;
    return _.map(systems, (access) => (
      <option
        key={access.system ? access.system._id : access._id}
        value={access.system ? access.system._id : access._id}
      >
        {access.system ? access.system.nickname : access.nickname}
      </option>
    ));
  }
  render() {
    const { input, type, meta } = this.props;
    return (
      <Wrapper>
        <SelectWrap>
          <SelectField {...input} type={type} value={this.props.sysIndex}>
            {this.renderOptions()}
          </SelectField>
        </SelectWrap>
        {meta && !meta.active && meta.touched && meta.error && (
          <ErrorMessage className="error">{meta.error}</ErrorMessage>
        )}
      </Wrapper>
    );
  }
}

export default renderSystemSelect;
