import React, { Component } from 'react';
import styled from 'styled-components';
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryAnimation,
} from 'victory';

import _ from 'lodash';

import colors from 'utils/colors';

class ParcelUsage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <VictoryChart
        domainPadding={{
          x: this.props.tab === 'yeartodate' ? [10, 10] : [50, 50],
        }}
        width={450}
      >
        <VictoryBar
          data={this.props.data}
          labels={({ datum }) => {
            return datum.y;
          }}
          style={{
            data: {
              fontSize: 20,
              fontFamily: 'Swiss721BTRegular',
            },
            labels: {
              fontSize: 20,
              fill: colors.action3,
              fontFamily: 'Swiss721BTRegular',
            },
          }}
        />
        <VictoryAxis
          style={{
            axisLabel: {
              fontSize: 20,
              fontFamily: 'Swiss721BTRegular',
            },
            tickLabels: {
              fontSize: 14,
              fontFamily: 'Swiss721BTRegular',
            },
          }}
        />
      </VictoryChart>
    );
  }
}

export default ParcelUsage;
