import React from 'react';
import colors from 'utils/colors';

export default (props) => (
  <g id="UI-Style-Guide" transform="translate(-487.000000, -2329.000000)">
    <g
      id="Expired-Packages-Notification"
      transform="translate(487.000000, 2329.000000)"
    >
      <circle id="Oval" fill="#CB4C6F" cx="16" cy="16" r="16" />
      <g
        id="!"
        transform="translate(13.000000, 5.000000)"
        fill="#CB4C6F"
        fontFamily="Swiss721BT-Bold, Swiss721 BT"
        fontSize="18"
        fontWeight="bold"
      >
        <text>
          <tspan x="0" y="17">
            !
          </tspan>
        </text>
      </g>
    </g>
  </g>
);
