import React from 'react';

export default (props) => (
  <g
    id="UI-Style-Guide"
    transform="translate(-282.000000, -2269.000000)"
    fill={props.fill}
  >
    <path
      d="M292,2289 C297.522847,2289 302,2284.52285 302,2279 C302,2273.47715 297.522847,2269 292,2269 C286.477153,2269 282,2273.47715 282,2279 C282.00881,2284.51919 286.480806,2288.99119 292,2289 Z M292,2287.95238 C296.944263,2287.95238 300.952381,2283.94426 300.952381,2279 C300.952381,2274.05574 296.944263,2270.04762 292,2270.04762 C287.055737,2270.04762 283.047619,2274.05574 283.047619,2279 C283.0544,2283.94145 287.058548,2287.9456 292,2287.95238 Z M292.334725,2279.18813 L295.104213,2276.41866 C295.276278,2276.24674 295.276278,2275.9678 295.104213,2275.79588 C294.932148,2275.62382 294.653496,2275.62382 294.48143,2275.79588 L291.711942,2278.56535 L288.942308,2275.79588 C288.770242,2275.62382 288.49159,2275.62382 288.319525,2275.79588 C288.14746,2275.9678 288.14746,2276.24674 288.319525,2276.41866 L291.08916,2279.18813 L288.319525,2281.95761 C288.14746,2282.12952 288.14746,2282.40847 288.319525,2282.58038 C288.405558,2282.66627 288.518311,2282.70929 288.630916,2282.70929 C288.743522,2282.70929 288.856275,2282.66627 288.942308,2282.58024 L291.711942,2279.81077 L294.48143,2282.58024 C294.567463,2282.66627 294.680216,2282.70929 294.792822,2282.70929 C294.905428,2282.70929 295.01818,2282.66627 295.104213,2282.58024 C295.276278,2282.40832 295.276278,2282.12938 295.104213,2281.95746 L292.334725,2279.18813 Z"
      id="delete-field"
    />
  </g>
);
