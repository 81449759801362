import React, { Component } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { resetLink, resetPassword } from 'redux/modules/duck-auth';

import { Header } from 'common/Header';
import Title from 'common/forms/FormTitle';
import ResetPasswordForm from './ResetPasswordForm';
import LoaderPage from 'common/LoaderPage';

const NewHeader = styled(Header)`
  height: 501px;
  width: 751px;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-top: 50px;
`;

class ResetPassword extends Component {
  componentWillMount() {
    const { history } = this.props;
    const { token } = this.props.match.params;

    this.props.resetLink(token, history);
  }

  render() {
    const { error, loading, form, history } = this.props;

    return loading ? (
      <LoaderPage color="accent1" />
    ) : (
      <NewHeader landing={true}>
        <Title
          color="base_text2"
          style={{ fontSize: '24px', lineHeight: '65px' }}
        >
          SET NEW PASSWORD
        </Title>
        <ResetPasswordForm
          err={error}
          loading={loading}
          onResetPasswordSubmit={() => {
            this.props.resetPassword(form.resetPasswordForm.values, history);
          }}
        />
      </NewHeader>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetLink,
      resetPassword,
    },
    dispatch
  );
}

function mapStateToProps({ auth, form }) {
  const { error, loading } = auth;
  return { error, loading, form };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
