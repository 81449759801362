import React, { Component } from 'react';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

class HzBarChart extends Component {
  render() {
    // const max = this.props.data[this.props.data.length - 1].count;
    return (
      <VictoryChart domainPadding={{ x: 25, y: 25 }} height={400}>
        <VictoryBar
          horizontal
          style={{
            data: {
              fill: colors.primary_blue,
            },
            labels: {
              fontSize: 15,
              padding: 5,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
          }}
          animate={{
            duration: 1500,
            onLoad: { duration: 500 },
          }}
          cornerRadius={3}
          data={this.props.data}
          labels={(d) => d.x}
          x={this.props.x}
          y={this.props.y}
        />
        <VictoryAxis
          offsetY={50}
          label="Packages Delivered To"
          tickCount={5}
          tickFormat={(t) => {
            return Number.isInteger(t) ? t : null;
          }}
          style={{
            axis: { stroke: colors.primary_blue },
            axisLabel: {
              fontSize: 15,
              padding: 30,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
            ticks: { stroke: colors.primary_blue, size: 5 },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
          }}
        />
      </VictoryChart>
    );
  }
}

export default HzBarChart;
