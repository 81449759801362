import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import NavItem from './NavItem';
import NavSeparator from './NavSeparator';
import Logo from './Logo';
import MobileToggle from './MobileToggle';
import MobileNavbar from './MobileNavbar';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';
import rem from 'utils/rem';

const Wrapper = styled.nav`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 75px;
  z-index: 999;
  font-family: ${baseFont};
  font-size: 18px;
  font-weight: 500;
  color: ${colors.secondary_blue};
  background-color: white;
`;

const NormalNavbar = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${rem(20)};
`;

const StartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const EndWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile(css`
    display: none;
  `)};
`;

const LogoLink = styled(Link)`
  display: inline-block;
  vertical-align: center;
`;

class Nav extends Component {
  render() {
    const { isMobileNav, onMobileNavToggle } = this.props;

    return (
      <Wrapper>
        <NormalNavbar>
          <StartWrapper>
            <LogoLink to="/dashboard">
              <Logo />
            </LogoLink>
          </StartWrapper>
          <EndWrapper>
            <NavItem to="/dashboard/login">Login</NavItem>
            <NavSeparator />
            <NavItem to="/dashboard/register">Sign Up</NavItem>
          </EndWrapper>
          <MobileToggle
            isMobileNav={isMobileNav}
            onMobileNavToggle={onMobileNavToggle}
            color="secondary_blue"
          />
        </NormalNavbar>
        <MobileNavbar
          isMobileNav={isMobileNav}
          onMobileNavToggle={onMobileNavToggle}
        />
      </Wrapper>
    );
  }
}

export default Nav;
