import { css } from 'styled-components';

export const belowthree = (inner) => css`
  @media (max-width: 350px) {
    ${inner};
  }
`;

export const belowfour = (inner) => css`
  @media (max-width: 400px) {
    ${inner};
  }
`;

export const belowFourHeight = (inner) => css`
  @media (max-height: 400px) {
    ${inner};
  }
`;

export const mobile = (inner) => css`
  @media (max-width: 767px) {
    ${inner};
  }
`;

export const phone = (inner) => css`
  @media (max-width: 500px) {
    ${inner};
  }
`;

export const large = (inner) => css`
  @media (min-width: 767px) and (max-width: 1024px) {
    ${inner};
  }
`;

export const xlarge = (inner) => {
  return css`
    @media (min-width: 1024px) and (max-width: 1440px) {
      ${inner};
    }
  `;
};

export const largest = (inner) => {
  return css`
    @media screen and (min-width: 1440px) {
      ${inner};
    }
  `;
};
