import React from 'react';

export default ({ fill, nocircle, special }) =>
  nocircle ? (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="HoldRequestSmall"
        stroke="none"
        strokeWidth="1"
        fill={fill}
        fillRule="evenodd"
      >
        <svg
          viewBox="0 0 550 550"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
        >
          <g>
            <g>
              <path
                d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
			c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
			h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"
              />
            </g>
          </g>

          <g>
            <g>
              <rect x="156" y="50%" width="40" height="40" />
            </g>
          </g>
          <g>
            <g>
              <rect x="236" y="50%" width="40" height="40" />
            </g>
          </g>
          <g>
            <g>
              <rect x="316" y="50%" width="40" height="40" />
            </g>
          </g>
        </svg>
      </g>
    </svg>
  ) : (
    <g
      id="Style-Guide"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="UI-Style-Guide" transform="translate(-132.000000, -2328.000000)">
        <g
          id="vacation-hold-icon"
          transform="translate(132.000000, 2328.000000)"
        >
          <circle
            id="Oval"
            fill={fill ? fill : '#5C7FA7'}
            cx="17"
            cy="17"
            r="17"
          />
          <svg
            viewBox="0 0 1000 1000"
            version="1.1"
            x={special ? '22%' : '25%'}
            y={special ? '22%' : '25%'}
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
          >
            <g>
              <g>
                <path
                  d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
			c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
			h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"
                />
              </g>
            </g>

            <g>
              <g>
                <rect x="156" y="250" width="40" height="40" />
              </g>
            </g>
            <g>
              <g>
                <rect x="236" y="250" width="40" height="40" />
              </g>
            </g>
            <g>
              <g>
                <rect x="316" y="250" width="40" height="40" />
              </g>
            </g>
          </svg>
        </g>
      </g>
    </g>
  );
