import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { onNavToggle } from 'redux/modules/duck-auth';
import { selectSystem } from 'redux/modules/duck-admin';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { admin_items, dashboard_items } from './navItems';

import Hamburger from 'Dashboard/Hamburger';

import SvgIcon from 'common/icons/SvgIcon';
import 'common/icons/icons.css';
import colors from 'utils/colors';
import { baseFont, boldFont, lightFont } from 'utils/fonts';
import defaultLogo from 'common/PackageHoldLogo.png';

// import { axiosInstance as axios } from 'redux/client'; // TESTING GENERATE PACKAGE - DELETE ONCE DONE

const Sidebar = styled.div`
  min-height: 100vh;
  background: ${colors.accent1};
  overflow: hidden;
  transition: all 350ms ease;
  box-sizing: border-box;
  ${(p) =>
    p.isNavOpen
      ? `min-width:233px;width:233px;`
      : `min-width:90px; width:90px;`};
`;

const Title = styled.div`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  font-family: ${baseFont};
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 7px;
  color: ${colors.primary_blue};
  background: white;
  cursor: default;
`;

const NavList = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Category = styled.div`
  ${(p) =>
    p.isNavOpen
      ? `min-width:233px;width:233px;`
      : `min-width:90px; width:90px;`}
  margin: 10px 0px;
`;

const CatTitle = styled.div`
  color: ${colors.white};
  font-family: ${baseFont};
  font-size: 14px;
  padding: 5px 15px;
  cursor: default;
`;

const NavItem = styled.div`
  list-style-type: none;
  cursor: pointer;
  span {
    height: 64px;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
    font-family: ${lightFont};
    font-size: 16px;
    background: transparent;
    color: ${colors.white};
    transition: all 0.2s ease-in-out;
    line-height: 16px;
    font-weight: 300;

    &:hover {
      color: ${colors.white};
      background: rgba(19, 19, 19, 0.3);
      font-size: 18px;
    }
    ${(p) =>
      p.active
        ? `{
      font: 16px ${baseFont};
      font-weight: bold;
      color: ${colors.white};
      background: rgba(19,19,19,.3);
      &:hover{
        label {
          transition: all 0.2s ease-in-out;
          color: ${colors.primary_blue};
          background: ${colors.white};
        };
      };
    }`
        : null};
  }
`;

const LogoLink = styled(Link)`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconBox = styled.div`
  ${(p) => (p.isNavOpen ? `  padding-left: 15px;` : `padding-left: 5px;`)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Count = styled.label`
  cursor: pointer;
  padding: 3px 7px;
  background: white;
  color: ${colors.nav_gray};
  border-radius: 3px;
  ${(p) =>
    p.active
      ? `{
    font: 16px ${boldFont};
    color: ${colors.white};
    background: ${colors.primary_blue};
    &:hover {
      color: ${colors.primary_blue};
      background: ${colors.white};
    }
  }`
      : null};
`;

const SelectWrapper = styled.div`
  display: block;
  width: 100%;
`;

const SelectWrap = styled.div`
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.base_text3} transparent transparent;
  }
`;

const SelectField = styled.select`
  width: 100%;
  height: 25px;
  font-family: ${baseFont};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.base_text3};
  background: white;
  border-radius: 3px;
  border-color: ${colors.base_text3};
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 16px;
`;

class Nav extends Component {
  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this);
    this.handleSystemChange = this.handleSystemChange.bind(this);
  }

  componentWillMount() {
    if (window.innerWidth > 600) {
      this.props.onNavToggle('on');
    } else {
      this.props.onNavToggle('off');
    }
    window.addEventListener('resize', this.resize.bind(this));
  }

  resize() {
    const { isNavOpen } = this.props;
    if (window.innerWidth > 725 && !isNavOpen) {
      this.props.onNavToggle('on');
    }
    if (window.innerWidth <= 725 && isNavOpen) {
      this.props.onNavToggle('off');
    }
  }

  onNavToggle = () => {
    this.props.onNavToggle();
  };

  renderLogo() {
    const { app, selectedSystem, isNavOpen } = this.props;
    const logoURL = selectedSystem
      ? selectedSystem.system
        ? selectedSystem.system.logoURL
        : null
      : null;

    // if navbar is minimized then show Hamburger
    if (app && !isNavOpen) {
      return (
        <Hamburger
          color="base_text2"
          onClick={() => {
            this.props.onNavToggle();
          }}
          style={{
            height: '100%',
            width: '100%',
            padding: 0,
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      );
    }

    //  Set default Logo to Digilock B&W logo. Display customized logo if it’s available. If both failed, then display ‘DIGILOCK’ text.
    if (app === 'dashboard' || !logoURL) {
      return (
        <LogoLink to="/dashboard">
          <img
            style={{
              maxHeight: '100px',
              maxWidth: '200px',
            }}
            src={defaultLogo}
            alt="logo"
          />
        </LogoLink>
      );
    } else if (logoURL) {
      return (
        <LogoLink to="/dashboard">
          <img
            style={{
              maxHeight: '60px',
              maxWidth: '200px',
            }}
            src={logoURL ? logoURL : defaultLogo}
            alt="logo"
          />
        </LogoLink>
      );
    } else {
      return 'DIGILOCK';
    }
  }

  renderSystemSelect() {
    const { systems } = this.props;
    return (
      <SelectWrapper>
        <SelectWrap>
          <SelectField onChange={this.handleSystemChange}>
            {_.map(systems, ({ system }) => {
              return (
                <option key={system._id} value={system._id}>
                  {system.nickname}
                </option>
              );
            })}
          </SelectField>
        </SelectWrap>
      </SelectWrapper>
    );
  }

  handleSystemChange(e) {
    if (this.props.selectSystemIndex !== e.target.selectedIndex) {
      this.props.selectSystem(e.target.selectedIndex);
    }
  }

  renderItems() {
    const { app, isOwner, parcels, counters, isNavOpen } = this.props;
    const { pathname } = this.props.history.location;
    const isMobile = window.innerWidth <= 600;

    // if mobile, close the side navbar automatically for space
    let url, navItems;
    if (app === 'dashboard') {
      url = '/dashboard';
      navItems = dashboard_items;
    }
    if (app === 'admin') {
      url = '/admin';
      navItems = admin_items;
    }
    return _.map(navItems, ({ category, items }, i) => {
      // system select
      if (isOwner && app === 'admin' && category === 'System') {
        category = (
          <div
            style={{
              marginBottom: isNavOpen ? '25px' : '-25px',
              visibility: isNavOpen ? 'visible' : 'hidden',
            }}
          >
            <p
              style={{
                paddingLeft: '5px',
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: 'bold',
              }}
            >
              Select System
            </p>
            {this.renderSystemSelect()}{' '}
          </div>
        );
      }
      // system settings only if owner has access
      if (
        app === 'admin' &&
        category === 'Settings' &&
        this.props.selectedSystem &&
        this.props.selectedSystem.level !== 1
      ) {
        return null;
      }
      if (category === 'User') {
        category = <div style={{ height: '115px' }} />;
      }
      return (
        <Category isNavOpen={isNavOpen} key={i}>
          <CatTitle category={category}>{category}</CatTitle>
          {_.map(items, ({ label, to, icon, viewBox }) => {
            let active = false;
            if (pathname.includes(to)) {
              active = true;
            }

            if (to === '/system_settings') {
              if (
                isOwner &&
                this.props.selectedSystem &&
                this.props.selectedSystem.level == 1
              ) {
                return (
                  <Link to={url + to} key={label}>
                    <NavItem
                      active={active}
                      onClick={isMobile ? this.onNavToggle : null}
                    >
                      <span>
                        <IconBox isNavOpen={isNavOpen} active={active}>
                          <SvgIcon
                            icon={icon}
                            style={{ marginRight: '10px' }}
                            height="20px"
                            width="15px"
                            viewBox={viewBox}
                            fill={colors.white}
                          />
                          {isNavOpen ? label : null}
                        </IconBox>
                      </span>
                    </NavItem>
                  </Link>
                );
              } else {
                return null;
              }
            }
            return (
              <Link to={url + to} key={label}>
                <NavItem
                  active={active}
                  onClick={isMobile ? this.onNavToggle : null}
                >
                  <span>
                    <IconBox isNavOpen={isNavOpen} active={active}>
                      <SvgIcon
                        icon={icon}
                        style={{
                          marginRight:
                            icon === 'holdRequestSmall-nocircle'
                              ? '5px'
                              : '10px',
                        }}
                        height="20px"
                        width="15px"
                        width={
                          icon === 'holdRequestSmall-nocircle' ? '20px' : '15px'
                        }
                        fill={colors.white}
                        viewBox={viewBox}
                      />
                      {isNavOpen ? label : null}
                    </IconBox>
                  </span>
                </NavItem>
              </Link>
            );
          })}
        </Category>
      );
    });
  }
  render() {
    const { isNavOpen } = this.props;

    return (
      <Sidebar isNavOpen={isNavOpen}>
        <Title>{this.renderLogo()}</Title>
        <NavList>{this.renderItems()}</NavList>
      </Sidebar>
    );
  }
}

function mapStateToProps({ auth, parcels, admin }) {
  const { isNavOpen, user } = auth;
  const {
    counters,
    systems,
    selectSystemIndex,
    selectedSystem,
    owner_systems,
  } = admin;

  return {
    isNavOpen,
    user,
    parcels: parcels.parcels,
    counters,
    systems,
    selectSystemIndex,
    selectedSystem,
    owner_systems,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onNavToggle, selectSystem }, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Nav)
);
