import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import configureStore from 'redux/configureStore';
import './index.css';
import 'core-js/fn/string/includes';

import App from './App';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <HttpsRedirect>
      <BrowserRouter>
        <Route path="/" component={App} />
      </BrowserRouter>
    </HttpsRedirect>
  </Provider>,
  document.getElementById('root')
);

// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();
