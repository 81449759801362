import React, { Component } from 'react';
import { VictoryPie, VictoryLabel } from 'victory';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

class BarChart extends Component {
  render() {
    if (this.props.data.length < 1 || this.props.noData) {
      return 'No enough data to display.';
    } else {
      return (
        <VictoryPie
          style={{
            labels: {
              fontSize: 18,
              margin: 10,
              fill: this.props.fillColor || colors.primary_blue,
              fontFamily: baseFont,
            },
          }}
          innerRadius={this.props.innerRadius || 0}
          colorScale={this.props.colorScale || 'grayscale'}
          labelRadius={this.props.labelRadius}
          animate={{
            duration: 1500,
            onLoad: { duration: 500 },
          }}
          data={this.props.data}
        />
      );
    }
  }
}

export default BarChart;
