import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchManagers, transferOwnership } from 'redux/modules/duck-admin';
import _ from 'lodash';

import LongButton from 'common/buttons/longButton';

import { baseFont } from 'utils/fonts';
import colors from 'utils/colors';
import LoaderPage from 'common/LoaderPage';

const SelectWrap = styled.div`
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 35%;
    right: 12px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.base_text3} transparent transparent;
  }
`;

const SelectField = styled.select`
  padding: 0px 5px;
  width: 650px;
  height: 42px;
  font-family: ${baseFont};
  font-size: 16px;
  color: ${colors.base_text3};
  background: transparent;
  border-radius: 2px;
  border: 1px solid #90a1b5;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 25px;
`;

const InputField = styled.input`
  margin: 25px 0;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  height: 50px;
  font-family: ${baseFont};
  font-size: 16px;
  color: ${colors.base_text3};

  &::placeholder {
    color: ${colors.primary_gray};
    opacity: 0.7;
    transition: opacity 150ms ease-in-out;
  }
  &:focus {
    outline: none;
  }
`;

const TransferButton = styled(LongButton)`
  box-sizing: border-box;
  margin: 25px;
  height: 34px;
  width: 226px;
  border: 1px solid #3e3e58;
  border-radius: 2px;


	font-family: ${baseFont};
	font-size: 14px;
	letter-spacing: 0.54px;
	line-height: 16px;
	text-align: center;
}
`;

class Transfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedManager: null,
      buttonDisable: true,
      confirmInput: '',
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.checkConfirm = this.checkConfirm.bind(this);
  }
  componentDidMount() {
    this.props.fetchManagers(this.props.system_id, this.props.history);
  }

  checkConfirm() {
    if (this.state.selectedManager) {
      if (
        this.state.confirmInput.toLowerCase() ===
        this.state.selectedManager.email.toLowerCase()
      ) {
        this.setState({ buttonDisable: false });
      } else if (this.state.buttonDisable === false) {
        this.setState({ buttonDisable: true });
      }
    }
  }

  handleSelect(e) {
    if (e.target.value === '') {
      this.setState({ selectedManager: {} }, this.checkConfirm);
    }
    this.setState(
      { selectedManager: this.props.managers[e.target.value] },
      this.checkConfirm
    );
  }

  handleConfirm(e) {
    this.setState({ confirmInput: e.target.value }, this.checkConfirm);
  }

  renderManagers() {
    const { managers } = this.props;
    return _.map(managers, (manager, index) => (
      <option value={index} key={manager._id}>
        {manager.first_name} {manager.last_name} ({manager.email})
      </option>
    ));
  }

  render() {
    const { transfer_loading } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '50px 25px',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {transfer_loading ? (
          <LoaderPage color="accent1" />
        ) : (
          <SelectWrap>
            <SelectField onChange={this.handleSelect}>
              <option value="">Select Manager</option>
              {this.renderManagers()}
            </SelectField>
          </SelectWrap>
        )}
        <InputField
          type="text"
          onChange={this.handleConfirm}
          disabled={!this.state.selectedManager}
          placeholder={
            this.state.selectedManager
              ? 'Type email of manager selected'
              : 'Select a Manager to transfer ownership...'
          }
        />
        <TransferButton
          textColor="white"
          backgroundColor="base_text3"
          type="button"
          onClick={() =>
            this.props.transferOwnership(
              this.props.system_id,
              this.state.selectedManager,
              this.props.history
            )
          }
          disabled={this.state.buttonDisable || !this.state.selectedManager}
        >
          COMPLETE TRANSFER
        </TransferButton>
        <TransferButton
          textColor="base_text3"
          backgroundColor="white"
          onClick={this.props.toggleModal}
          style={{ marginTop: '0px' }}
        >
          GO BACK
        </TransferButton>
      </div>
    );
  }
}

function mapStateToProps({ admin }) {
  const { managers, transfer_loading } = admin;
  return {
    managers,
    transfer_loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchManagers, transferOwnership }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transfer);
