export const dashboard_items = [
  {
    category: 'User',
  },
  {
    items: [
      {
        label: 'Dashboard',
        to: '/parcels',
        icon: 'packagenocircle',
        viewBox: '0 0 16 16',
      },
      {
        label: 'History',
        to: '/logs',
        icon: 'packagehistory',
        viewBox: '0 0 24 24',
      },
      {
        label: 'Directory',
        to: '/directory',
        icon: 'manageusers',
        viewBox: '0 0 26 19',
      },
      // {
      //   label: 'Help',
      //   to: '/training',
      //   icon: 'training',
      //   viewBox: '0 0 20 15',
      // },
    ],
  },
];

export const admin_items = [
  {
    category: 'System',
  },
  {
    items: [
      {
        label: 'Dashboard',
        to: '/dashboard',
        icon: 'dashboard',
        viewBox: '0 0 22 21',
      },
      {
        label: 'History',
        to: '/system-log',
        icon: 'packagehistory',
        viewBox: '0 0 24 24',
      },
      {
        label: 'Users',
        to: '/manage',
        icon: 'manageusers',
        viewBox: '0 0 26 19',
      },
      {
        label: 'System Settings',
        to: '/system_settings',
        icon: 'systemsettings',
        viewBox: '0 0 30 25',
      },
      // {
      //   label: 'Help',
      //   to: '/training',
      //   icon: 'training',
      //   viewBox: '0 0 20 15',
      // },
    ],
  },
];
