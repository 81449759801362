import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { changeRole } from 'redux/modules/duck-admin';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

const Confirm = styled.button`
  margin-top: 5px;
  color: #ffffff;
  font-family: ${baseFont};
  font-size: 12px;
  letter-spacing: 0.46px;
  line-height: 15px;
  text-align: center;

  box-sizing: border-box;
  height: 22px;
  width: 75.3px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  background: ${colors.action1};
  cursor: pointer;

  &:hover {
    color: ${colors.action1};
    background: ${colors.white};
    border-color: ${colors.action1};
  }
`;

class ConfirmButton extends Component {
  render() {
    const { role, _access, history } = this.props;
    return (
      <Confirm
        onClick={() => {
          this.props.changeRole(_access, role, history);
        }}
      >
        CONFIRM
      </Confirm>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeRole,
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(ConfirmButton);
