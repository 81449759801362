import React from 'react';
import styled from 'styled-components';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

const Title = styled.span`
  font-family: ${baseFont};
  color: ${(p) => (p.color ? colors[p.color] : colors.base_text3)};
  margin-bottom: 25px;
  font-size: 36px;
  line-height: 65px;
`;

export default (props) => <Title {...props} />;
