import React, { useEffect, Fragment } from 'react';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import 'common/styles/tooltip.css';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchNotificationsList, selectSystem } from 'redux/modules/duck-admin';
import SvgIcon from 'common/icons/SvgIcon';
import moment from 'moment';
import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import ExclamationPoint from '../common/icons/IconExclamationPoint';

const Icon = styled.i.attrs({
  className: (p) => p.icon,
})`
  cursor: pointer;
  color: ${colors.base_text3};
  transition: all 300ms ease-in-out;
  font-size: 25px;
  &:hover {
    transform: scale(1.15);
    ::after {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  ::before {
    border-radius: 30px;
    border: 2px solid ${colors.base_text3};
    padding: 5px;
  }
`;

const OverlayWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
`;

const Section = styled.div`
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0px;
  font-size: 14px;
`;

const NavItem = styled(Link)`
  margin: 0;
  transition: opacity 0.15s, transform 0.15s;
  cursor: pointer;
  text-decoration: none;
  font-family: ${baseFont};
  color: ${colors.base_text3};
  &:hover,
  &:focus {
    opacity: 0.5;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.5;
  }
  &:visited {
    color: ${colors.base_text3};
  }
`;

const Name = styled.span`
  font-size: 14px;
  cursor: default;
  text-decoration: none;
  font-family: ${baseFont};
  color: ${colors.white};
`;

const IconWrapper = styled.div`
  display: flex;
`;

const CircleIcon = styled.div`
  background: ${colors.base_text2};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const NoticeOverlay = styled.div`
  background: #131313;
  min-height: 32px;
  height: auto;
  width: 100%;
  color: #ffffff;
  font-family: ${baseFont};
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
`;

const NotificationList = styled.li`
  display: flex;
  margin-bottom: 20px;
  &:hover {
    background: '#F6F7F9';
  }
  &.notifList > *:first-child {
    min-width: 30px;
  }
`;

const NotifRectangle = styled.div`
  height: 18px;
  width: 18px;
  border: 1px solid ${colors.action3};
  border-radius: 2px;
  background-color: ${colors.action3};
  position: absolute;
  top: -45%;
  left: 65%;
  color: white;
  font-size: 0.8rem;
  text-align: center;
`;

const RowIcon = ({ type }) => {
  switch (type) {
    case 'vacation_request':
      return (
        <CircleIcon>
          <SvgIcon
            icon="holdRequestSmall-nocircle"
            fill="white"
            // viewbox is hard coded due to lack of understanding of svg design
            viewBox="-1 0 18 24"
          />
        </CircleIcon>
      );
    case 'unregistered_parcel':
      return (
        <SvgIcon
          viewBox="0 0 34 34"
          // height and width hard coded to match circleIcon for vacation
          height="30px"
          width="30px"
          icon="unregistered"
          fill="white"
        />
      );
    case 'request_registration':
      return (
        <SvgIcon
          viewBox="0 0 34 34"
          height="30px"
          width="30px"
          icon="pending"
          fill="white"
        />
      );
    default:
      return <p>unrecognized notification</p>;
  }
};

const RowText = ({ firstText, secondText }) => {
  return (
    <span>
      <h3 style={{ margin: '0 0 0 10px', fontSize: '16px' }}>{firstText}</h3>
      <h5 style={{ margin: '0 0 0 10px', fontSize: '14px' }}>{secondText}</h5>
    </span>
  );
};

const NotificationOverlay = ({ data }) => {
  return (
    <OverlayWrap>
      <h3 style={{ fontSize: '16px' }}>Notifications</h3>
      {!data.length && (
        <React.Fragment>
          <h5>
            No new Notifications, click{' '}
            <Link to="/admin/dashboard/notifications/holdrequest">here</Link> to
            navigate to the tables for hold requests, unregistered users, and
            pending accounts
          </h5>
        </React.Fragment>
      )}
      <div>
        {data.map((row) => {
          const { type, _id } = row;
          let fullName;
          let urlType = '';
          let dateOfVacation;
          switch (type) {
            case 'vacation_request':
              fullName = `${row.first_name} ${row.last_name}`;
              const hold_from = moment(row.hold_from).format('MM/DD/YYYY');
              const hold_to = moment(row.hold_to).format('MM/DD/YYYY');
              dateOfVacation = `${hold_from} - ${hold_to}`;
              urlType = 'holdrequest';
              break;
            case 'unregistered_parcel':
              fullName = row.requester;
              urlType = 'unregistered';
              break;
            case 'request_registration':
              fullName = `${row.first_name} ${row.last_name}`;
              urlType = 'pending';
              break;
            default:
              urlType = 'error';
              break;
          }
          return (
            <Fragment key={_id}>
              <Link
                to={`/admin/dashboard/notifications/${urlType}`}
                style={{ color: '#073E66' }}
              >
                <NotificationList className="notifList">
                  <RowIcon type={type} />
                  {type === 'vacation_request' && (
                    <RowText firstText={fullName} secondText={dateOfVacation} />
                  )}
                  {type === 'unregistered_parcel' && (
                    <RowText
                      firstText={fullName}
                      secondText="Unregistered User"
                    />
                  )}
                  {type === 'request_registration' && (
                    <RowText
                      firstText={fullName}
                      secondText="Account Approval Needed"
                    />
                  )}
                </NotificationList>
              </Link>
            </Fragment>
          );
        })}
      </div>
    </OverlayWrap>
  );
};

const Overlay = ({ firstName, mode, isAdmin, logOut }) => {
  return (
    <OverlayWrap>
      <NavItem to="/admin/dashboard/settings">
        <Section>
          <i className="icon-cog" style={{ marginRight: '10px' }} />
          Settings
        </Section>
      </NavItem>
      <NavItem onClick={logOut} to="/dashboard">
        <Section style={{ borderBottom: 'none' }}>
          <i className="icon-logout" style={{ marginRight: '10px' }} />
          Logout
        </Section>
      </NavItem>
    </OverlayWrap>
  );
};

const ProfileIcon = (props) => {
  const {
    mode,
    firstName,
    isAdmin,
    expired_parcels_counter,
    fetchNotificationsList,
    data,
    updateLoading,
    approveUser,
    rejectUser,
    selectedSystem,
  } = props;
  useEffect(() => {
    if (selectedSystem && selectedSystem.system._id) {
      fetchNotificationsList(selectedSystem.system._id);
    }
  }, [selectedSystem, updateLoading, approveUser, rejectUser]);

  const showNotifs = Array.isArray(data) && data.length;
  return (
    <div
      style={{
        display: 'flex',
        width: '100px',
        justifyContent: 'space-between',
        paddingRight: '25px',
      }}
    >
      <Tooltip
        placement="bottomRight"
        trigger={['click']}
        mouseEnterDelay={0.1}
        mouseLeaveDelay={0.5}
        prefixCls={'rc-tooltip-profileicon'}
        overlay={<Overlay {...props} />}
      >
        <CircleIcon>
          <Name style={{ cursor: 'pointer' }}>{firstName.toUpperCase()}</Name>
        </CircleIcon>
      </Tooltip>
    </div>
  );
};

function mapStateToProps({ admin }) {
  const {
    notificationsList,
    notificationsListLoading,
    notificationsListError,
    selectedSystem,
    updateLoading,
    approveUser,
    rejectUser,
  } = admin;

  return {
    data: notificationsList,
    loading: notificationsListLoading,
    error: notificationsListError,
    updateLoading,
    approveUser,
    rejectUser,
    selectedSystem,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchNotificationsList,
      selectSystem,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileIcon)
);
