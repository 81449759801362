import React, { Component } from 'react';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Spinner from 'common/Spinner';
import { loginFields } from 'common/forms/formFields';
import renderField from 'common/forms/renderField';
import { RoundButton } from 'common/buttons/button.js';
import ErrorMessage from 'common/Error';

import colors from 'utils/colors';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

class AdminLoginForm extends Component {
  renderFields() {
    return _.map(loginFields, ({ label, name, type, placeholder }) => (
      <Field
        component={renderField}
        key={name}
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
      />
    ));
  }

  render() {
    const { err, loading } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.props.onLoginSubmit)}>
        {this.renderFields()}
        <RoundButton
          textColor={colors.white}
          buttonColor={colors.blue}
          type="submit"
          style={{ overflow: 'hidden' }}
        >
          {loading ? <Loader color={colors.yellow} size="3" /> : 'SUBMIT'}
        </RoundButton>
        <Link to="/register">
          <RoundButton textColor={colors.yellow} buttonColor={colors.white}>
            Register
          </RoundButton>
        </Link>
        <Link to="/forgot">
          <RoundButton textColor={colors.yellow} buttonColor={colors.white}>
            Forgot Password?
          </RoundButton>
        </Link>
        {err ? <ErrorMessage>{err.message}</ErrorMessage> : null}
      </form>
    );
  }
}

export default reduxForm({
  form: 'adminLoginForm',
  touchOnBlur: false,
})(AdminLoginForm);
