import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as loginActions from 'redux/modules/duck-auth';

import { Header } from 'common/Header';
import LoginForm from './LoginForm';
import Title from 'common/forms/FormTitle';

const NewHeader = styled(Header)`
  height: 501px;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-top: 50px;
  @media (max-width: 768px) {
    width: 350px;
  }
`;

class Login extends Component {
  componentWillUnmount() {
    if (this.props.auth.error) {
      this.props.clearMessages();
    }
  }

  render() {
    const { auth, form, history } = this.props;
    return (
      <NewHeader landing={true}>
        <Title color="base_text2">LOGIN</Title>
        <LoginForm
          err={auth.error}
          loading={auth.loading}
          onLoginSubmit={() => {
            this.props.loginUser(form.loginForm.values, history);
          }}
        />
      </NewHeader>
    );
  }
}

function mapStateToProps({ auth, form }) {
  return { auth, form };
}

export default connect(
  mapStateToProps,
  loginActions
)(Login);
