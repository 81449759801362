import React from 'react';
import styled from 'styled-components';

import Spinner from './Spinner';

const Loader = styled.div`
  width: ${(p) => (p.width ? p.width : '25%')};
  height: ${(p) => (p.height ? p.height : '25%')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default (props) => (
  <Loader width={props.width} height={props.height}>
    <Spinner color={props.color} />
  </Loader>
);
