import styled, { css } from 'styled-components';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100% - 30px);
  max-height: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 15px 25px 15px;
  text-align: center;
  color: ${colors.primary_blue};
  font-family: ${baseFont};
  font-size: 18px;
  overflow: auto;
  ${(p) =>
    p.landing
      ? `
    margin-top: 150px;
    `
      : ``};
  ${(p) =>
    p.landing &&
    mobile(css`
      margin-top: 100px;
    `)};
`;
