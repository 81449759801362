import React from 'react';

export default (props) => (
  <g
    id="UI-Style-Guide"
    transform="translate(-682.000000, -2269.000000)"
    fill={props.fill}
    fillRule="nonzero"
  >
    <path
      d="M685.9,2277.14286 C687.334062,2277.14286 688.5,2275.92567 688.5,2274.42857 C688.5,2272.93147 687.334062,2271.71429 685.9,2271.71429 C684.465937,2271.71429 683.3,2272.93147 683.3,2274.42857 C683.3,2275.92567 684.465937,2277.14286 685.9,2277.14286 Z M704.1,2277.14286 C705.534063,2277.14286 706.7,2275.92567 706.7,2274.42857 C706.7,2272.93147 705.534063,2271.71429 704.1,2271.71429 C702.665938,2271.71429 701.5,2272.93147 701.5,2274.42857 C701.5,2275.92567 702.665938,2277.14286 704.1,2277.14286 Z M705.4,2278.5 L702.8,2278.5 C702.085,2278.5 701.439062,2278.80112 700.967813,2279.28884 C702.605,2280.22612 703.766875,2281.9183 704.01875,2283.92857 L706.7,2283.92857 C707.419062,2283.92857 708,2283.3221 708,2282.57143 L708,2281.21429 C708,2279.71719 706.834062,2278.5 705.4,2278.5 Z M695,2278.5 C697.514688,2278.5 699.55,2276.37522 699.55,2273.75 C699.55,2271.12478 697.514688,2269 695,2269 C692.485312,2269 690.45,2271.12478 690.45,2273.75 C690.45,2276.37522 692.485312,2278.5 695,2278.5 Z M698.12,2279.85714 L697.782812,2279.85714 C696.937812,2280.28125 695.999375,2280.53571 695,2280.53571 C694.000625,2280.53571 693.06625,2280.28125 692.217188,2279.85714 L691.88,2279.85714 C689.29625,2279.85714 687.2,2282.04554 687.2,2284.74286 L687.2,2285.96429 C687.2,2287.08817 688.073437,2288 689.15,2288 L700.85,2288 C701.926563,2288 702.8,2287.08817 702.8,2285.96429 L702.8,2284.74286 C702.8,2282.04554 700.70375,2279.85714 698.12,2279.85714 Z M689.032187,2279.28884 C688.560938,2278.80112 687.915,2278.5 687.2,2278.5 L684.6,2278.5 C683.165938,2278.5 682,2279.71719 682,2281.21429 L682,2282.57143 C682,2283.3221 682.580938,2283.92857 683.3,2283.92857 L685.977188,2283.92857 C686.233125,2281.9183 687.395,2280.22612 689.032187,2279.28884 Z"
      id="manageUsers"
    />
  </g>
);
