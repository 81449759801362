import React from 'react';
import styled from 'styled-components';
import colors from 'utils/colors';

const Spinner = (props) => (
  <StyledSpinner viewBox="0 0 50 50" color={props.color}>
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
);

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  width: 100%;
  height: 100%;

  & .path {
    stroke: ${(p) => (p.color ? colors[p.color] : 'white')};
    stroke-linecap: round;
    animation: dash 1s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default Spinner;
