import React, { Component } from 'react';
import styled from 'styled-components';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import Nav from './Nav';
import Jumbotron from './Jumbotron';
import Register from '../Auth/Register';
import UserInvitation from '../Auth/UserInvitation';
import Forgot from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import TokenExpired from '../Auth/ResetPasswordTokenExpired';
import Login from '../Auth/Login';
import AdminLogin from '../Auth/AdminLogin';
import Unsubscribe from '../Auth/Unsubscribe';
import Verify from '../Auth/Verify';
import SetPin from '../Auth/SetPin';

import Notifications from 'common/notification';
import PageShell from 'common/PageShell';
import Footer from 'common/Footer';
import BackgroundImg from 'common/HeroSection.png';
import SvgIcon from 'common/icons/SvgIcon';

import PackageHold from 'common/PackageHoldLogoWhite.png';

const Wrapper = styled.div`
  display: flex;
  min-height: 125vh;
  height: auto;
  width: 100vw;
  background-image: url(${BackgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  background: transparent;
`;

const Logo = styled.div`
  height: 81px;
  width: 450px;
  background-image: url(${PackageHold});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 25px 0px;
`;

class Landing extends Component {
  state = {
    isMobileNav: false,
  };

  onMobileNavToggle = () => {
    this.setState({
      isMobileNav: !this.state.isMobileNav,
    });
  };

  render() {
    const { isMobileNav } = this.state;
    return (
      <Wrapper>
        <Notifications page="landing" />
        <Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {this.props.history.location.pathname.includes(
              '/dashboard/register'
            ) ? (
              <SvgIcon
                className="landingIcons"
                icon="register"
                width="537px"
                height="69px"
                viewBox="0 0 537 69"
              />
            ) : this.props.history.location.pathname.includes(
                '/dashboard/verify'
              ) ? (
              <SvgIcon
                className="landingIcons"
                icon="verify"
                width="537px"
                height="69px"
                viewBox="0 0 537 69"
              />
            ) : this.props.history.location.pathname.includes(
                '/dashboard/setpin'
              ) ? (
              <SvgIcon
                className="landingIcons"
                icon="set-pin"
                width="537px"
                height="69px"
                viewBox="0 0 537 69"
              />
            ) : this.props.history.location.pathname.includes(
                '/dashboard/login'
              ) ||
              this.props.history.location.pathname.includes(
                '/dashboard/forgot'
              ) ||
              this.props.history.location.pathname.includes(
                '/dashboard/unsubscribe'
              ) ||
              this.props.history.location.pathname.includes(
                '/dashboard/reset'
              ) ? (
              <Logo />
            ) : null}
            <Switch location={this.props.location}>
              <Route
                exact
                path="/dashboard/login"
                component={PageShell(Login)}
              />
              <Route
                exact
                path="/dashboard/register/:token"
                component={PageShell(Register)}
              />
              <Route
                exact
                path="/dashboard/userinvitation/:token"
                component={PageShell(UserInvitation)}
              />
              <Route
                exact
                path="/dashboard/verify/:token"
                component={PageShell(Verify)}
              />
              <Route
                exact
                path="/dashboard/setpin/:token"
                component={PageShell(SetPin)}
              />
              <Route
                exact
                path="/dashboard/login/admin"
                component={PageShell(AdminLogin)}
              />
              <Route
                exact
                path="/dashboard/forgot"
                component={PageShell(Forgot)}
              />
              <Route
                exact
                path="/dashboard/reset/:token"
                component={PageShell(ResetPassword)}
              />
              <Route
                exact
                path="/dashboard/tokenExpired"
                component={PageShell(TokenExpired)}
              />
              <Route
                exact
                path="/dashboard/unsubscribe/:user_id"
                component={PageShell(Unsubscribe)}
              />
              <Route path="/dashboard" component={PageShell(Jumbotron)} />
              <Redirect to="/dashboard/" />
            </Switch>
            <Footer />
          </div>
        </Content>
      </Wrapper>
    );
  }
}

export default withRouter(Landing);
