import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changeSettings,
  noValuesChangedAlert,
  fetchUser,
} from 'redux/modules/duck-auth';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import { Link, Prompt } from 'react-router-dom';

import { Header } from 'common/Header';
import { settingsFields } from 'common/forms/formFields';
import Box from 'common/forms/FormBox';
import renderField from 'common/forms/renderField';
import renderCheckbox from 'common/forms/renderCheckbox';
import LongButton from 'common/buttons/longButton.js';
import Spinner from 'common/Spinner';

import colors from 'utils/colors';
import { userPasswordValidation as validate } from 'utils/validations';
import { mobile, phone } from 'utils/media';
import { baseFont } from 'utils/fonts';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NotificationsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0px 25px;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 25px;
  ${mobile(css`
    flex-direction: column;
    width: 100%;
    div {
      width: 100%;
    }
  `)};
`;

const CheckboxTitle = styled.div`
  text-align: left;
  color: ${colors.base_text3};
`;

const ButtonWrapper = styled.div`
  width: 275px;
  display: flex;
  justify-content: center;
  ${phone(
    css`
      justify-content: space-around;
      width: 100%;
    `
  )};
`;

const SelectButton = styled(LongButton)`
  width: 125px;
`;

const Instruction = styled.div`
  width: auto !important;
  max-width: 500px;
  padding: 20px;
  font-weight: 100;
  color: ${colors.base_text3};
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  margin: 25px auto;

  a {
    font-weight: bolder;
    color: ${colors.base_text3};
  }
`;

const ToolButton = styled(LongButton)`
  box-sizing: border-box;
  height: 34px;
  width: 173px;
  border: 1px solid #3e3e58;
  border-radius: 2px;

  font-family: 'Swiss721BTRegular';
  font-size: 14px;
  letter-spacing: 0.54px;
  line-height: 16px;
  text-align: center;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  width: 100%;
  align-items: left;
  ${mobile(css`
    align-items: left;
  `)};
`;

const SubTitle = styled.p`
  color: #083e66;
  font-size: 24px;
  font-weight: bold;
  line-height: 22px;
  text-align: left;
  margin: 50px 0 0 0;
`;

const UpdateButton = styled(LongButton)`
  height: 45px;
  width: 257px;
  color: #ffffff;
  font-family: ${baseFont};
  font-size: 15px;
  letter-spacing: 0.58px;
  line-height: 18px;
  text-align: center;
`;

class SettingsForm extends Component {
  constructor(props) {
    super(props);
    this.renderTitle = this.renderTitle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    const { setting } = this.props.match.params;
    if (
      !(
        setting === 'ada' ||
        setting === 'notifications' ||
        setting === 'password' ||
        setting === 'reset_pin'
      )
    ) {
      // prevent routes that aren't a setting route
      this.props.history.push('/admin/dashboard/settings');
    }
  }

  // componentDidMount() {
  //   if (this.props.user && this.props.user._id) {
  //     this.props.fetchUser(this.props.user._id, this.props.history);
  //   }
  // }

  checkChanges(values) {
    const { user } = this.props;
    let changedValues = {};
    for (const field in values) {
      if (
        values[field] !== undefined &&
        user[field] !== undefined &&
        !_.isEqual(values[field], user[field])
      ) {
        changedValues[field] = values[field];
      }
    }
    return changedValues;
  }

  handleSubmit() {
    if (this.props.settingsForm.userSettingsForm.syncErrors) {
      return;
    }
    if (
      this.props.settingsForm.userSettingsForm.values &&
      (this.props.settingsForm.userSettingsForm.values.password ||
        this.props.settingsForm.userSettingsForm.values.pin_password)
    ) {
      return this.props.changeSettings(
        this.props.settingsForm.userSettingsForm.values,
        this.props.history
      );
    }
    const changedValues = this.checkChanges(
      this.props.settingsForm.userSettingsForm.values
    );
    if (
      Object.keys(changedValues).length > 0 &&
      changedValues.constructor === Object
    ) {
      this.props.changeSettings(changedValues, this.props.history);
    } else {
      this.props.noValuesChangedAlert();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  renderFields() {
    const { user } = this.props;
    const { setting } = this.props.match.params;
    if (user) {
      if (setting === 'notifications') {
        return this.renderNotifications(settingsFields[setting]);
      }
      if (setting === 'ada') {
        return (
          <Field
            component={renderCheckbox}
            type="checkbox"
            label="ADA"
            name="ada"
            settingspage={true}
            defaultValue={this.props.initialValues.ada}
            onChange={this.handleInputChange}
          />
        );
      }
      if (setting === 'password') {
        return (
          <div>
            <Field
              component={renderField}
              label="Current Password"
              name="current_password"
              formtype="userSettings"
              type="password"
            />
            <Field
              component={renderField}
              label="New Password"
              name="password"
              formtype="userSettings"
              type="password"
            />
            <Field
              component={renderField}
              label="Confirm Password"
              name="confirm"
              formtype="userSettings"
              type="password"
            />
          </div>
        );
      }
      if (setting === 'reset_pin') {
        return (
          <div>
            <Field
              component={renderField}
              label="New Pin"
              name="new_pin"
              formtype="userSettings"
              type="password"
              normalize={(value) => {
                if (!value) {
                  return value;
                }
                const onlyNums = value.replace(/[^\d]/g, '');
                if (value && value.length <= 4) {
                  return onlyNums;
                }
              }}
            />
            <Field
              component={renderField}
              label="Confirm Pin"
              name="confirm_pin"
              formtype="userSettings"
              type="password"
              normalize={(value) => {
                if (!value) {
                  return value;
                }
                const onlyNums = value.replace(/[^\d]/g, '');
                if (value && value.length <= 4) {
                  return onlyNums;
                }
              }}
            />
            <Field
              component={renderField}
              label="Enter Password"
              name="pin_password"
              formtype="userSettings"
              type="password"
            />
          </div>
        );
      }
    } else {
      return null;
    }
  }

  renderNotifications(notification_options) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        key="test"
      >
        {_.map(notification_options, ({ label, name, type }, index) => {
          if (
            name === 'notifications.secondary_email_notification' &&
            !this.props.initialValues.secondary_email
          ) {
            // Avoid showing notification setting if secondary email is not set up
            return;
          } else if (name === 'notifications.secondary_email_notification') {
            return (
              <Field
                key={name}
                component={renderCheckbox}
                type={type}
                label={label}
                name={name}
                checked={
                  this.props.initialValues.notifications
                    .secondary_email_notification
                }
              />
            );
          }
          const sms = `notifications.sms_${name}`;
          const email = `notifications.email_${name}`;
          const defaultCheckedSMS = this.props.initialValues.notifications[
            `sms_${name}`
          ];
          const defaultCheckedEmail = this.props.initialValues.notifications[
            `email_${name}`
          ];

          return (
            <NotificationsRow key={index}>
              <CheckboxTitle>{label}:</CheckboxTitle>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Field
                  component={renderCheckbox}
                  type={type}
                  label={'SMS'}
                  name={sms}
                  settingspage={true}
                  defaultValue={defaultCheckedSMS}
                />
                <Field
                  component={renderCheckbox}
                  type={type}
                  label={'EMAIL'}
                  name={email}
                  settingspage={true}
                  defaultValue={defaultCheckedEmail}
                />
              </div>
            </NotificationsRow>
          );
        })}
      </div>
    );
  }

  selectAll() {
    const notifications = {};
    notifications.sms_dropoff = true;
    notifications.sms_expiring = true;
    notifications.sms_pickup_reminder = true;
    notifications.sms_picked_up = true;
    notifications.email_dropoff = true;
    notifications.email_expiring = true;
    notifications.email_pickup_reminder = true;
    notifications.email_picked_up = true;
    if (
      this.props.user.secondary_email &&
      this.props.user.secondary_email.length > 0
    ) {
      notifications.secondary_email_notification = true;
    } else {
      notifications.secondary_email_notification = false;
    }
    this.props.initialize({ notifications });
  }

  clearAll() {
    const notifications = {};
    notifications.sms_dropoff = false;
    notifications.sms_expiring = false;
    notifications.sms_pickup_reminder = false;
    notifications.sms_picked_up = false;
    notifications.email_dropoff = false;
    notifications.email_expiring = false;
    notifications.email_pickup_reminder = false;
    notifications.email_picked_up = false;
    notifications.secondary_email_notification = false;
    this.props.initialize({ notifications });
  }

  renderInstructions() {
    return (
      <Instruction>
        We are proud to offer services compliant with the Americans with
        Disabilities Act. By checking the box below and clicking UPDATE, you are
        confirming that you have accessibility needs in accordance with the
        Americans with Disabilities Act. This will prioritize ADA accessible
        lockers for your deliveries, and inform you in the case that one is not
        available. For more information:{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ada.gov/"
        >
          https://www.ada.gov/
        </a>
      </Instruction>
    );
  }

  renderTitle() {
    const { setting } = this.props.match.params;
    if (setting === 'ada') {
      return 'ADA';
    }
    if (setting === 'reset_pin') {
      return 'Reset Pin';
    }
    return setting.charAt(0).toUpperCase() + setting.substr(1);
  }

  render() {
    const { loading } = this.props;
    const { setting } = this.props.match.params;
    return (
      <Header>
        <FormWrapper onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <FieldWrapper>
            <SubTitle style={{ margin: 0 }}>{this.renderTitle()}</SubTitle>
            <Box>
              {setting === 'ada' ? this.renderInstructions() : null}

              {this.renderFields()}
            </Box>
          </FieldWrapper>
          {setting === 'notifications' ? (
            <ButtonWrapper>
              <SelectButton
                textColor="white"
                backgroundColor="base_text3"
                type="button"
                onClick={() => {
                  this.selectAll();
                }}
              >
                Select All
              </SelectButton>
            </ButtonWrapper>
          ) : null}

          <ToolButton
            textColor="white"
            backgroundColor="base_text3"
            type="submit"
            style={{ marginTop: '25px', overflow: 'hidden' }}
          >
            {loading ? <Loader /> : 'UPDATE'}
          </ToolButton>
          <Link to="/admin/dashboard/settings">
            <ToolButton
              textColor="accent1"
              backgroundColor="white"
              style={{ marginTop: '25px' }}
              type="button"
            >
              BACK
            </ToolButton>
          </Link>
        </FormWrapper>
      </Header>
    );
  }
}

function mapStateToProps({ auth, form }) {
  let initialValues = null;
  const { user, loading, error } = auth;
  if (user) {
    initialValues = {
      ada: user.ada,
      notifications: user.notifications,
    };
  }
  return { loading, err: error, settingsForm: form, initialValues, user };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSettings,
      noValuesChangedAlert,
      fetchUser,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: 'userSettingsForm',
    touchOnBlur: false,
    enableReinitialize: true,
  })(SettingsForm)
);
