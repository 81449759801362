import React, { Component } from 'react';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Spinner from 'common/Spinner';
import { forgotPasswordFields } from 'common/forms/formFields';
import renderField from 'common/forms/renderField';
import LongButton from 'common/buttons/longButton.js';
import ErrorMessage from 'common/Error';

import { baseFont } from 'utils/fonts';
import colors from 'utils/colors';
import { forgotPasswordValidate as validate } from 'utils/validations';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
`;

const Helper = styled(Link)`
  text-decoration: underline;
  color: ${colors.base_text2};

  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.5;
  }
  &:visited {
    color: ${colors.base_text2};
  }
`;

const SendButton = styled(LongButton)`
  margin: 25px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  height: 55px;
  width: 257px;
`;

const Instructions = styled.p`
  height: 42px;
  width: 366px;
  color: #7c7c7c;
  font-family: ${baseFont};
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  opacity: 0.35;
`;

class ForgotPasswordForm extends Component {
  renderFields() {
    return _.map(forgotPasswordFields, ({ label, name, type, placeholder }) => (
      <Field
        component={renderField}
        key={name}
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        formtype="landing"
      />
    ));
  }

  render() {
    const { err, loading } = this.props;
    return (
      <FormWrapper
        onSubmit={this.props.handleSubmit(this.props.onForgotPasswordSubmit)}
        autoComplete="off"
      >
        {this.renderFields()}
        <Instructions>
          Enter your email address and we’ll send you instructions to reset your
          password.
        </Instructions>
        <SendButton
          textColor="white"
          backgroundColor="base_text2"
          type="submit"
          style={{ overflow: 'hidden' }}
        >
          {loading ? <Loader /> : 'SEND'}
        </SendButton>

        <Helper to="/dashboard/login">Back to Login</Helper>
        {err ? <ErrorMessage>{err.message}</ErrorMessage> : null}
      </FormWrapper>
    );
  }
}

export default reduxForm({
  validate,
  form: 'forgotPasswordForm',
  touchOnBlur: false,
})(ForgotPasswordForm);
