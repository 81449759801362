const getTableSize = (totalCounts) => {
  if (totalCounts <= 25) {
    return { pageStart: totalCounts, pageSizeArray: [25] };
  } else if (totalCounts < 100) {
    return { pageStart: 25, pageSizeArray: [25, 50] };
  } else {
    return { pageStart: 25, pageSizeArray: [25, 50, 100] };
  }
};

export default getTableSize;
