import React, { Component } from 'react';
import styled from 'styled-components';
import { VictoryPie, VictoryLabel, VictoryAnimation } from 'victory';

import colors from 'utils/colors';

// const easeOutQuad = (t, b, c, d) => {
//   // t: current time, b: beginning value, c: change in value, d: duration
//   return -c * (t /= d) * (t - 2) + b;
// };

const Label = styled.h1`
  padding: 0;
  margin: 0;
  color: ${colors.base_text3};
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;

class ProgressBar extends Component {
  constructor() {
    super();
  }

  convertDataToArray(percent) {
    return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
  }

  createAnimationData(data) {
    return {
      percent: 0,
      data,
    };
  }

  render() {
    return (
      <div
        id={this.props.id}
        style={{
          flex: this.props.label ? '1 3' : null,
        }}
      >
        {this.props.label ? (
          <Label data={this.props.data}>{this.props.label}</Label>
        ) : null}
        <svg viewBox="0 0 400 400" width="100%" height="100%">
          <VictoryPie
            standalone={false}
            animate={{ duration: 1000 }}
            width={400}
            height={400}
            data={this.convertDataToArray(
              this.props.data ? this.props.data : 0
            )}
            innerRadius={130}
            labels={() => null}
            labelRadius={40}
            style={{
              data: {
                fill: ({ datum }) => {
                  return datum.x === 1 ? colors.base_text3 : colors.accent4;
                },
              },
              labels: {
                fill: ({ datum }) => {
                  return datum.x === 1 ? colors.base_text3 : colors.accent4;
                },
                fontSize: 30,
              },
            }}
          />
          <VictoryAnimation
            duration={750}
            data={this.createAnimationData(
              this.convertDataToArray(this.props.data ? this.props.data : 0)
            )}
          >
            {() => {
              return (
                <g>
                  <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    x={200}
                    y={!this.props.size ? 175 : 200}
                    text={[`${Math.round(this.props.data)}%`]}
                    style={{
                      fontFamily: 'Swiss721BTRegular',
                      fontSize: !this.props.size ? 32 : 50,
                      lineHeight: 22,
                      fill:
                        Math.round(this.props.data) >= 70
                          ? colors.action3
                          : colors.base_text3,
                      fontWeight: 'bold',
                    }}
                  />
                  {!this.props.size ? (
                    <VictoryLabel
                      textAnchor="middle"
                      verticalAnchor="middle"
                      x={200}
                      y={210}
                      text={[`locker capacity`]}
                      style={{
                        fontSize: 20,
                        fill: colors.base_text3,
                        fontFamily: 'Swiss721BTRegular',
                      }}
                    />
                  ) : null}
                </g>
              );
            }}
          </VictoryAnimation>
        </svg>
      </div>
    );
  }
}

export default ProgressBar;
