import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pluralize from 'react-pluralize';

import LoaderPage from 'common/LoaderPage';
import 'common/icons/icons.css';
import colors from 'utils/colors';
import { baseFont, lightFont } from 'utils/fonts';
import {
  belowthree,
  belowfour,
  mobile,
  large,
  xlarge,
  largest,
} from 'utils/media';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 400px;
  max-width: calc(50% - 20px);
  box-sizing: border-box;
  margin: 0px 10px 25px 10px;
  height: auto;
  border-radius: 3px;
  background: white;
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    flex 500ms ease-in-out;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.22);
  &:hover {
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.25), 0 8px 8px rgba(0, 0, 0, 0.22);
  }
  ${mobile(
    css`
      max-width: none;
      flex: 1 0 300px;
    `
  )};
  ${large(css`
    max-width: none;
  `)};
  ${belowfour(
    css`
      flex: 1 0 200px;
    `
  )};
`;

const Header = styled.div`
  box-sizing: border-box;
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100px;
  width: 100%;
  padding: 10px;
  background: ${(p) => colors[p.color]};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: white;
  font-family: ${lightFont};
`;

const TopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile(css`
    div {
      font-size: 14px;
    }
  `)};
  ${belowthree(css`
    display: none;
  `)};
  ${(p) =>
    p.isNavOpen
      ? mobile(css`
          display: none;
        `)
      : mobile(css`
          font-size: 14px;
        `)};
  ${(p) =>
    p.isNavOpen
      ? large(
          css`
            display: none;
          `
        )
      : large(css`
          font-size: 16px;
        `)};
`;

const Center = styled.div`
  margin-bottom: 5px;
  align-self: center;
  font-size: 15px;
  font-family: ${baseFont};
  ${xlarge(css`
    font-size: 18px;
  `)};
  ${largest(
    css`
      font-size: 18px;
    `
  )};
`;

const ContentWrap = styled.div`
  width: 100%;
  height: 250px;
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  flex-wrap: wrap;
  padding: 15px 0px 15px 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
`;

const Icon = styled.i.attrs({
  className: (p) => p.icon,
})`
  cursor: pointer;
  color: ${(p) => colors[p.color]};
  transition: all 300ms ease-in-out;
  font-size: ${(p) => p.fontSize || '50px'};
  h1:first-child {
    margin: 15px auto;
    letter-spacing: 15px;
  }
  ::after {
    opacity: 0;
    font-family: ${baseFont};
    font-style: normal;
    font-weight: bolder;
    display: block;
    text-align: center;
    font-size: 12px;
    content: "${(p) => p.content}";
    transition: transform 300ms ease-in-out, opacity 200ms ease-in-out;
    transform: translateY(-20px);
  }
  &:hover {
    transform: scale(1.15);
    ::after {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  ${belowfour(css`
    &:hover {
      transform: none;
      ::after {
        opacity: 1;
      }
    }
    font-size: ${(p) => p.fontSize || '75px'};
  `)}
  &:active {
    transition: all 10ms ease-in-out;
    color: white;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${(p) => colors[p.color]}
`;

const Instructions = styled.label`
  font-size: 12px;
  font-family: ${baseFont};
  align-text: center !important;
`;

const Requester = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
`;

class ActionCard extends Component {
  renderType() {
    const { type } = this.props;
    if (type === 'expired_parcels') {
      return 'Expired Packages';
    } else if (type === 'request_invite') {
      return 'Requested Invitation';
    } else if (type === 'vacation_request') {
      return 'Requested Vacation';
    } else if (type === 'unregistered_parcel') {
      return 'Package for Unregistered Recipient';
    }
  }

  renderContent() {
    const {
      _id,
      type,
      invite_token,
      manager_pin,
      invite_loading,
      requester,
      created_at,
      expiredParcelsCounter,
    } = this.props;

    if (type === 'expired_parcels') {
      const s = `Please remove this date's expired packages from the system using this Manager PIN.`;
      return (
        <Content>
          <h3 style={{ marginTop: '0', color: colors.warning_red }}>
            <Pluralize
              singular="Package"
              plural="Packages"
              className="pluralize"
              count={expiredParcelsCounter}
            />
          </h3>
          <Icon color="warning_red" content={s}>
            <h1>{manager_pin}</h1>
          </Icon>
        </Content>
      );
    }

    if (type === 'unregistered_parcel') {
      const s = `Please notify this recipient that a package is available for them to pick up with the attached access code.`;
      return (
        <Content>
          <h1 style={{ margin: 0 }}>
            <u>{requester}</u>
          </h1>
          <h3>{moment(created_at).format('MMMM Do YYYY')}</h3>
          <Icon color="primary_blue" content={s}>
            <h1>{manager_pin}</h1>
          </Icon>
        </Content>
      );
    }

    if (type === 'request_invite') {
      return !invite_loading ? (
        <Content style={{ flexDirection: 'row' }}>
          <Icon
            color="secondary_blue"
            icon="icon-cancel"
            content="Deny Request"
            onClick={() => {
              this.props.denyInvite(_id, invite_token, this.props.history);
            }}
          />
          <Icon
            color="secondary_blue"
            icon="icon-ok"
            content="Invite User"
            onClick={() => {
              this.props.acceptInvite(_id, invite_token, this.props.history);
            }}
          />
        </Content>
      ) : (
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
          <LoaderPage width="50%" height="50%" color="accent1" />
        </div>
      );
    }

    if (type === 'vacation_request') {
      return (
        <Content>
          <h1 style={{ margin: 0 }}>
            <u>{requester}</u>
          </h1>
          <h3>{moment(created_at).format('MMMM Do YYYY')}</h3>
          <Link to={`/admin/vacation-management/${this.props._vacation}`}>
            <Icon
              color="primary_blue"
              icon="icon-flight"
              content="View Request"
              fontSize="75px"
            />
          </Link>
        </Content>
      );
    }
  }

  renderLabels() {
    const { type } = this.props;
    if (type === 'expired_parcels') {
      return (
        <Instructions>
          There are currently expired packages occupying lockers in this system.
        </Instructions>
      );
    }
    if (type === 'unregistered_parcel') {
      const s = `The recipient couldn't be found in the directory at the time of delivery.`;
      return <Instructions>{s}</Instructions>;
    }
    if (type === 'request_invite') {
      return (
        <Instructions>
          <Requester>{this.props.requester}</Requester> has requested an
          invitation to create an account associated with your organization.
        </Instructions>
      );
    }
    if (type === 'vacation_request') {
      return (
        <Instructions>
          Someone has requested a vacation hold within your system.
        </Instructions>
      );
    }
  }

  render() {
    const { type } = this.props;
    return (
      <Wrapper>
        <Header
          color={
            type === 'expired_parcels'
              ? 'warning_red'
              : type === 'request_invite'
              ? 'secondary_blue'
              : 'primary_blue'
          }
        >
          <Center>{this.renderType()}</Center>
          <TopHeader>{this.renderLabels()}</TopHeader>
        </Header>
        <ContentWrap>{this.renderContent()}</ContentWrap>
      </Wrapper>
    );
  }
}

export default ActionCard;
