import React from 'react';
import styled from 'styled-components';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const PageShell = (Page, style) => (props) => {
  return (
    <React.Fragment>
      <ReactCSSTransitionGroup
        transitionAppear
        transitionAppearTimeout={600}
        transitionEnterTimeout={600}
        transitionLeaveTimeout={200}
        transitionName="SlideIn"
      >
        <Page {...props} />
      </ReactCSSTransitionGroup>
    </React.Fragment>
  );
};

export default PageShell;
