import React from 'react';

export default (props) =>
  props.nocircle ? (
    <g
      id="UI-Style-Guide"
      transform="translate(-584.000000, -2271.000000)"
      fill={props.fill}
    >
      <path
        d="M602.122881,2276.125 L597.956039,2276.125 L594.12425,2269.2991 C594.020244,2269.11411 593.826875,2268.99999 593.617477,2269 L591.229445,2269 C590.841891,2269 590.562255,2269.3774 590.668714,2269.75666 L592.456274,2276.125 L588.706152,2276.125 L587.131145,2273.9875 C587.02104,2273.83795 586.847863,2273.75 586.664476,2273.75 L585.206501,2273.75 C584.826968,2273.75 584.548425,2274.11293 584.640665,2274.48773 L585.789472,2278.5 L584.640665,2282.51227 C584.548425,2282.88707 584.826968,2283.25 585.206501,2283.25 L586.664476,2283.25 C586.848227,2283.25 587.02104,2283.16205 587.131145,2283.0125 L588.706152,2280.875 L592.456274,2280.875 L590.668714,2287.24297 C590.562255,2287.62223 590.841891,2288 591.229445,2288 L593.617477,2288 C593.826749,2288 594.019979,2287.8857 594.123886,2287.7009 L597.956039,2280.875 L602.122881,2280.875 C603.411689,2280.875 605.622897,2279.81182 605.622897,2278.5 C605.622897,2277.18818 603.411689,2276.125 602.122881,2276.125 Z"
        id="vacation"
        transform="translate(595.122897, 2278.500000) rotate(-38.000000) translate(-595.122897, -2278.500000) "
      />
    </g>
  ) : (
    <g
      id="Style-Guide"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="UI-Style-Guide" transform="translate(-132.000000, -2328.000000)">
        <g
          id="vacation-hold-icon"
          transform="translate(132.000000, 2328.000000)"
        >
          <circle
            id="Oval"
            fill={props.fill ? props.fill : '#5C7FA7'}
            cx="17"
            cy="17"
            r="17"
          />
          <path
            d="M23.0153724,14.7511133 L19.8406538,14.7511133 L16.9212127,9.6416748 C16.8419706,9.50320537 16.6946423,9.41777616 16.5351021,9.41778617 L14.7156597,9.41778617 C14.4203823,9.41778617 14.207327,9.70028584 14.288438,9.9841744 L15.6503809,14.7511133 L12.7931619,14.7511133 L11.5931633,13.1511152 C11.5092745,13.0391709 11.3773302,12.9733376 11.2376081,12.9733376 L10.1267761,12.9733376 C9.8376097,12.9733376 9.62538772,13.245004 9.69566542,13.5255592 L10.5709422,16.5288891 L9.69566542,19.532219 C9.62538772,19.8127742 9.8376097,20.0844406 10.1267761,20.0844406 L11.2376081,20.0844406 C11.377608,20.0844406 11.5092745,20.0186073 11.5931633,19.906663 L12.7931619,18.3066648 L15.6503809,18.3066648 L14.288438,23.073326 C14.207327,23.3572146 14.4203823,23.639992 14.7156597,23.639992 L16.5351021,23.639992 C16.6945463,23.639992 16.8417684,23.5544366 16.920935,23.4161034 L19.8406538,18.3066648 L23.0153724,18.3066648 C23.9973157,18.3066648 25.682036,17.5108324 25.682036,16.5288891 C25.682036,15.5469458 23.9973157,14.7511133 23.0153724,14.7511133 Z"
            id="Airplane"
            fill="#F6F7F9"
            transform="translate(17.682082, 16.528889) rotate(-38.000000) translate(-17.682082, -16.528889) "
          />
        </g>
      </g>
    </g>
  );
