import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { acceptInvite } from 'redux/modules/duck-admin';

import Notifications from 'common/notification';
import LoaderPage from 'common/LoaderPage';

class AcceptInvite extends Component {
  componentWillMount() {
    const { invite_token } = this.props.match.params;
    this.props.acceptInvite(invite_token, this.props.history);
  }

  render() {
    return (
      <div>
        <Notifications page="landing" />
        <LoaderPage color="accent1" />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ acceptInvite }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps
)(AcceptInvite);
