import React from 'react';
import styled, { css } from 'styled-components';
import Tooltip from 'rc-tooltip';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { belowfour } from 'utils/media';

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 416px;
  border: 0.5px solid #e8e8e8;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 35px;
  width: 400px;
  @media (max-width: 580px) {
    width: 300px;
  }
`;

const Header = styled.div`
  box-sizing: border-box;
  padding: 0px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  background: transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #083e66;
  font-family: ${baseFont};
  border-bottom: 0.5px solid #e8e8e8;
`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
  z-index: 999;
  cursor: pointer;
`;

const Tab = styled.div`
  display: inline;
  float: left;
  flex-grow: 1;
  font-family: ${baseFont};
  box-sizing: border-box;
  padding: 5px 5px 5px 5px;
  font-size: 16px;
  z-index: 999;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  ${(p) =>
    p.active && p.warning
      ? `
      background:white;
      color:${colors.warning_red};
      text-decoration: underline;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;`
      : p.active
      ? `background:white;
      color: ${colors.primary_blue};
      text-decoration: underline;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;`
      : `color:'white';`};
`;

const Content = styled.div`
  height: calc(100% - 70px);
  width: 100%;
  flex: 1 0 100%;
  display: flex;
  justify-content: space-around;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  flex-wrap: wrap;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 0px 15px 0px;
  ${(p) =>
    p.title === 'Approved Hold Requests'
      ? `flex-direction: column; padding: 15px 25px;`
      : `padding: 15px 0px 15px 0px;`};
`;

const CardTitle = styled.h1`
  color: #083e66;
  font-family: ${baseFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;
  box-sizing: border-box;
  font-weight: 900;
`;

const HeaderTab = styled.span`
  cursor: pointer;
  + span {
    &:after {
      margin-top: -1.5px;
      z-index: 999;
      display: block;
      content: '';
      border-bottom: 3px solid ${colors.white};
      transition: transform 250ms ease-in-out;
    }
  }
  ${(p) =>
    p.active
      ? `+ span {
        &:after {
          transform: scaleX(1.01);
        }
      }`
      : `+ span {
        &:after {
          transform: scaleX(0);
        }
      }`};
`;

const SelectWrap = styled.div`
  position: relative;

  :after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: 50%;
    right: 6px;
    margin-right: 2px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${(p) =>
        !p.disabled ? colors.base_text3 : `rgba(144,161,181,0.5)`}
      transparent transparent;
  }
`;

const TimeSelect = styled.select`
  box-sizing: border-box;
  text-align: right;
  background: transparent;
  border: none;
  height: 15px;
  width: 150px;
  color: #083e66;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  padding: 0 30px 0 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${(p) =>
    p.disabled
      ? `-webkit-touch-callout: none;
     -webkit-user-select: none;
      -khtml-user-select: none;
        -moz-user-select: none;
         -ms-user-select: none;
             user-select: none;  color: rgba(144,161,181,0.5);`
      : null}
`;

const Option = styled.option``;

export default (props) => {
  return (
    <Wrapper isNavOpen={props.isNavOpen} type={props.type}>
      <Header color={props.color}>
        <CardTitle>{props.title}</CardTitle>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {props.title === 'Capacity' ||
          props.title === 'Approved Hold Requests' ||
          props.title === 'Courier Usage' ||
          props.title === 'Usage' ||
          props.title === 'System Activity' ||
          props.title === 'Average Pickup Time' ||
          props.title === 'Packages Received' ? (
            <SelectWrap
              onChange={(e) => {
                props.changeTab(e.target.value);
              }}
              disabled={props.disabled}
            >
              {props.title === 'Approved Hold Requests' ||
              props.title === 'Courier Usage' ||
              props.title === 'Usage' ||
              props.title === 'System Activity' ||
              props.title === 'Average Pickup Time' ||
              props.title === 'Packages Received' ? (
                <TimeSelect dir="rtl" disabled={props.disabled}>
                  <Option value="thisweek">
                    {props.title === 'Average Pickup Time'
                      ? 'last 7 days'
                      : 'this week'}
                  </Option>
                  <Option value="thismonth">
                    {props.title === 'Average Pickup Time'
                      ? 'last 30 days'
                      : 'this month'}
                  </Option>
                  {props.title === 'Usage ' ||
                  props.title === 'Packages Received' ? (
                    <Option value="yeartodate">year to date</Option>
                  ) : null}
                </TimeSelect>
              ) : (
                <TimeSelect dir="rtl" disabled={props.disabled}>
                  <Option value="Total">total capacity</Option>
                  <Option value="Size">by size</Option>
                </TimeSelect>
              )}
            </SelectWrap>
          ) : (
            <div style={{ width: '125px' }} />
          )}
          {props.dateRange && (
            <p
              style={{
                fontSize: 10,
                color: '#3B3A48',
                fontFamily: "'Swis721 Lt BT Light', sans-serif ",
              }}
            >
              {props.dateRange}
            </p>
          )}
        </div>
      </Header>
      <Content>
        <Center {...props}>{props.children}</Center>
      </Content>
    </Wrapper>
  );
};
