import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';

import renderEmail from './renderEmail';
import SvgIcon from 'common/icons/SvgIcon';

import colors from 'utils/colors';

const Wrapper = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;

const Add = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  cursor: pointer;

	color: ${colors.base_text3};
	font-size: 14px;
	line-height: 16px;
	text-align: center;
}
`;

const Err = styled.span`
  color: ${colors.action2};
  font-size: 14px;
`;

const renderEmails = ({ fields, meta: { error, submitFailed } }) => (
  <Wrapper>
    <div
      style={{
        minHeight: '200px',
        maxHeight: '300px',
        overflow: 'scroll',
        marginBottom: '25px',
      }}
    >
      {fields.map((email, index) => (
        <Row key={index}>
          <Field
            name={`${email}`}
            type="text"
            label="Email"
            component={renderEmail}
            placeholder="email here"
            formtype="emails"
          />
          <div
            onClick={() => (fields.length > 1 ? fields.remove(index) : null)}
          >
            <SvgIcon
              icon="delete"
              viewBox="0 0 20 20"
              style={{ cursor: 'pointer' }}
              width="20px"
              height="20px"
            />
          </div>
        </Row>
      ))}
    </div>
    {submitFailed && error && <Err>{error}</Err>}

    <Add onClick={() => fields.push('')}>
      <SvgIcon
        icon="add"
        fill={colors.action1}
        viewBox="0 0 16 16"
        style={{ marginRight: '10px' }}
        width="12px"
        height="12px"
      />
      Add More
    </Add>
  </Wrapper>
);

export default renderEmails;
