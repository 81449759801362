import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import upload from 'superagent';
import styled, { css } from 'styled-components';

import { success, warning } from 'react-notification-system-redux';
import {
  uploadSuccess,
  uploadFail,
  noFile,
  maxSizeExceeded,
} from 'utils/notiOptions';

import SvgIcon from 'common/icons/SvgIcon';

import LongButton from 'common/buttons/longButton';
import LoaderPage from 'common/LoaderPage';
import { baseFont } from 'utils/fonts';
import colors from 'utils/colors';
import { phone } from 'utils/media';

const ENV = process.env.REACT_APP_ENV || 'development';
let URL = `${process.env.REACT_APP_SERVER}upload/logo`;

if (ENV === 'development') {
  URL = 'http://localhost:5000/upload/logo';
}

const Drop = styled.div`
  display: flex;
  margin: 30px auto;
  text-align: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-family: ${baseFont};
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: ${colors.base_text3};

  box-sizing: border-box;
  height: 218px;
  width: 516px;
  border: 1px solid #90a1b5;
  border-radius: 4px;
  letter-spacing: 2px;
`;

const PreviewWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Preview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  maxheight: 400px;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px auto;
`;

const UploadButton = styled(LongButton)`
  font-size: 15px;
  transition: all 0.3s ease-in-out;

  height: 33px;
  width: 155px;
  border-radius: 2px;
  margin: 10px 0;
`;

class UploadLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      maxSize: 400,
      uploading: false,
    };
    this.upload = this.upload.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }
  onDrop = (files) => {
    if (files.length === 0) {
      this.props.noFile();
      return;
    }
    var image = new Image();
    Object.assign(files[0], {
      preview: window.URL.createObjectURL(files[0]),
    });

    image.src = files[0].preview;
    image.addEventListener('load', () => {
      if (image.height > this.state.maxSize) {
        this.props.maxSizeExceeded();
        return;
      }
      this.setState({
        file: files[0],
      });
    });
  };

  onDropRejected = (files) => {
    this.props.uploadFail();
  };

  async upload() {
    const sysId = this.props.system_id;
    await upload
      .post(URL)
      .on(
        'progress',
        ((e) => {
          if (e.direction === 'upload') {
            this.setState({ uploading: true });
          } // eslint-disable-next-line
        }).bind(this)
      )
      .attach('selectedFile', this.state.file)
      .field('system_id', sysId)
      .end((err, res) => {
        if (err) {
          console.error(err);
        }
        this.props.uploadSuccess();
        window.location.reload(); // Refresh to re-render new logo.
      });
  }

  preventDefault = () => (event) => {
    event.preventDefault();
  };

  render() {
    return !this.state.file ? (
      <div>
        <Dropzone
          accept="image/jpeg, image/png"
          maxSize={2097152}
          onDragOver={this.preventDefault()}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          style={{
            margin: 'auto',
            width: '90%',
            height: '100%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
          multiple={false}
        >
          {(dropzoneProps) => {
            return (
              <Drop {...dropzoneProps.getRootProps({ refKey: 'innerRef' })}>
                <input {...dropzoneProps.getInputProps()} />
                <SvgIcon
                  icon="upload"
                  fill={colors.base_text3}
                  viewBox="0 0 75 69"
                  width="50px"
                  height="50px"
                />
                <span style={{ width: '250px' }}>
                  Drag or drop your logo image here or just click to{' '}
                  <span style={{ color: colors.action1 }}>browse</span> files.
                </span>
                <span>
                  Max Image Height: <strong>400px</strong>
                </span>
              </Drop>
            );
          }}
        </Dropzone>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <LongButton
            textColor="white"
            backgroundColor="base_text3"
            onClick={this.props.toggleUploadModal}
            style={{ fontSize: '15px' }}
          >
            GO BACK
          </LongButton>
        </div>
      </div>
    ) : (
      <PreviewWrapper>
        <h1 style={{ textAlign: 'center', color: colors.base_text3 }}>
          Preview
        </h1>
        <Preview>
          {!this.state.uploading ? (
            <div style={{ maxWidth: '400px' }}>
              <img
                style={{ maxWidth: '100%', maxHeight: '200px' }}
                src={this.state.file.preview}
                alt="preview"
              />
            </div>
          ) : (
            <LoaderPage color="accent1" />
          )}
        </Preview>
        <ButtonWrap>
          <UploadButton
            textColor="white"
            backgroundColor="base_text3"
            onClick={() => this.upload()}
          >
            Upload
          </UploadButton>
          <UploadButton
            textColor="white"
            backgroundColor="action2"
            onClick={() => {
              this.setState({ file: null });
            }}
          >
            Cancel
          </UploadButton>
        </ButtonWrap>
      </PreviewWrapper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uploadSuccess: () => dispatch(success(uploadSuccess)),
    uploadFail: () => dispatch(warning(uploadFail)),
    noFile: () => dispatch(warning(noFile)),
    maxSizeExceeded: () => dispatch(warning(maxSizeExceeded)),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(UploadLogo);
