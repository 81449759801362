import { createStore, applyMiddleware, combineReducers } from 'redux';
// import { createLogger } from 'redux-logger';
import reduxThunk from 'redux-thunk';
import reduxUnhandledAction from 'redux-unhandled-action';
import { reducer as notifications } from 'react-notification-system-redux';

import auth from './modules/duck-auth';
import parcels from './modules/duck-parcels';
import admin from './modules/duck-admin';
import actions from './modules/duck-actions';

import { LOGOUT } from './modules/duck-auth';

import { reducer as reduxForm } from 'redux-form';
const ENV = process.env.NODE_ENV || 'development';

// const loggerMiddleware = createLogger(); // initialize logger
const callback = (action) =>
  console.error(`${action} didn't lead to creation of a new state object`); // log an error to the console if the state returned from a dispatch is equal to the state before the dispatch.

const middleware =
  ENV === 'development'
    ? [
        require('redux-immutable-state-invariant').default(),
        reduxUnhandledAction(callback),
        reduxThunk,
        // loggerMiddleware
      ]
    : [reduxThunk];

// app reducer
const reducer = combineReducers({
  notifications,
  auth,
  parcels,
  admin,
  actions,
  form: reduxForm,
});

const rootReducer = (state, action) => {
  // logout resets entire redux state for app
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return reducer(state, action);
};

const configureStore = () =>
  createStore(rootReducer, {}, applyMiddleware(...middleware));

export default configureStore;
