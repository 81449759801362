import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form';
import _ from 'lodash';
import Spinner from 'common/Spinner';
import ErrorMessage from 'common/Error';
import Papa from 'papaparse';
import PropTypes from 'prop-types';

import renderEmails from 'common/forms/renderEmails';
import LongButton from 'common/buttons/longButton.js';
import InviteButton from 'common/buttons/InviteButton.js';

import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';
import colors from 'utils/colors';
import { validateEmails as validate, validateEmail } from 'utils/validations';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  box-sizing: border-box;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectWrap = styled.div`
  width: 500px;
  margin-bottom: 25px;
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.primary_blue} transparent transparent;
  }
  ${mobile(
    css`
      width: 100%;
    `
  )};
`;

const Select = styled.select`
  box-sizing: border-box;
  width: 100%;
  height: 25px;
  font-family: ${baseFont};
  font-size: 16px;
  font-weight: bold;
  color: ${colors.primary_blue};
  background: transparent;
  border-radius: 3px;
  border-color: ${colors.primary_blue};
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const Textarea = styled.textarea`
  display: block;
  width: 500px;
  height: 200px;
  font-size: 18px;
  font-family: ${baseFont};
  box-sizing: border-box;
  padding: 5px;
  border: 2px solid rgba(51, 51, 51, 0.3);
  margin-bottom: 25px;
  resize: none;
  transition: border-color 150ms ease-in-out;
  &::placeholder {
    color: ${colors.primary_gray};
    opacity: 0.35;
    transition: opacity 150ms ease-in-out;
  }
  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0.1;
    }
    border-color: ${colors.primary_blue};
  }
  ${mobile(css`
    width: 100%;
  `)};
`;

// TODO: Remove outline none for accessibility
const UploadButton = styled.button`
  width: 100px;
  margin-top: -40px;
  margin-bottom: 50px;
  outline: none;
  border: none;
  margin-left: auto;
`;

const CSVFile = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  outline: none;
  margin-top: -100px;
`;

const FileName = styled.p`
  cursor: pointer;

  position: absolute;
  margin: 0;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 14px;
  line-height: 16px;

  text-decoration: underline;
`;

const Span = styled.span`
  width: 100%;
  text-align: left;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
`;

class InvitationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: 'Upload CSV',
      genLinkClicked: false,
    };
    this.upload = this.upload.bind(this);
  }

  componentDidMount() {
    this.props.initialize({ emails: ['', '', ''] });
  }

  upload(evt) {
    const file = evt.target.files[0];
    const reader = new FileReader();

    if (!file) {
      this.setState({
        fileName: 'Upload CSV',
      });
      return;
    }
    reader.readAsText(file);
    reader.onload = (event) => {
      const csvData = event.target.result;
      const data = Papa.parse(csvData);

      if (data) {
        // Flatten multi-dimensional arrays and convert to a string.
        const dataString = []
          .concat(...data.data)
          .filter((str) => !validateEmail(str));
        this.setState({ fileName: file.name });
        this.props.change('emails_csv', dataString);
      }
    };

    reader.onerror = () => {
      alert(`Unable to read ${file.fileName}`);
    };
  }

  render() {
    const { err, loading, systems, link, linkLoading, linkError } = this.props;
    const { genLinkClicked } = this.state;

    return (
      <FormWrapper>
        <Span>Email Addresses</Span>
        <FieldArray name="emails" component={renderEmails} />
        <UploadButton type="button">
          <div style={{ cursor: 'pointer' }}>
            <FileName>{this.state.fileName}</FileName>
            <CSVFile
              type="file"
              name="File Upload"
              id="csvFile"
              onChange={this.upload}
              onClick={(event) => {
                event.target.value = null;
              }}
              accept=".csv"
            />
          </div>
        </UploadButton>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <LongButton
            onClick={this.props.handleSubmit((values) => {
              return new Promise((resolve, reject) => {
                const errors = validate(values, this.props);
                // check if error object is empty via Lodash
                if (!_.isEmpty(errors)) {
                  reject(new SubmissionError(errors));
                } else {
                  this.props.onInvitationSubmit(this.props.reset);
                  this.setState({ fileName: 'Upload CSV' });
                }
              });
            })}
            textColor="white"
            backgroundColor="accent1"
            type="submit"
            fontSize="14px"
            style={{
              width: '196px',
              height: '44px',
              border: '1px solid #15234A',
              overflow: 'hidden',
            }}
          >
            {loading ? <Loader /> : 'SEND INVITATION'}
          </LongButton>
          <span
            style={{
              color: colors.base_text3,
              fontSize: '14px',
              margin: 'auto',
            }}
          >
            OR
          </span>

          <InviteButton
            onClick={(e) => {
              e.preventDefault();
              this.setState({ genLinkClicked: true });
              this.props.onGenerateLink(this.props.reset);
            }}
            clicked={genLinkClicked}
            loading={linkLoading}
            renderSpinner={() => <Loader />}
            error={linkError}
            link={link}
          />
        </div>

        {err ? <ErrorMessage>{err.message}</ErrorMessage> : null}
      </FormWrapper>
    );
  }
}

InvitationForm.contextTypes = {
  store: PropTypes.object,
};

export default reduxForm({
  form: 'invitationForm',
  touchOnBlur: false,
})(InvitationForm);
