import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { mobile } from 'utils/media';

import { reduxForm, Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  registerUserInvitation,
  userInviteCreateUser,
  clearMessages,
} from 'redux/modules/duck-auth';
import LongButton from 'common/buttons/longButton.js';
import { userInvitationValidate as validate } from 'utils/validations';

import { Header } from 'common/Header';
import LoaderPage from 'common/LoaderPage';
import colors from 'utils/colors';
import { baseFont, boldFont } from 'utils/fonts';
import renderField from 'common/forms/renderField';
import SvgIcon from '../common/icons/SvgIcon';

const NewHeader = styled(Header)`
  min-height: 600px;
  height: auto;
  width: 751px;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-top: 50px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${mobile(css`
    flex-direction: column;
  `)};
`;

const SendButton = styled(LongButton)`
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  height: 55px;
  width: 225px;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  line-height: 22px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 50px;
`;

const Instructions = styled.p`
  width: 300px;
  color: #083e66;
  font-family: 'Swiss721BTRegular';
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

class UserInvitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
    };
  }

  componentWillMount() {
    const { history } = this.props;
    const { token } = this.props.match.params;
    this.props.registerUserInvitation(token, history);
  }

  componentWillUnmount() {
    if (this.props.auth.error) {
      this.props.clearMessages();
    }
  }

  render() {
    const { auth, form, history } = this.props;

    return (
      <NewHeader landing={true}>
        {auth.user_invitation ? (
          // when user is created
          auth.invite_user_create ? (
            <Fragment>
              {/* TODO: Account for error with userCreate */}
              {/* TODO: Correct checkmark */}
              <SvgIcon
                icon="checkmark"
                height="61px"
                width="61px"
                fill="#A6D7C1"
                viewBox="0 0 22 22"
              />{' '}
              {/* TODO: change title to be smaller */}
              <Title>Email Verified</Title>
              <Instructions>
                We’ve notified your administrator to approve your account. Once
                approved, you will receive a link to complete your registration.
              </Instructions>
              {/* TODO: Redirect to login */}
            </Fragment>
          ) : (
            <FormWrapper
              onSubmit={(e) => {
                e.preventDefault();
                return this.props.userInviteCreateUser(
                  form.userInvitation.values,
                  this.props.match.params.token
                );
              }}
              autoComplete="off"
            >
              <Title>WELCOME</Title>
              <Row>
                <Field
                  component={renderField}
                  type="text"
                  label="First Name"
                  name="firstName"
                  placeholder="Type First Name"
                  formtype="register"
                  row="true"
                />
                <Field
                  component={renderField}
                  type="text"
                  label="Last Name"
                  name="lastName"
                  placeholder="Type Last Name"
                  formtype="register"
                  row="true"
                />
              </Row>
              <Field
                component={renderField}
                type="email"
                label="Email"
                name="email"
                placeholder="Type Email"
                formtype="register"
                row="true"
              />
              <Instructions>
                You've been invited by to join Digilock. Let's start by
                verifying your email.
              </Instructions>
              <SendButton
                textColor="white"
                backgroundColor="base_text2"
                type="submit"
              >
                SUBMIT
              </SendButton>
            </FormWrapper>
          )
        ) : (
          <Fragment>
            {auth.user_invitation_loading && <LoaderPage color="accent1" />}
            {auth.user_invitation_error && <div>Wrong link buddy</div>}
          </Fragment>
        )}
      </NewHeader>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerUserInvitation,
      userInviteCreateUser,
      clearMessages,
    },
    dispatch
  );
}

function mapStateToProps({ auth, form }) {
  return { auth, form };
}

UserInvitation = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInvitation);

export default reduxForm({
  validate,
  form: 'userInvitation',
  touchOnBlur: false,
})(UserInvitation);
