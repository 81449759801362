import { axiosInstance as axios } from 'redux/client';
import FormData from 'form-data';
import {
  inviteSuccess as invSuccess,
  loginError,
  failedEmails,
  failedSecondaryEmails,
} from 'utils/notiOptions';
import { success, warning } from 'react-notification-system-redux';
// import moment from 'moment-timezone';
import { FINISHED_ACTION } from './duck-admin';

// Action Constants
const FETCH_ACTIONS_REQUEST = 'casegoods-PL-web/actions/FETCH_ACTIONS_REQUEST';
const FETCH_ACTIONS_SUCCESS = 'casegoods-PL-web/actions/FETCH_ACTIONS_SUCCESS';
const FETCH_ACTIONS_FAIL = 'casegoods-PL-web/actions/FETCH_ACTIONS_FAIL';
const INVITE_REQUEST = 'casegoods-PL-web/actions/INVITE_REQUEST';
const INVITE_SUCCESS = 'casegoods-PL-web/actions/INVITE_SUCCESS';
const INVITE_FAIL = 'casegoods-PL-web/actions/INVITE_FAIL';

const initialState = {
  actions: null,
  actions_loading: false, // Loading boolean for pages / spinners
  invite_loading: false,
  error: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ACTIONS_REQUEST:
      return {
        ...state,
        actions_loading: true,
      };
    case FETCH_ACTIONS_SUCCESS:
      return {
        ...state,
        actions_loading: false,
        actions: action.data,
      };
    case FETCH_ACTIONS_FAIL:
      return {
        ...state,
        actions_loading: false,
        actions: null,
        error: action.error,
      };
    case INVITE_REQUEST:
      return {
        ...state,
        invite_loading: true,
      };
    case INVITE_SUCCESS:
      const new_actions = state.actions.filter(
        (obj) => obj._id !== action.action_id
      );
      return {
        ...state,
        actions: new_actions,
        invite_loading: false,
      };
    case INVITE_FAIL:
      return {
        ...state,
        invite_loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}

// Actions
export function fetchActionsReq() {
  return {
    type: FETCH_ACTIONS_REQUEST,
  };
}

export function fetchActionsSuccess(data) {
  return {
    type: FETCH_ACTIONS_SUCCESS,
    data,
  };
}

export function fetchActionsFail(error) {
  return {
    type: FETCH_ACTIONS_FAIL,
    error,
  };
}

export function inviteReq() {
  return {
    type: INVITE_REQUEST,
  };
}

export function inviteSuccess(action_id, system_id) {
  return {
    type: INVITE_SUCCESS,
    action_id,
    system_id,
  };
}

export function inviteFail(error) {
  return {
    type: INVITE_FAIL,
    error,
  };
}

// Action Creators
export const logOutUser = (history) => (dispatch) => {
  localStorage.removeItem('user_token');
  if (history) {
    history.push('/');
  }
};

export const fetchActions = (sys_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchActionsFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchActionsReq());
  axios
    .get(`/admin/fetchActions/${sys_id}`)
    .then(({ data }) => {
      dispatch(fetchActionsSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(fetchActionsFail(err.response.data));
    });
};

export const acceptInvite = (action_id, invite_token, history) => (
  dispatch
) => {
  dispatch(inviteReq());
  let invite = new FormData();
  invite.append('invite_token', invite_token);
  axios
    .post('/admin/accept_invite', invite)
    .then(({ data }) => {
      dispatch(success(invSuccess));
      if (data.failedEmails && data.failedEmails.length > 0) {
        dispatch(warning(failedEmails(data.failedEmails)));
      }
      if (data.failedSecondaryEmails && data.failedSecondaryEmails.length > 0) {
        dispatch(warning(failedSecondaryEmails(data.failedSecondaryEmails)));
      }
      dispatch({ type: FINISHED_ACTION });
      dispatch(inviteSuccess(action_id, data.sys_id));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(inviteFail(err.response.data));
    });
};

export const denyInvite = (action_id, invite_token, history) => (dispatch) => {
  dispatch(inviteReq());
  let invite = new FormData();
  invite.append('invite_token', invite_token);
  axios
    .post('/admin/deny_invite', invite)
    .then(({ data }) => {
      if (data.success === true) {
        dispatch({ type: FINISHED_ACTION });
        dispatch(inviteSuccess(action_id, data.sys_id));
        dispatch(warning(loginError('Requested Invitation Denied')));
      } else {
        dispatch(inviteFail());
        dispatch(warning(loginError(data.msg)));
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(inviteFail(err.response.data));
    });
};
