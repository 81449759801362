import React, { Component } from 'react';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Spinner from 'common/Spinner';
import { loginFields } from 'common/forms/formFields';
import renderField from 'common/forms/renderField';
import LongButton from 'common/buttons/longButton.js';
import ErrorMessage from 'common/Error';

import colors from 'utils/colors';
import { loginValidate as validate } from 'utils/validations';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
`;

const Helper = styled(Link)`
  text-decoration: underline;
  color: ${colors.base_text2};
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.5;
  }
  &:visited {
    color: ${colors.base_text2};
  }
`;

const LoginButton = styled(LongButton)`
  margin: 25px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  height: 55px;
  width: 257px;
`;

class LoginForm extends Component {
  renderFields() {
    return _.map(loginFields, ({ label, name, type, placeholder }) => (
      <Field
        component={renderField}
        key={name}
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        formtype="landing"
      />
    ));
  }

  render() {
    const { err, loading } = this.props;
    return (
      <FormWrapper
        onSubmit={this.props.handleSubmit(this.props.onLoginSubmit)}
        autoComplete="off"
      >
        <input type="hidden" value="prayer" />
        {this.renderFields()}
        {err ? (
          <ErrorMessage style={{ margin: '0 auto 25px auto' }}>
            {err.message}
          </ErrorMessage>
        ) : null}
        <LoginButton
          textColor="white"
          backgroundColor="base_text2"
          type="submit"
          style={{ overflow: 'hidden' }}
        >
          {loading ? <Loader color="base_text2" size="3" /> : 'LOGIN'}
        </LoginButton>
        <Helper to="/dashboard/forgot">Forgot Password?</Helper>
      </FormWrapper>
    );
  }
}

export default reduxForm({
  validate,
  form: 'loginForm',
  touchOnBlur: false,
})(LoginForm);
