import React from 'react';

export default (props) => (
  <g
    id="UI-Style-Guide"
    transform="translate(-330.000000, -2271.000000)"
    fill={props.fill}
  >
    <g id="Add-Field" transform="translate(330.000000, 2271.000000)">
      <path
        d="M8.78485527,7.8083592 L13.1272937,3.46594522 C13.3970847,3.19638592 13.3970847,2.75901386 13.1272937,2.48945455 C12.8575027,2.21966505 12.4205886,2.21966505 12.1507975,2.48945455 L7.80835909,6.83186852 L3.46569044,2.48945455 C3.19589941,2.21966505 2.75898528,2.21966505 2.48919426,2.48945455 C2.21940323,2.75901386 2.21940323,3.19638592 2.48919426,3.46594522 L6.83186291,7.8083592 L2.48919426,12.1507731 C2.21940323,12.4203325 2.21940323,12.8577045 2.48919426,13.1272638 C2.62408977,13.2619284 2.80088116,13.3293758 2.97744235,13.3293758 C3.15400354,13.3293758 3.33079492,13.2619284 3.46569044,13.1270336 L7.80835909,8.78461967 L12.1507975,13.1270336 C12.285693,13.2619284 12.4624844,13.3293758 12.6390456,13.3293758 C12.8156068,13.3293758 12.9923982,13.2619284 13.1272937,13.1270336 C13.3970847,12.8574743 13.3970847,12.4201023 13.1272937,12.150543 L8.78485527,7.8083592 Z"
        transform="translate(7.808244, 7.808244) rotate(45.000000) translate(-7.808244, -7.808244) "
      />
    </g>
  </g>
);
