import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import {
  fetchActions,
  acceptInvite,
  denyInvite,
} from 'redux/modules/duck-actions';
import { selectSystem, fetchCounters } from 'redux/modules/duck-admin';

import ActionCard from 'common/ActionCard';
import { Header } from 'common/Header';
import LoaderPage from 'common/LoaderPage';

import { mobile } from 'utils/media';
import colors from 'utils/colors';
import { boldFont } from 'utils/fonts';

const NA = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color: ${colors.primary_blue};
  font-family: ${boldFont};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  ${mobile(css`
    width: 100%;
  `)};
`;

class Actions extends Component {
  componentDidMount() {
    if (localStorage.user_token && this.props.selectedSystem) {
      this.props.fetchCounters(
        this.props.selectedSystem.system._id,
        this.props.history
      );
      this.props.fetchActions(
        this.props.selectedSystem.system._id,
        this.props.history
      );
    }
  }

  renderActions() {
    const {
      isNavOpen,
      actions,
      expired_parcels_counter,
      invite_loading,
      acceptInvite,
      denyInvite,
    } = this.props;
    return _.map(actions, (action, index) => {
      return (
        <ActionCard
          key={action._id}
          {...action}
          isNavOpen={isNavOpen}
          expiredParcelsCounter={expired_parcels_counter}
          acceptInvite={action.type === 'request_invite' ? acceptInvite : null}
          denyInvite={action.type === 'request_invite' ? denyInvite : null}
          invite_loading={invite_loading}
        />
      );
    });
  }

  render() {
    const { actions, actions_loading } = this.props;
    return (
      <Header>
        {actions && actions.length !== 0 && !actions_loading ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <CardWrapper>{this.renderActions()}</CardWrapper>
          </div>
        ) : actions && actions.length === 0 ? (
          <NA>No Actions</NA>
        ) : (
          <div style={{ marginTop: '200px', height: '200px' }}>
            <LoaderPage color="accent1" height="50%" width="50%" />
          </div>
        )}
      </Header>
    );
  }
}

function mapStateToProps({ actions, admin }) {
  const { selectedSystem, expired_parcels_counter } = admin;
  const { actions_loading, invite_loading } = actions;
  return {
    actions: actions.actions,
    actions_loading,
    invite_loading,
    selectedSystem,
    expired_parcels_counter,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCounters,
      fetchActions,
      selectSystem,
      acceptInvite,
      denyInvite,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions);
