import React, { Component } from 'react';
import {
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryLabel,
  VictoryLegend,
} from 'victory';

class PickupLineChart extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        {Array.isArray(this.props.data) &&
        this.props.data.filter((obj) => obj.y > 0).length != 0 ? (
          <div
            style={{
              marginLeft: 25, // applying justifyContent to center does effectively center the graph chart itself but the inclusion of the y-axis label ignores this. Instead a marginLeft is applied
            }}
          >
            <VictoryChart
              containerComponent={
                <VictoryVoronoiContainer // effectively acts as tooltip/ onMouseHover
                  labels={({ datum }) => datum.y + ' Parcels'}
                  voronoiBlacklist={['LineData']} // tooltip will evaluate for each graph within parent VictoryChart i.e. it will evaluate tooltip two times for both VictoryLine and VictoryScatter. The blacklist will prevent the tooltip to show the displayed information twice over as both VictoryLine and VictoryScatter both pull data from this.props.data
                />
              }
            >
              <VictoryAxis // y-axis
                style={{
                  ticks: { stroke: '#000' },
                  grid: { stroke: '#979797', strokeWidth: 0.25 },
                  tickLabels: {
                    padding: 5,
                    fontSize: 11,
                    fontFamily: "'Swis721 Lt BT', sans-serif ",
                    color: '#3E3E58',
                  },
                }}
                dependentAxis
              />
              <VictoryAxis // x-axis
                tickFormat={(tick, index) => (index % 2 !== 0 ? tick : null)}
                style={{
                  ticks: { stroke: '#000' },
                  grid: { stroke: '#979797', strokeWidth: 0.25 },
                  tickLabels: {
                    fontSize: 7,
                    fontFamily: "'Swis721 Lt BT', sans-serif ",
                    color: '#3E3E58',
                    padding: 5,
                    angle: -90,
                    verticalAnchor: 'middle',
                    textAnchor: 'end',
                  },
                }}
                crossAxis
              />
              <VictoryLabel
                x={7}
                y={200}
                angle={270}
                text="Average Quantity"
                style={{
                  fill: '#3B3A48',
                  fontSize: 12,
                  fontFamily: "'Swis721 Lt BT', sans-serif ",
                }}
              />
              <VictoryLine // To emulate the wireframe at the time of this code, there are both a line chart and an overlapping scatter chart. The scatter chart will only be visible when y.values are greater than 0 but are still there.
                name="LineData"
                style={{
                  data: {
                    stroke: '#C15D26',
                    strokeWidth: 1,
                  },
                  parent: { border: '.5px solid #ccc' },
                }}
                data={this.props.data}
              />
              <VictoryScatter
                name="ScatterData"
                data={this.props.data}
                style={{
                  data: {
                    fill: ({ datum }) => {
                      return datum.y > 0 ? '#B64D16' : 'transparent';
                    },
                    stroke: ({ datum }) => {
                      return datum.y > 0 ? '#B64D16' : 'transparent';
                    },
                    strokeWidth: 2,
                  },
                }}
              />
              <VictoryLegend
                x={210}
                y={275}
                style={{
                  data: { fill: '#B64D16', stroke: '#B64D16', strokeWidth: 2 },
                }}
                data={[
                  {
                    name: 'Number of packages picked up',
                    labels: {
                      fontSize: 12,
                      fill: '#3B3A48',
                      fontFamily: "'Swis721 Lt BT', sans-serif ",
                    },
                  },
                ]}
              />
            </VictoryChart>
          </div>
        ) : (
          <p
            style={{
              color: '#083E66',
              fontSize: 12,
              fontFamily: "'Swis721 Lt BT', sans-serif ",
            }}
          >
            No parcels between {this.props.dateRange}
          </p> // The chart has bizarre change in behaviour when it is passed the same data structure but with all y values of 0. Rather than find out how to get the chart to behave similarly to when passed a data structure with at least one y value greater than 0, the chart will altogether be replaced by a string to convey that there are no parcels to be found in the requested time range.
        )}
      </div>
    );
  }
}

export default PickupLineChart;
