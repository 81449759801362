import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUserParcels } from 'redux/modules/duck-parcels';
import { fetchDirectory } from 'redux/modules/duck-auth';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';

import _ from 'lodash';
import ReactModal from 'react-modal';
import moment from 'moment';

import { Header } from 'common/Header';
import Card from 'common/AnalyticsCard';
import LoaderPage from 'common/LoaderPage';
import SvgIcon from 'common/icons/SvgIcon';
import Box from 'common/forms/FormBox';
import renderField from 'common/forms/renderField';
import ParcelUsage from 'common/d3/ParcelUsage';
import colors from 'utils/colors';
import { baseFont, boldFont } from 'utils/fonts';
import { mobile } from 'utils/media';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

// grid has max of 2 columns and min of 1 column and centers all cards
// max width prevents having more than 2 columns
const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  max-width: 1000px;
  width: 100%;
  justify-items: center;
  @media (max-width: 580px) {
    justify-items: flex-start;
  }
`;

const NA = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color: ${colors.primary_blue};
  font-family: ${boldFont};
`;

const BarWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
`;

const ParcelInfo = styled.span`
  margin-top: -30px;
`;

const PieLabelWrapper = styled.div`
  font-size: 12px;
  margin-top: 50%;
`;

const PieLabel = styled.div`
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  margin-bottom: 70px;
  span:before {
    content: '';
    display: inline-flex;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
`;

const ColorBox = styled.span`
  margin-right: 20px;
  display: flex;
  width: 100%;
  :before {
    background: ${(props) => props.color};
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  line-height: 22px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 50px;
  align-self: flex-start;
`;

const PackageCount = styled.span`
  position: absolute;
  margin-top: 150px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
`;

const NAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
`;

const NATitle = styled.h1`
  color: #5c7fa7;
  font-family: ${baseFont};
  font-size: 24px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  margin-top: 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  ${mobile(css`
    flex-direction: column;
    width: 100%;
    div {
      width: 100%;
    }
  `)};
`;

const NASub = styled.span`
  color: #15234a;
  font-family: ${baseFont};
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
`;

const InviteButton = styled(Link)`
  margin-top: 9px;
  padding: 7px 20px;
  width: 200px;
  border-radius: 2px;
  background-color: #3e3e58;
  color: #f6f7f9;
  font-family: 'Swiss721BTRegular';
  font-size: 14px;
  letter-spacing: 0.54px;
  line-height: 16px;
  text-align: center;
`;

const ViewPackages = styled.div`
  color: #5c7fa7;
  font-family: 'Swiss721BTRegular';
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
`;

const Instructions = styled.p`
  width: 300px;
  color: #083e66;
  font-family: 'Swiss721BTRegular';
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

class UserDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      systemUsageTab: 'thisweek',
    };

    this.changeSystemUsageTab = this.changeSystemUsageTab.bind(this);
  }

  componentDidMount() {
    ReactModal.setAppElement('body');
    if (localStorage.user_token) {
      this.props.fetchUserParcels(this.props.history);
      this.props.fetchDirectory(this.props.history);
    }
  }

  changeSystemUsageTab(tab) {
    if (tab !== this.state.systemUsageTab) {
      this.setState({ systemUsageTab: tab });
    }
  }

  renderCards(parcels, directory) {
    let request;
    const { parcel_count, expired_parcels, systemUsage } = parcels;

    if (directory[0]) {
      request = `Max Hold Requests is ${directory[0].vacation_limit} days`;
    }

    return (
      <CardWrapper>
        <Card title="Deliveries">
          {parcel_count == 0 ? (
            <NAWrapper>
              <SvgIcon
                icon="package"
                fill={colors.base_text3}
                width="50px"
                height="50px"
                viewBox="0 0 59 59"
              />
              <NATitle style={{ marginTop: '10px' }}>No Packages Yet</NATitle>
              <Instructions>
                We’ll notify you as soon as a package is delivered.
              </Instructions>
            </NAWrapper>
          ) : (
            <ViewPackages>
              <p>
                You have{' '}
                <span style={{ color: colors.action3 }}>{parcel_count}</span>{' '}
                package(s)!
              </p>{' '}
              <InviteButton to="/dashboard/logs">VIEW PACKAGES</InviteButton>
            </ViewPackages>
          )}
        </Card>
        <Card title="Expired Packages">
          {expired_parcels == 0 ? (
            <NAWrapper>
              <SvgIcon
                icon="expiredpackage"
                fill={colors.base_text3}
                width="50px"
                height="50px"
                viewBox="0 0 150 150"
              />
              <NATitle style={{ marginTop: '10px' }}>
                0 Expired Packages
              </NATitle>
              <Instructions>
                If a package expires, we’ll let you know where to pick it up.
              </Instructions>
            </NAWrapper>
          ) : (
            <ViewPackages>
              <p>
                You have{' '}
                <span style={{ color: colors.action3 }}>{expired_parcels}</span>{' '}
                expired package(s).
              </p>{' '}
              <Instructions>
                Pick up your package or contact management to pick up your
                package.
              </Instructions>
            </ViewPackages>
          )}
        </Card>
        <Card title="Packages Received" changeTab={this.changeSystemUsageTab}>
          <PackageCount>
            <span style={{ color: colors.action3 }}>
              {systemUsage ? systemUsage[this.state.systemUsageTab].count : 0}
            </span>{' '}
            Packages Delivered
          </PackageCount>
          <ParcelUsage
            data={
              systemUsage ? systemUsage[this.state.systemUsageTab].data : []
            }
            tab={this.state.systemUsageTab}
          />
        </Card>
        <Card title="Hold Requests">
          <NAWrapper>
            <SvgIcon
              icon="holdRequestSmall"
              fill={colors.base_text3}
              width="50px"
              height="50px"
              viewBox="0 0 34 34"
            />
            <NATitle style={{ marginTop: '10px' }}>Hold Requests</NATitle>
            <Instructions>
              Expecting a package but won't be here? Submit a hold request.
            </Instructions>
            <h4 style={{ color: colors.action3 }}>{request}</h4>
          </NAWrapper>
        </Card>
      </CardWrapper>
    );
  }

  render() {
    const { parcels, loading } = this.props;
    const { directory } = this.props.auth;
    const { pin, label_url } = this.state;
    return (
      <Header style={{ alignItems: 'flex-start' }}>
        <Title>Dashboard</Title>
        <Wrapper>{this.renderCards(parcels, directory)}</Wrapper>
      </Header>
    );
  }
}

function mapStateToProps(state) {
  const { directory } = state.auth.directory;
  const { parcels, loading } = state.parcels;
  const { auth } = state;

  const { isNavOpen } = state.auth;
  return { parcels, loading, auth, isNavOpen, directory };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchUserParcels, fetchDirectory }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDash);
