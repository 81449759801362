import React from 'react';

export default (props) => (
  <g
    id="UI-Style-Guide"
    transform="translate(-637.000000, -2269.000000)"
    fill={props.fill}
    fillRule="nonzero"
  >
    <path
      d="M645.5,2288 C646.840264,2288 647.927426,2286.93682 647.927426,2285.625 L643.072574,2285.625 C643.072574,2286.93682 644.159736,2288 645.5,2288 Z M653.673256,2282.44436 C652.940133,2281.67396 651.568374,2280.51504 651.568374,2276.71875 C651.568374,2273.83535 649.501059,2271.52715 646.713523,2270.96086 L646.713523,2270.1875 C646.713523,2269.53178 646.170132,2269 645.5,2269 C644.829868,2269 644.286477,2269.53178 644.286477,2270.1875 L644.286477,2270.96086 C641.498941,2271.52715 639.431626,2273.83535 639.431626,2276.71875 C639.431626,2280.51504 638.059867,2281.67396 637.326744,2282.44436 C637.099066,2282.68371 636.998129,2282.96982 637.000026,2283.25 C637.0042,2283.85859 637.492569,2284.4375 638.218103,2284.4375 L652.781897,2284.4375 C653.507431,2284.4375 653.996179,2283.85859 653.999974,2283.25 C654.001871,2282.96982 653.900934,2282.68334 653.673256,2282.44436 Z"
      id="notifications"
    />
  </g>
);
