import { axiosInstance as axios } from 'redux/client';
import { success } from 'react-notification-system-redux';
import { sendSMSSuccess, sendEmailSuccess } from 'utils/notiOptions';

import { logOutUser } from './duck-auth';

// Action Constants
const USER_PARCELS_REQUEST = 'casegoods-PL-web/parcels/USER_PARCELS_REQUEST';
const USER_PARCELS_SUCCESS = 'casegoods-PL-web/parcels/USER_PARCELS_SUCCESS';
const USER_PARCELS_FAIL = 'casegoods-PL-web/parcels/USER_PARCELS_FAIL';
const USER_LOGS_REQUEST = 'casegoods-PL-web/parcels/USER_LOGS_REQUEST';
const USER_LOGS_SUCCESS = 'casegoods-PL-web/parcels/USER_LOGS_SUCCESS';
const USER_LOGS_FAIL = 'casegoods-PL-web/parcels/USER_LOGS_FAIL';
const FETCH_ADMIN_USER_LOG_REQUEST =
  'casegoods-PL-web/parcels/FETCH_ADMIN_USER_LOG_REQUEST';
const FETCH_ADMIN_USER_LOG_SUCCESS =
  'casegoods-PL-web/parcels/FETCH_ADMIN_USER_LOG_SUCCESS';
const FETCH_ADMIN_USER_LOG_FAIL =
  'casegoods-PL-web/parcels/FETCH_ADMIN_USER_LOG_FAIL';
const CHANGE_LOG_SORT_REQUEST =
  'casegoods-PL-web/parcels/CHANGE_LOG_SORT_REQUEST';
const CHANGE_LOG_SORT_SUCCESS =
  'casegoods-PL-web/parcels/CHANGE_LOG_SORT_SUCCESS';
const RESET_LOG_SORT_REQUEST =
  'casegoods-PL-web/parcels/RESET_LOG_SORT_REQUEST';

const initialState = {
  loading: false, // Loading boolean for pages / spinners
  error: null, // error messages
  parcels: [],
  // log sorting
  log_parcels: [],
  log_user_id: null,
  table_loading: false,
  log_loading: false,
  sortBy: 'date',
  ascending: true,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case USER_PARCELS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USER_PARCELS_SUCCESS:
      return {
        ...state,
        loading: false,
        parcels: action.data,
        error: null,
      };
    case USER_PARCELS_FAIL:
      return {
        ...state,
        loading: false,
        parcels: null,
        error: action.error,
      };
    case USER_LOGS_REQUEST:
      return {
        ...state,
        log_loading: true,
        error: null,
      };
    case USER_LOGS_SUCCESS:
      return {
        ...state,
        ascending: true,
        log_loading: false,
        log_parcels: action.data,
        error: null,
      };
    case USER_LOGS_FAIL:
      return {
        ...state,
        table_loading: false,
        log_parcels: [],
        error: action.error,
      };

    case FETCH_ADMIN_USER_LOG_REQUEST:
      return {
        ...state,
        log_loading: true,
        log_parcels: [],
      };
    case FETCH_ADMIN_USER_LOG_SUCCESS:
      return {
        ...state,
        log_loading: false,
        log_parcels: action.data,
        log_user_id: action.data.log_user_id,
      };
    case FETCH_ADMIN_USER_LOG_FAIL:
      return {
        ...state,
        table_loading: false,
        error: action.error,
      };
    case CHANGE_LOG_SORT_REQUEST:
      return {
        ...state,
        table_loading: true,
      };
    case CHANGE_LOG_SORT_SUCCESS:
      if (action.sortBy === state.sortBy) {
        return {
          ...state,
          table_loading: false,
          ascending: !action.ascending,
        };
      }
      return {
        ...state,
        table_loading: false,
        ascending: true,
        sortBy: action.sortBy,
      };
    case RESET_LOG_SORT_REQUEST:
      return {
        ...state,
        table_loading: false,
        ascending: true,
        sortBy: 'date',
      };
    default:
      return state;
  }
}

// Actions
export function userParcelsReq() {
  return {
    type: USER_PARCELS_REQUEST,
  };
}

export function userParcelsSuccess(data) {
  return {
    type: USER_PARCELS_SUCCESS,
    data,
  };
}

export function userParcelsFail(error) {
  return {
    type: USER_PARCELS_FAIL,
    error,
  };
}

export function userLogsReq() {
  return {
    type: USER_LOGS_REQUEST,
  };
}

export function userLogsSuccess(data) {
  return {
    type: USER_LOGS_SUCCESS,
    data,
  };
}

export function userLogsFail(error) {
  return {
    type: USER_LOGS_FAIL,
    error,
  };
}

export function fetchAdminUserLogReq() {
  return {
    type: FETCH_ADMIN_USER_LOG_REQUEST,
  };
}

export function fetchAdminUserLogSuccess(data) {
  return {
    type: FETCH_ADMIN_USER_LOG_SUCCESS,
    data,
  };
}

export function fetchAdminUserLogFail(error) {
  return {
    type: FETCH_ADMIN_USER_LOG_FAIL,
    error,
  };
}

export function changeLogSortReq() {
  return {
    type: CHANGE_LOG_SORT_REQUEST,
  };
}

export function changeLogSortSuccess(sortBy, ascending) {
  return {
    type: CHANGE_LOG_SORT_SUCCESS,
    sortBy,
    ascending,
  };
}

export function resetLogSortReq() {
  return {
    type: RESET_LOG_SORT_REQUEST,
  };
}

// Action Creators
export const fetchUserParcels = (history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(userParcelsFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(userParcelsReq());
  axios
    .get('/parcels/fetchUserParcels')
    .then(({ data }) => {
      dispatch(userParcelsSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(userParcelsFail(err.response.data));
    });
};

export const fetchUserLogs = (when, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(userLogsFail());
    return;
  }

  let amount, time;
  if (when === 'one_month') {
    amount = 1;
    time = 'month';
  }
  if (when === 'three_month') {
    amount = 3;
    time = 'month';
  }
  if (when === 'one_year') {
    amount = 1;
    time = 'year';
  }
  if (when === 'all') {
    amount = 0;
    time = 'all';
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(userLogsReq());
  axios
    .get(`/parcels/fetch-user-logs/${amount}/${time}`)
    .then(({ data }) => {
      dispatch(userLogsSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(userLogsFail(err.response.data));
    });
};

export const fetchAdminUserLog = (user_id, system_id, history) => (
  dispatch
) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchAdminUserLogFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchAdminUserLogReq());
  axios
    .get(`/admin/fetchUserLog/${user_id}/${system_id}`)
    .then(({ data }) => {
      data.log_user_id = user_id;
      dispatch(fetchAdminUserLogSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(fetchAdminUserLogFail(err.response.data));
    });
};

export const changeLogSort = (sortBy, ascending) => (dispatch) => {
  dispatch(changeLogSortReq());
  dispatch(changeLogSortSuccess(sortBy, ascending));
};

export const resetLogSort = () => (dispatch) => {
  dispatch(resetLogSortReq());
};

export const sendSMS = (pin, phone) => (dispatch) => {
  let smsData = new FormData();

  smsData.append('pin', pin);
  smsData.append('phone', phone);

  delete axios.defaults.headers.common['Authorization'];

  axios.post('/parcels/send_sms', smsData);

  dispatch(success(sendSMSSuccess));
};

export const sendEmail = (pin, expiration, user) => (dispatch) => {
  let notificationData = new FormData();

  notificationData.append('pin', pin);
  notificationData.append('expiration', expiration);
  notificationData.append('first_name', user.first_name);
  notificationData.append('email', user.email);
  notificationData.append('user_id', user._id);

  if (user.secondary_email && user.secondary_email.length > 0) {
    notificationData.append('secondary_email', user.secondary_email);
  }

  delete axios.defaults.headers.common['Authorization'];

  axios.post('/parcels/send_email', notificationData);

  dispatch(success(sendEmailSuccess));
};
