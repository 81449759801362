import React from 'react';
import styled, { css } from 'styled-components';
import Tooltip from 'rc-tooltip';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { belowfour } from 'utils/media';

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 550px;
  flex: 0 0 420px;
  border: 0.5px solid #e8e8e8;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 35px;
`;

const Header = styled.div`
  box-sizing: border-box;
  padding: 0px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #15234a;
  color: white;
  font-family: ${baseFont};
  border-bottom: 0.5px solid #e8e8e8;
`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
  z-index: 999;
  cursor: pointer;
`;

const Tab = styled.div`
  display: inline;
  float: left;
  flex-grow: 1;
  font-family: ${baseFont};
  box-sizing: border-box;
  padding: 5px 5px 5px 5px;
  font-size: 16px;
  z-index: 999;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  ${(p) =>
    p.active && p.warning
      ? `
      background:white;
      color:${colors.warning_red};
      text-decoration: underline;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;`
      : p.active
      ? `background:white;
      color: ${colors.primary_blue};
      text-decoration: underline;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;`
      : `color:'white';`};
`;

const Content = styled.div`
  height: calc(100% - 70px);
  width: 100%;
  flex: 1 0 100%;
  display: flex;
  justify-content: space-around;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  flex-wrap: wrap;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 0px 15px 0px;
  p {
    color: #15234a;

    font-size: 18px;
    line-height: 21px;
  }
`;

const CardTitle = styled.span`
  font-family: ${baseFont};

	color: #FFFFFF;
	font-size: 22px;
	line-height: 22px;
	text-align: center;
}
`;

const Title = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;
  box-sizing: border-box;
  font-weight: 900;
`;

const HeaderTab = styled.span`
  cursor: pointer;
  + span {
    &:after {
      margin-top: -1.5px;
      z-index: 999;
      display: block;
      content: '';
      border-bottom: 3px solid ${colors.white};
      transition: transform 250ms ease-in-out;
    }
  }
  ${(p) =>
    p.active
      ? `+ span {
        &:after {
          transform: scaleX(1.01);
        }
      }`
      : `+ span {
        &:after {
          transform: scaleX(0);
        }
      }`};
`;

const SelectWrap = styled.div`
  position: relative;

  :after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: 50%;
    right: 6px;
    margin-right: 2px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${(p) =>
        !p.disabled ? colors.base_text3 : `rgba(144,161,181,0.5)`}
      transparent transparent;
  }
`;

const TimeSelect = styled.select`
  box-sizing: border-box;
  text-align: right;
  background: transparent;
  border: none;
  height: 15px;
  width: 150px;
  color: #083e66;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  padding: 0 30px 0 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${(p) =>
    p.disabled
      ? `-webkit-touch-callout: none;
     -webkit-user-select: none;
      -khtml-user-select: none;
        -moz-user-select: none;
         -ms-user-select: none;
             user-select: none;  color: rgba(144,161,181,0.5);`
      : null}
`;

const Option = styled.option``;

export default (props) => {
  return (
    <Wrapper isNavOpen={props.isNavOpen} type={props.type}>
      <Header color={props.color}>
        <CardTitle>{props.title}</CardTitle>
      </Header>
      <Content>
        <Center {...props}>{props.children}</Center>
      </Content>
    </Wrapper>
  );
};
