import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as unsubActions from 'redux/modules/duck-auth';
import { Link } from 'react-router-dom';

import { Header } from 'common/Header';
import Title from 'common/forms/FormTitle';
import LongButton from 'common/buttons/longButton.js';
import LoaderPage from 'common/LoaderPage';
import Spinner from 'common/Spinner';

import colors from 'utils/colors';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Helper = styled(Link)`
  margin-top: 25px;
  text-decoration: underline;
  color: ${colors.primary_blue};
  &:hover,
  &:focus {
    opacity: 0.5;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.5;
  }
  &:visited {
    color: ${colors.primary_blue};
  }
`;

class Unsubscribe extends Component {
  componentWillMount() {
    // Grab current user settings

    if (!this.props.user) {
      this.props.fetchUser(this.props.match.params.user_id, this.props.history);
    }
  }

  render() {
    const { user, loading, history } = this.props;
    return user ? (
      <Header landing={true}>
        <Title>Unsubscribe Emails</Title>
        <FormWrapper>
          <h2 style={{ marginBottom: '25px' }}>
            <u>{user ? user.email : ''}</u>
          </h2>
          <p style={{ marginBottom: '25px' }}>
            Notifications can be changed in your settings tab.
          </p>
          <LongButton
            textColor="white"
            backgroundColor="primary_blue"
            onClick={() => {
              this.props.unsubscribeUser(user._id, history);
            }}
            style={{ overflow: 'hidden' }}
          >
            {loading ? <Loader /> : 'UNSUBSCRIBE INDEFINITELY'}
          </LongButton>
          <Helper to="/dashboard">Take me back</Helper>
        </FormWrapper>
      </Header>
    ) : (
      <LoaderPage color="accent1" />
    );
  }
}

function mapStateToProps({ auth }) {
  const { user, loading } = auth;
  return { user, loading };
}

export default connect(
  mapStateToProps,
  unsubActions
)(Unsubscribe);
