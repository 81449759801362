import React from 'react';
import styled, { css } from 'styled-components';

import { phone } from 'utils/media';
import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

const LongButton = styled.button`
  padding: 0px;
  font-family: ${baseFont};
  font-size: ${(p) => p.fontSize || '18px'};
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0px;
  width: 275px;

  ${phone(css`
    width: 100%;
  `)};
  height: 40px;
  color: ${(p) => colors[p.textColor]};
  background-color: ${(p) => colors[p.backgroundColor]};
  cursor: pointer;
  outline: none;
  border-radius: 2px;
  border-color: ${(p) => colors[p.textColor]};
  transition: background-color 0.1s ease-in-out;
  svg {
    & .path {
      stroke: ${(p) => colors[p.textColor]};
    }
  }
  ${'' /* Hover should fill button */}
  &:hover {
    color: ${(p) => colors[p.backgroundColor]};
    background-color: ${(p) =>
      p.inviteBtn ? (p.copied ? '#5CBA8F' : '#083E66') : colors[p.textColor]};
    border-color: ${(p) => colors[p.backgroundColor]};
    svg {
      & .path {
        stroke: ${(p) => colors[p.backgroundColor]};
      }
    }
  }
`;

export default (props) => <LongButton {...props} style={props.style} />;
