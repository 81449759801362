import React, { Component } from 'react';
import styled from 'styled-components';
import { VictoryPie } from 'victory';

import _ from 'lodash';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

// reducer for creating the Other category for couriers
const largeArrayReducer = (acc, cur, idx) => {
  if (idx <= 6) {
    acc.push(cur);
  } else {
    acc[acc.length - 1].x = 'Other';
    acc[acc.length - 1].y = acc[acc.length - 1].y + cur.y;
  }
  return acc;
};

const UsagePieContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -25px;
`;

const Color = styled.div`
  display: flex;
  align-items: center;
  ${(p) =>
    p.recipient_info
      ? `flex-direction: row; justify-content: space-evenly;`
      : `flex-direction: column;  justify-content: center;
`}
`;

const ColorGrid = styled.div`
  width: 23px;
  height: 20px;
  background-color: ${(p) => (p.color ? p.color : null)};

  box-sizing: border-box;
  border: 0.5px solid #e8e8e8;
  border-radius: 2px;
`;

const ColorLabel = styled.span`
  margin-top: 2px;
  color: #083e66;
  font-family: 'Swiss721BTRegular';
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  ${(p) => (p.recipient_info ? `margin-left: 2px` : null)}
`;

const colorScale = [
  colors.base_text3,
  '#5C7FA7',
  '#5CBA8F',
  '#C8AB85',
  '#90A1B5',
  colors.adjusted_blue,
  colors.base1,
];
const recipientColorScale = [colors.base_text3, colors.accent4];

class UsagePie extends Component {
  constructor() {
    super();
    this.state = {
      percent: 0,
    };
  }
  render() {
    const largeArray = this.props.data.length >= 6 ? true : false;
    const data = largeArray
      ? this.props.data.sort((a, b) => b.y - a.y).reduce(largeArrayReducer, [])
      : this.props.data.sort((a, b) => b.y - a.y);
    // if large array is true, any value 5 or under is put into the "Other" category

    return (
      <UsagePieContainer>
        <svg viewBox="0 0 400 400" width="100%" height="100%">
          <VictoryPie
            colorScale={
              this.props.recipient_info ? recipientColorScale : colorScale
            }
            standalone={false}
            width={400}
            height={400}
            data={data}
            innerRadius={130}
            labelRadius={85}
            labels={(d) => {
              const numOfUsers = this.props.recipient_info
                ? `(${d.amount})`
                : '';
              return d.y > 0
                ? `${(d.y * 100).toFixed(0)}% ${numOfUsers}`
                : null;
            }}
            style={{
              data: {
                // if pie chart is for recipients, use its color scale, otherwise, use other color scale
                fill: ({ eventKey }) =>
                  this.props.recipient_info
                    ? recipientColorScale[eventKey]
                    : colorScale[eventKey],
              },
              labels: {
                fontSize: 20,
                fill: colors.base_text3,
                fontWeight: 'bold',
                fontFamily: 'Swiss721BTBold',
              },
            }}
          />
        </svg>
        <Legend>
          {data.map((datum, i) => {
            return (
              <Color key={datum.x} recipient_info={this.props.recipient_info}>
                <ColorGrid
                  color={
                    this.props.recipient_info
                      ? recipientColorScale[i]
                      : colorScale[i]
                  }
                />
                <ColorLabel recipient_info={this.props.recipient_info}>
                  {datum.x}
                </ColorLabel>
              </Color>
            );
          })}
        </Legend>
      </UsagePieContainer>
    );
  }
}

export default UsagePie;
