import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LongButton from 'common/buttons/longButton';
import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import styled from 'styled-components';
import {
  fetchUnregisteredUsers,
  fetchPendingUsers,
  fetchVacationRequests,
  selectSystem,
  fetchCounters,
} from 'redux/modules/duck-admin';
import PendingTable from './PendingTable';
import UnregisteredTable from './UnregisteredTable';
import HoldRequestsTable from './HoldRequestsTable';
import 'react-table/react-table.css';
import './NotificationTables.css';

const NotifTableBtns = styled(LongButton)`
  max-width: 275px;
  min-width: 100px;
  &:hover {
    background-color: ${(p) => colors[p.backgroundColor]};
    color: ${(p) => colors[p.textColor]};
    border-color: ${(p) => colors[p.textColor]};
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  line-height: 22px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 50px;
  align-self: flex-start;
`;

const Instructions = styled.p`
  color: #083e66;
  font-family: 'Swiss721BTRegular';
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

const NotificationTables = ({
  fetchUnregisteredUsers,
  fetchPendingUsers,
  match,
  selectedSystem,
  history,
  fetchVacationRequests,
}) => {
  const [table, setTable] = useState(match.params.type);

  useEffect(() => {
    switch (table) {
      case 'holdrequest':
        if (selectedSystem) {
          return fetchVacationRequests(selectedSystem.system._id, history);
        }
      case 'unregistered':
        return fetchUnregisteredUsers();
      case 'pending':
        return fetchPendingUsers();
      default:
        break;
    }
  }, [table]);

  return (
    <Fragment>
      <Title>Notifications</Title>
      <nav className="notif__nav">
        <div>
          <Link to="/admin/dashboard/notifications/holdrequest">
            <NotifTableBtns
              backgroundColor={
                table === 'holdrequest' ? 'secondary_blue' : 'white'
              }
              textColor={table === 'holdrequest' ? 'base2' : 'secondary_blue'}
              onClick={() => setTable('holdrequest')}
            >
              HOLD REQUESTS
            </NotifTableBtns>
          </Link>
          {table === 'holdrequest' && (
            <Instructions>
              These users have hold requests awaiting approval.
            </Instructions>
          )}
        </div>
        <div>
          <Link to="/admin/dashboard/notifications/unregistered">
            <NotifTableBtns
              backgroundColor={
                table === 'unregistered' ? 'secondary_blue' : 'white'
              }
              textColor={table === 'unregistered' ? 'base2' : 'secondary_blue'}
              onClick={() => setTable('unregistered')}
            >
              UNREGISTERED USERS
            </NotifTableBtns>
          </Link>
          {table === 'unregistered' && (
            <Instructions>
              These users were not found in directory at time of delivery.
            </Instructions>
          )}
        </div>
        <div>
          <Link to="/admin/dashboard/notifications/pending">
            <NotifTableBtns
              backgroundColor={table === 'pending' ? 'secondary_blue' : 'white'}
              textColor={table === 'pending' ? 'base2' : 'secondary_blue'}
              onClick={() => setTable('pending')}
            >
              PENDING ACCOUNTS
            </NotifTableBtns>
          </Link>
          {table === 'pending' && (
            <Instructions>
              These users are verified and are awaiting account approval.
            </Instructions>
          )}
        </div>
      </nav>
      {table === 'holdrequest' && <HoldRequestsTable />}
      {table === 'unregistered' && <UnregisteredTable />}
      {table === 'pending' && <PendingTable />}
    </Fragment>
  );
};

function mapStateToProps({ admin }) {
  const {
    notificationsList,
    notificationsListLoading,
    notificationsListError,
    selectedSystem,
    counters,
  } = admin;
  return {
    data: notificationsList,
    loading: notificationsListLoading,
    error: notificationsListError,
    selectedSystem,
    counters,
  };
}

NotificationTables.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUnregisteredUsers,
      fetchPendingUsers,
      fetchVacationRequests,
      selectSystem,
      fetchCounters,
    },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NotificationTables)
);
