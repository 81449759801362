import React from 'react';

export default (props) => (
  <g
    id="Package-History---dropdown"
    transform="translate(-1227.000000, -471.000000)"
    fill={props.fill}
    fillRule="nonzero"
  >
    <g id="TABLE-VIEW" transform="translate(320.000000, 420.000000)">
      <g id="Label-image" transform="translate(887.000000, 46.000000)">
        <path
          d="M26.0852443,8.91911765 C25.0769193,8.91911765 24.259671,9.79673873 24.259671,10.8786765 C24.259671,11.9610061 25.0769193,12.8382353 26.0852443,12.8382353 C27.0933258,12.8382353 27.9108175,11.9610061 27.9108175,10.8786765 C27.9108175,9.79673873 27.0933258,8.91911765 26.0852443,8.91911765 Z M30.9534397,6.95955882 L29.4929811,6.95955882 C29.292168,6.95955882 29.0757767,6.79234314 29.0123685,6.5877652 L28.6348399,5.37166299 C28.5711883,5.16721569 28.3550404,5 28.1542273,5 L24.0162612,5 C23.8154482,5 23.5990569,5.16721569 23.5356486,5.37179363 L23.1581201,6.58789583 C23.0944684,6.79234314 22.8783205,6.95955882 22.6775075,6.95955882 L21.2170489,6.95955882 C20.547672,6.95955882 20,7.54742647 20,8.26593137 L20,14.1446078 C20,14.8631127 20.547672,15.4509804 21.2170489,15.4509804 L30.9534397,15.4509804 C31.6228166,15.4509804 32.1704885,14.8631127 32.1704885,14.1446078 L32.1704885,8.26593137 C32.1704885,7.54742647 31.6228166,6.95955882 30.9534397,6.95955882 Z M26.0852443,14.1446078 C24.4048649,14.1446078 23.0426221,12.682385 23.0426221,10.8786765 C23.0426221,9.07509853 24.4048649,7.6127451 26.0852443,7.6127451 C27.7653802,7.6127451 29.1278664,9.07509853 29.1278664,10.8786765 C29.1278664,12.682385 27.7653802,14.1446078 26.0852443,14.1446078 Z M30.5274726,9.1794777 C30.2923387,9.1794777 30.1015055,8.97489975 30.1015055,8.7222473 C30.1015055,8.46985613 30.2923387,8.26501691 30.5274726,8.26501691 C30.7628498,8.26501691 30.9534397,8.46985613 30.9534397,8.7222473 C30.9534397,8.97489975 30.7627281,9.1794777 30.5274726,9.1794777 Z"
          id="Shape"
        />
      </g>
    </g>
  </g>
);
