import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Header } from 'common/Header';
import LoaderPage from 'common/LoaderPage';
import Card from 'common/DirectoryCard';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

const CardWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 20px;
  box-sizing: border-box;
  padding: 0 15px 0 15px;
  width: 100%;
  justify-content: space-around;
`;

const P = styled.p`
  color: ${colors.secondary_blue};
  font-size: 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  line-height: 22px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 50px;
  align-self: flex-start;
`;

const HorizontalRuler = styled.hr`
  min-width: 250px;
`;

class Directory extends Component {
  renderDirectory() {
    const { directory } = this.props;
    return directory && directory.length > 0 ? (
      <CardWrapper>
        {_.map(directory, (system) => (
          <Card color="secondary_blue" key={system._id} title={system.nickname}>
            <div>
              <P
                style={{
                  fontSize: '20px',
                  fontFamily: 'Swiss721BTBold',
                  textTransform: 'uppercase',
                }}
              >
                Address
              </P>
              <P>{system.location.address1}</P>
              {system.location.address2 ? (
                <P>{system.location.address2}</P>
              ) : null}
              <P>
                {system.location.city}, {system.location.state}
              </P>
              <P>{system.location.zip}</P>

              <P
                style={{
                  marginTop: '70px',
                  fontSize: '20px',
                  fontFamily: 'Swiss721BTBold',
                  textTransform: 'uppercase',
                }}
              >
                Contact Information
              </P>
              <P>{system.email}</P>
              <P>{system.phone}</P>
            </div>
          </Card>
        ))}
      </CardWrapper>
    ) : (
      <div style={{ marginTop: '25px', height: '200px' }}>
        <LoaderPage height="50%" width="50%" color="accent1" />
      </div>
    );
  }

  render() {
    return (
      <Header>
        <Title>Systems Available</Title>
        {this.props.directory &&
        this.props.directory.length > 0 &&
        !this.props.loading ? (
          this.renderDirectory()
        ) : (
          <LoaderPage color="accent1" />
        )}
      </Header>
    );
  }
}

function mapStateToProps({ auth }) {
  const { directory, loading } = auth;
  return { directory, loading };
}

export default connect(
  mapStateToProps,
  null
)(Directory);
