import React from 'react';

export default (props) =>
  props.nocircle ? (
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Directory2"
        transform="translate(-41.000000, -264.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Navbar" transform="translate(-4.000000, 79.000000)">
          <g
            id="Packages-Delivered"
            transform="translate(44.000000, 184.000000)"
          >
            <g id="Deliveries-Icon" transform="translate(0.500000, 1.000000)">
              <polygon
                id="Path"
                points="16.3260848 3.32625403 8.53386957 0.520068909 5.45944887 1.62741115 13.2495048 4.43408233"
              />
              <polygon
                id="Path"
                points="3.85950974 2.47707562 1.17865292 3.50457082 8.70828228 6.39108905 11.3867048 5.36409378"
              />
              <polygon
                id="Path"
                points="0.520068909 12.241919 8.09378487 15.1976192 8.09378487 7.0637837 0.520068909 4.10791454"
              />
              <polygon
                id="Path"
                points="13.3650317 5.26356731 13.3650317 7.55831524 13.0533418 7.37601268 12.741652 7.80161056 12.4299622 7.61897011 12.1182724 8.04490583 11.8065826 7.86243435 11.4948928 8.2885391 11.4948928 5.99328431 8.75236883 7.0637837 8.75236883 15.1976192 16.3260848 12.241919 16.3260848 4.10791454"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  ) : (
    <g id="UI-Style-Guide" transform="translate(-224.000000, -2397.000000)">
      <g id="no-packages-yet" transform="translate(224.000000, 2397.000000)">
        <circle
          id="Oval"
          fill={props.fill ? props.fill : '#BEB7A6'}
          cx="29.5"
          cy="29.5"
          r="29.5"
        />
        <polygon
          id="Path"
          fill="#F6F7F9"
          points="45 19.5037107 29.7172954 14 23.6875 16.1718066 38.9659697 21.6764706"
        />
        <polygon
          id="Path"
          fill="#F6F7F9"
          points="20.5495737 17.8382353 15.2916667 19.8534396 30.0593673 25.5147059 35.3125 23.5004821"
        />
        <polygon
          id="Path"
          fill="#F6F7F9"
          points="14 36.9898133 28.8541667 42.7867647 28.8541667 26.8340475 14 21.0367647"
        />
        <polygon
          id="Path"
          fill="#F6F7F9"
          points="39.19255 23.3033217 39.19255 27.8039616 38.5812394 27.4464155 37.9699289 28.2811315 37.3586184 27.9229227 36.7473078 28.7583013 36.1359973 28.4004239 35.5246868 29.236134 35.5246868 24.7345 30.1458333 26.8340475 30.1458333 42.7867647 45 36.9898133 45 21.0367647"
        />
      </g>
    </g>
  );
