import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import colors from 'utils/colors';
import { mobile } from 'utils/media';

const Wrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  ${mobile(css`
    ${(p) =>
      p.isMobileNav
        ? css`
            background-color: white;
            top: 100px;
            left: 0;
            right: 0;
            padding: 0;
            visibility: visible;
            opacity: 1;
          `
        : css`
            opacity: 0;
            visibility: hidden;
          `};
  `)};
`;

const MobileNavItem = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  padding-bottom: 10px;
  color: ${colors.primary_blue};
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }
  &:visited {
    color: ${colors.primary_blue};
  }
`;

const MobileNav = ({ isMobileNav, onMobileNavToggle }) => (
  <Wrapper isMobileNav={isMobileNav}>
    <MobileNavItem to="/dashboard/login" onClick={onMobileNavToggle}>
      Login
    </MobileNavItem>
    <MobileNavItem to="/dashboard/register" onClick={onMobileNavToggle}>
      Sign Up
    </MobileNavItem>
  </Wrapper>
);

export default MobileNav;
