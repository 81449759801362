import React from 'react';

export default (props) => (
  <g
    id="User-Dashboard-filled-in"
    transform="translate(-685.000000, -217.000000)"
    fill={props.fill ? props.fill : '#15234A'}
    fillRule="nonzero"
  >
    <g id="email-icon" transform="translate(685.000000, 217.000000)">
      <path
        d="M14,0 C6.2680135,-4.73447626e-16 9.46895252e-16,6.2680135 0,14 C-9.46895252e-16,21.7319865 6.2680135,28 14,28 C21.7319865,28 28,21.7319865 28,14 C28,10.2869691 26.5250042,6.72601436 23.8994949,4.10050506 C21.2739856,1.47499577 17.7130309,2.27357568e-16 14,0 Z M20.16,9.24 L14,13.8488 L7.84,9.24 L20.16,9.24 Z M21,18.256 C20.9997777,18.4032298 20.9358898,18.5431685 20.8247893,18.6397776 C20.7136887,18.7363868 20.5662343,18.7802241 20.4204,18.76 L7.56,18.76 C7.41110545,18.7760522 7.2627349,18.7254306 7.15470186,18.6217189 C7.04666881,18.5180072 6.99003593,18.3718255 7,18.2224 C7,15.6669333 7,13.1114667 7,10.556 L7,9.8476 L9.2792,11.5584 L13.58,14.784 C13.8131312,15.0131137 14.1868688,15.0131137 14.42,14.784 L20.8768,9.9372 L20.9832,9.8588 C20.9902265,9.89956405 20.9949009,9.94069874 20.9972,9.982 L20.9972,18.2448 L21,18.256 Z"
        id="Shape"
      />
    </g>
  </g>
);
