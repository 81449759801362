import React from 'react';

import { Header } from 'common/Header';

export default () => (
  <Header landing={true}>
    <h1>The Link is invalid or has expired.</h1>
    <h1>Please resubmit your email address.</h1>
  </Header>
);
